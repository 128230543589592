import { Button } from "@mui/material";
import React, { useMemo } from "react";
import { FourDoorWranglerParts, FourDoorGradiaterParts, TwoDoorWranglarParts } from "./constants";

const ModelPartsButtons = ({ selectedType, setSelectedType, params, setTranslate, setPartsCount }) => {
  // Memoize partsDetails based on params
  const partsDetails = useMemo(() => {
    switch (params) {
      case "0001":
        return FourDoorWranglerParts;
      case "0002":
        return FourDoorGradiaterParts;
      case "0003":
        return TwoDoorWranglarParts;
      default:
        return [];
    }
  }, [params]);

  return (
    <div className="model-parts-buttons">
      {partsDetails.map((part) => (
        <Button
          key={part.name}
          onClick={() => {
            setSelectedType(part.name);
            setTranslate(part.translate);
            setPartsCount(part.count);
          }}
          className={
            selectedType === part.name
              ? "build-main-button-active"
              : "build-main-button"
          }
        >
          {`${part.name} (${part.count})`}
        </Button>
      ))}
    </div>
  );
};

export default ModelPartsButtons;
