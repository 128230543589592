import { NewTwoDoorImages } from "./ModelImages";

const { 
  bumperImages,
  doorImages,
  hoodImages,
  grillImages,
  sideStepImages,
  wheelImages,
  otherImages 
} = NewTwoDoorImages;

export const TwoDoorParts = [
  // Bumpers
  {
    name: "Corey Jones Bumper",
    price: 20,
    image: bumperImages.CoreyJonesBumper,
  },
  {
    name: "Covered Bumper",
    price: 20,
    image: bumperImages.CoveredBumper,
  },
  {
    name: "Darlene Moscone Bumper",
    price: 20,
    image: bumperImages.DarleneMosconeBumper,
  },
  {
    name: "Dustin Hemeur Rig 2 Bumper",
    price: 20,
    image: bumperImages.DustinHemeurRig2Bumper,
  },
  {
    name: "Dustin Hemeur Rig 3 Bumper",
    price: 20,
    image: bumperImages.DustinHemeurRig3Bumper,
  },
  {
    name: "Elizabeth Vanes Bumper",
    price: 20,
    image: bumperImages.ElizabethVanesBumper,
  },
  {
    name: "Glen Jones Bumper",
    price: 20,
    image: bumperImages.GlenJonesBumper,
  },
  {
    name: "Jason Cunningham Bumper",
    price: 20,
    image: bumperImages.JasonCunninghamBumper,
  },
  {
    name: "Jason Martino Bumper",
    price: 20,
    image: bumperImages.JasonMartinoBumper,
  },
  {
    name: "Jessica Venable Bumper",
    price: 20,
    image: bumperImages.JessicaVenableBumper,
  },
  {
    name: "Jonathan Mclaren Bumper",
    price: 20,
    image: bumperImages.JonathanMclarenBumper,
  },
  {
    name: "Joy Garner Bumper",
    price: 20,
    image: bumperImages.JoyGarnerBumper,
  },
  {
    name: "Kevin Kan Bumper",
    price: 20,
    image: bumperImages.KevinKanBumper,
  },
  {
    name: "Kevin Ooten Bumper",
    price: 20,
    image: bumperImages.KevinOotenBumper,
  },
  {
    name: "Leslie Medrano Bumper",
    price: 20,
    image: bumperImages.LeslieMedranoBumper,
  },
  {
    name: "Rick Valentin Bumper",
    price: 20,
    image: bumperImages.RIckValentinBumper,
  },
  {
    name: "Robert Hutchings Bumper",
    price: 20,
    image: bumperImages.RobertHutchingsBumper,
  },
  {
    name: "Sharon Fullmer Bumper",
    price: 20,
    image: bumperImages.SharonFullmerBumper,
  },
  {
    name: "Tammy McCormick Bumper",
    price: 20,
    image: bumperImages.TammyMcCormickBumper,
  },
  {
    name: "Tim Traynor Bumper",
    price: 20,
    image: bumperImages.TimTraynorBumper,
  },
  // Doors
  {
    name: "Simple Door",
    price: 0,
    image: doorImages.SimpleDoor,
  },
  // Hoods
  {
    name: "Darlene Moscone Hood",
    price: 10,
    image: hoodImages.DarleneMoscocneHood,
  },
  {
    name: "Rubicon Hood",
    price: 10,
    image: hoodImages.RubiconHood,
  },
  {
    name: "Jessica Venable Hood",
    price: 10,
    image: hoodImages.JessicaVenableHood,
  },
  {
    name: "Robert Hutchings Hood",
    price: 10,
    image: hoodImages.RobertHutchingsHood,
  },
  {
    name: "Simple Hood",
    price: 0,
    image: hoodImages.SimpleHood,
  },
  {
    name: "Yasean Bruce Hood",
    price: 10,
    image: hoodImages.YaseanBruceHood,
  },
  // Grills
  {
    name: "Claire Garette Grill",
    price: 10,
    image: grillImages.ClaireGaretteGrill,
  },
  {
    name: "Flat Grill",
    price: 10,
    image: grillImages.FlatGrill,
  },
  {
    name: "Glen Jones Grill",
    price: 10,
    image: grillImages.GlenJonesGrill,
  },
  {
    name: "Mark J Hanscom Grill",
    price: 10,
    image: grillImages.MarkJHanscomGrill,
  },
  {
    name: "Michael Knapp Grill",
    price: 10,
    image: grillImages.MichaelKnappGrill,
  },
  {
    name: "Rhi Mcclendon Grill",
    price: 10,
    image: grillImages.RhiMcclendonGrill,
  },
  {
    name: "Ricky Beilchner Grill",
    price: 10,
    image: grillImages.RickyBeilchnerGrill,
  },
  {
    name: "Shawn Osburn Grill",
    price: 10,
    image: grillImages.ShawnOsburnGrill,
  },
  // Side Steps
  {
    name: "Chris Hill Side Step",
    price: 10,
    image: sideStepImages.ChrisHillSideStep,
  },
  {
    name: "Yasean Bruce Side Step",
    price: 10,
    image: sideStepImages.YaseanBruceSideStep,
  },
  // Wheels
  {
    name: "Aaron Gordon Rims",
    price: 10,
    image: wheelImages.AaronGordonLBWheel,
  },
  {
    name: "Arkon Rims",
    price: 10,
    image: wheelImages.ArkonLBWheel,
  },
  {
    name: "Bethany Smeltzer Rims",
    price: 10,
    image: wheelImages.BethanySmeltzerLBWheel,
  },
  {
    name: "Bill Houghton Rims",
    price: 10,
    image: wheelImages.BillHoughtonLBWheel,
  },
  {
    name: "Black Rhino Rims",
    price: 10,
    image: wheelImages.BlackRhinoLBWheel,
  },
  {
    name: "Blitz Rims",
    price: 10,
    image: wheelImages.BlitzLBWheel,
  },
  {
    name: "Braydon Rims",
    price: 10,
    image: wheelImages.BraydonLBWheel,
  },
  {
    name: "Callie Lemming Rims",
    price: 10,
    image: wheelImages.CallieLemmingLBWheel,
  },
  {
    name: "Claire Garette Rims",
    price: 10,
    image: wheelImages.ClaireGaretteLBWheel,
  },
  {
    name: "Colter Bahlau Rims",
    price: 10,
    image: wheelImages.ColterBahlauLBWheel,
  },
  // {
  //   name: "Corey Jones Rims",
  //   price: 10,
  //   image: wheelImages.CoreyJonesBLWheel,
  // },
  {
    name: "Dani Coleman Rims",
    price: 10,
    image: wheelImages.DaniColemanBLWheel,
  },
  {
    name: "Darlene Moscone Rims",
    price: 10,
    image: wheelImages.DarleneMosconeBLWheel,
  },
  {
    name: "David Shafer Rims",
    price: 10,
    image: wheelImages.DavidShaferBLWheel,
  },
  {
    name: "Dustin Hemauer Rims",
    price: 10,
    image: wheelImages.DustinHemauerBLWheel,
  },
  {
    name: "Glen Jones Rims",
    price: 10,
    image: wheelImages.GlenJonesBLWheel,
  },
  {
    name: "Jared Renfroe Rims",
    price: 10,
    image: wheelImages.JaredRenfroeBLWheel,
  },
  {
    name: "Jason Martino Rims",
    price: 10,
    image: wheelImages.JasonMartinoFRWheel,
  },
  {
    name: "Jonathen Mclaren Rims",
    price: 10,
    image: wheelImages.JonathenMclarenWheel,
  },
  {
    name: "Joy Garner Rims",
    price: 10,
    image: wheelImages.JoyGarnerWheel,
  },
  {
    name: "Kevin Kan Rims",
    price: 10,
    image: wheelImages.KevinKanWheel,
  },
  {
    name: "Mark J Hanscom Rims",
    price: 10,
    image: wheelImages.MarkJHanscomWheel,
  },
  {
    name: "Darlene Moscocne Snorkel Other",
    price: 10,
    image: otherImages.DarleneMoscocneSnorkel,
  },
  {
    name: "Adam Wagner Fender Other",
    price: 10,
    image: otherImages.AdamWagner,
  },
  {
    name: "Andrea Sims Fender Other",
    price: 10,
    image: otherImages.AndreaSimsFender,
  },
  {
    name: "Christina Cabra Fender Other",
    price: 10,
    image: otherImages.ChristinaCabraFender,
  },
  {
    name: "Snow wolf Fender Other",
    price: 10,
    image: otherImages.SnowwolfFender,
  },
  {
    name: "Toyota Tacoma Fender Other",
    price: 10,
    image: otherImages.ToyotaTacomaFender,
  },

];
