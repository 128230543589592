import React from "react";
import { Grid } from "@mui/material"; // or import your preferred UI library components
import { colorOptions } from "../../Constants";

import yellow_circle from "../../assets/svg/yellow_circle.svg";
import white_circle from "../../assets/svg/white_circle.svg";
import red_circle from "../../assets/svg/red_circle.svg";
import green_circle from "../../assets/svg/green_circle.svg";
import blue_circle from "../../assets/svg/blue_circle.svg";
import black_circle from "../../assets/svg/black_circle.svg";
import circle_262A2C from "../../assets/svg/262A2C_circle.svg";
import grey_circle from "../../assets/svg/grey_circle.svg";
import circle_75747c from "../../assets/svg/75747c_circle.svg";

const BuyBuildHero = ({
  productDetails,
  handleAddToWishlist,
  setChassisColor,
}) => {
  return (
    <>
      <div className="buy-build-hero">
        <div className="buy-build-heading-buttons">
          <div>
            <p>
              {productDetails && productDetails.name
                ? productDetails.name.toUpperCase()
                : ""}
            </p>
            <p className="buy-build-title Akira-Expanded">BUILD BUY YOUR OWN</p>
          </div>
          <div className="buy-build-shop-buttons-container">
            <button
              className="main-button"
              onClick={() =>
                handleAddToWishlist({
                  _id: productDetails ? productDetails._id : "",
                  name: productDetails ? productDetails.name : "",
                  description: productDetails ? productDetails.description : "",
                  titleImageURL: productDetails
                    ? productDetails.titleImageURL
                    : "",
                  price: productDetails ? productDetails.price : 0,
                  vehicleParts: productDetails
                    ? productDetails.vehicleParts
                    : [],
                })
              }
              style={{
                margin: "0px 3px",
                color: "white",
                height: 50,
                width: 150,
              }}
            >
              Add To WishList
            </button>
          </div>
        </div>

        {/* <div className="model-color-container">
        <Grid container>
        {colorOptions.map((color, index) => (
            <Grid item key={index} sm={1}>
            <button
            style={{
                border: "none",
                backgroundColor: "transparent",
                }}
                onClick={() => setChassisColor(color.hex)}
                >
                <img src={color.image} alt={`color-${color.hex}`} style={{ width: "30px" }} />
                </button>
                </Grid>
                ))}
                </Grid>
                </div> */}
      </div>
      <div className="model-color-contaier">
        <Grid container sm={12} lg={6}>
          <Grid sm={1}>
            <button
              style={{
                margin: "0px 5px",
                border: "none",
                backgroundColor: "transparent",
              }}
              onClick={(e) => setChassisColor("#ff0")}
            >
              <img src={yellow_circle} style={{ width: "30px" }} />
            </button>
          </Grid>
          <Grid sm={1}>
            <button
              style={{
                margin: "0px 5px",
                border: "none",
                backgroundColor: "transparent",
              }}
              onClick={(e) => setChassisColor("#FDFEFF")}
            >
              <img src={white_circle} style={{ width: "30px" }} />
            </button>
          </Grid>
          <Grid sm={1}>
            <button
              style={{
                margin: "0px 5px",
                border: "none",
                backgroundColor: "transparent",
              }}
              onClick={(e) => setChassisColor("#f00")}
            >
              <img src={red_circle} style={{ width: "30px" }} />
            </button>
          </Grid>
          <Grid sm={1}>
            <button
              style={{
                margin: "0px 5px",
                border: "none",
                backgroundColor: "transparent",
              }}
              onClick={(e) => setChassisColor("#0f0")}
            >
              <img src={green_circle} style={{ width: "30px" }} />
            </button>
          </Grid>
          <Grid sm={1}>
            <button
              style={{
                margin: "0px 5px",
                border: "none",
                backgroundColor: "transparent",
              }}
              onClick={(e) => setChassisColor("#00f")}
            >
              <img src={blue_circle} style={{ width: "30px" }} />
            </button>
          </Grid>
          <Grid sm={1}>
            <button
              style={{
                margin: "0px 5px",
                border: "none",
                backgroundColor: "transparent",
              }}
              onClick={(e) => setChassisColor("#000")}
            >
              <img src={black_circle} style={{ width: "30px" }} />
            </button>
          </Grid>
          <Grid sm={1}>
            <button
              style={{
                margin: "0px 5px",
                border: "none",
                backgroundColor: "transparent",
              }}
              onClick={(e) => setChassisColor("#262A2C")}
            >
              <img src={circle_262A2C} style={{ width: "30px" }} />
            </button>
          </Grid>
          <Grid sm={1}>
            <button
              style={{
                margin: "0px 5px",
                border: "none",
                backgroundColor: "transparent",
              }}
              onClick={(e) => setChassisColor("#A8A9AD")}
            >
              <img src={grey_circle} style={{ width: "30px" }} />
            </button>
          </Grid>
          <Grid sm={1}>
            <button
              style={{
                margin: "0px 5px",
                border: "none",
                backgroundColor: "transparent",
              }}
              onClick={(e) => setChassisColor("#75747c")}
            >
              <img src={circle_75747c} style={{ width: "30px" }} />
            </button>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default BuyBuildHero;
