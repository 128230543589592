import React, { useEffect, useState } from "react";
import queryString from "query-string";
import {
  Get_Access_Token,
  Create_Order,
  Capture_Payment,
} from "../Utilities/Paypal";
import { PayPalButtons, usePayPalScriptReducer } from "@paypal/react-paypal-js";

export default function Paypal_credit({ handlePaymentSubmit }) {
  const cart_ = localStorage.getItem("Cart");
  const [{ options, isPending }, dispatch] = usePayPalScriptReducer();
  const [currency, setCurrency] = useState(options.currency);

  const onCurrencyChange = ({ target: { value } }) => {
    setCurrency(value);
    dispatch({
      type: "resetOptions",
      value: {
        ...options,
        currency: value,
      },
    });
  };

  const onCreateOrder = (data, actions) => {
    let total_ = 2000;
    let finalCart = {};
    if (cart_) {
      const currentCart = JSON.parse(cart_);

      total_ = currentCart.reduce(
        (sum, item) => sum + item.productPrice * item.Quantity,
        0
      );

      finalCart = {
        items: currentCart.map((item) => {
          return {
            name: item.productName,
            description: "item.productDescription",
            quantity: item.Quantity.toString(),
            unit_amount: {
              currency_code: "USD",
              value: item.productPrice.toFixed(2).toString(),
            },
          };
        }),
        amount: {
          currency_code: "USD",
          value: total_.toFixed(2).toString(),
          breakdown: {
            item_total: {
              currency_code: "USD",
              value: total_.toFixed(2).toString(),
            },
          },
        },
      };
    }
    console.log(total_);
    console.log(finalCart);
    return actions.order.create({
      intent: "CAPTURE",
      purchase_units: [finalCart],
    });
  };

  const onApproveOrder = (data, actions) => {
    return actions.order.capture().then((details) => {
      const name = details.payer.name.given_name;
      handlePaymentSubmit("COMPLETED");
      // alert(`Transaction completed by ${name}`);
    });
  };

  return (
    <div>
      {isPending ? (
        <p>LOADING...</p>
      ) : (
        <>
          {/* <select value={currency} onChange={onCurrencyChange}>
            <option value="USD">💵 USD</option>
            <option value="EUR">💶 Euro</option>
          </select> */}
          <PayPalButtons
            style={{ layout: "vertical" }}
            createOrder={(data, actions) => onCreateOrder(data, actions)}
            onApprove={(data, actions) => onApproveOrder(data, actions)}
          />
        </>
      )}
    </div>
  );
}
