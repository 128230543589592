import useGLBLoader from '../../../../useGlbLoader.js';
import { TwoDoorWranglerParts } from '../Constants/ModelConstants.js';

const useGrillContainer = (visibleItems) => {

  let claireGaretteGrill = useGLBLoader(
    TwoDoorWranglerParts.Grills.ClaireGaretteGrill,
    visibleItems.includes("Claire Garette Grill")
  );

  let flatGrill = useGLBLoader(
    TwoDoorWranglerParts.Grills.FlatGrill,
    visibleItems.includes("Flat Grill")
  );

  let glenJonesGrill = useGLBLoader(
    TwoDoorWranglerParts.Grills.GlenJonesGrill,
    visibleItems.includes("Glen Jones Grill")
  );

  let markJHanscomGrill = useGLBLoader(
    TwoDoorWranglerParts.Grills.MarkJHanscomGrill,
    visibleItems.includes("Mark J Hanscom Grill")
  );

  let michaelKnappGrill = useGLBLoader(
    TwoDoorWranglerParts.Grills.MichaelKnappGrill,
    visibleItems.includes("Michael Knapp Grill")
  );

  let rhiMcclendonGrill = useGLBLoader(
    TwoDoorWranglerParts.Grills.RhiMcclendonGrill,
    visibleItems.includes("Rhi Mcclendon Grill")
  );

  let rickyBeilchnerGrill = useGLBLoader(
    TwoDoorWranglerParts.Grills.RickyBeilchnerGrill,
    visibleItems.includes("Ricky Beilchner Grill")
  );

  let shawnOsburnGrill = useGLBLoader(
    TwoDoorWranglerParts.Grills.ShawnOsburnGrill,
    visibleItems.includes("Shawn Osburn Grill")
  );

  const grills = [
    { name: "Claire Garette Grill", model: claireGaretteGrill },
    { name: "Flat Grill", model: flatGrill },
    { name: "Glen Jones Grill", model: glenJonesGrill },
    { name: "Mark J Hanscom Grill", model: markJHanscomGrill },
    { name: "Michael Knapp Grill", model: michaelKnappGrill },
    { name: "Rhi Mcclendon Grill", model: rhiMcclendonGrill },
    { name: "Ricky Beilchner Grill", model: rickyBeilchnerGrill },
    { name: "Shawn Osburn Grill", model: shawnOsburnGrill },
  ];

  return {
    grills
  };
};

export default useGrillContainer;
