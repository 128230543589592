import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { products } from './constants';
import { Button, Grid } from '@mui/material';

const RelatedProducts = ({
  params,
  handleAddToCart,
  handleAddToWishlist,
}) => {
  return (
    <div style={{ textAlign: 'center', marginTop: '100px', marginBottom: '100px' }}>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <div className="divider-hero"></div>
        <p style={{ fontSize: '36px', lineHeight: '52px', marginLeft: 20 }} className="Akira-Expanded">
          RELATED PRODUCTS
        </p>
        <div className="divider-hero"></div>
      </div>

      <Grid container spacing={2} style={{ width: '90%', margin: 'auto', marginTop: '50px' }}>
        {products.map((item) => {
          if (item._id == params) return null;

          return (
            <Grid item md={4} key={item._id}>
              <div className="shop-card-container">
                <div className="shop-card-image-container">
                  <img src={item.image} alt="" style={{ width: '80%' }} />
                </div>
                <div className="shop-card-details">
                  <Link to={`/product-details?q=${item._id}`} style={{ textDecoration: 'none', color: 'black' }}>
                    <p className="shop-card-details-heading Akira-Expanded">{item.name}</p>
                  </Link>
                  <p className="shop-card-details-description">{item.description}</p>
                  <p className="shop-card-details-price">${item.price}</p>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Link onClick={() => localStorage.removeItem('modelParts')} to={`/build-your-own?q=${item._id}`} style={{ textDecoration: 'none', color: 'black' }}>
                      <Button className="main-button">Build Buy Your Own</Button>
                    </Link>
                    <button
                      style={{ marginLeft: '10px', border: 'none' }}
                      onClick={() =>
                        handleAddToCart({
                          _id: item._id,
                          name: item.name,
                          description: item.description,
                          titleImageURL: item.image,
                          price: item.price,
                        })
                      }
                    >
                      {/* <LocalMall style={{ color: '#FF3132', fontSize: '36px' }} /> */}
                    </button>
                    <button
                      style={{ marginLeft: '5px', border: 'none' }}
                      onClick={() =>
                        handleAddToWishlist({
                          _id: item._id,
                          name: item.name,
                          description: item.description,
                          titleImageURL: item.image,
                          price: item.price,
                        })
                      }
                    >
                      {/* <Favorite style={{ color: '#FF3132', fontSize: '36px' }} /> */}
                    </button>
                  </div>
                </div>
              </div>
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
};

RelatedProducts.propTypes = {
  products: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      image: PropTypes.string.isRequired,
      price: PropTypes.number.isRequired,
    })
  ).isRequired,
  params: PropTypes.string.isRequired,
  handleAddToCart: PropTypes.func.isRequired,
  handleAddToWishlist: PropTypes.func.isRequired,
};

export default RelatedProducts;
