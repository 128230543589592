const baseURL = "/Models/NewFourDoorWrangler";

export const FourDoorWranglerParts = {
  Bumpers: {
    CoreyJonesBumper: `${baseURL}/Bumper/Compressed Bumper/CoreyJonesBumper.glb`,
    CoveredBumper: `${baseURL}/Bumper/Compressed Bumper/CoveredBumper.glb`,
    DarleneMosconeBumper: `${baseURL}/Bumper/Compressed Bumper/DarleneMosconeBumper.glb`,
    DustinHemeurRig2Bumper: `${baseURL}/Bumper/Compressed Bumper/DustinHemeurRig2Bumper.glb`,
    DustinHemeurRig3Bumper: `${baseURL}/Bumper/Compressed Bumper/DustinHemeurRig3Bumper.glb`,
    ElizabethVanesBumper: `${baseURL}/Bumper/Compressed Bumper/ElizabethVanesBumper.glb`,
    GlenJonesBumper: `${baseURL}/Bumper/Compressed Bumper/GlenJonesBumper.glb`,
    JasonCunninghamBumper: `${baseURL}/Bumper/Compressed Bumper/JasonCunninghamBumper.glb`,
    JasonMartinoBumper: `${baseURL}/Bumper/Compressed Bumper/JasonMartinoBumper.glb`,
    JessicaVenableBumper: `${baseURL}/Bumper/Compressed Bumper/JessicaVenableBumper.glb`,
    JonathanMclarenBumper: `${baseURL}/Bumper/Compressed Bumper/JonathanMclarenBumper.glb`,
    JoyGarnerBumper: `${baseURL}/Bumper/Compressed Bumper/JoyGarnerBumper.glb`,
    KevinKanBumper: `${baseURL}/Bumper/Compressed Bumper/KevinKanBumper.glb`,
    KevinOotenBumper: `${baseURL}/Bumper/Compressed Bumper/KevinOotenBumper.glb`,
    LeslieMedranoBumper: `${baseURL}/Bumper/Compressed Bumper/LeslieMedranoBumper.glb`,
    RIckValentinBumper: `${baseURL}/Bumper/Compressed Bumper/RIckValentinBumper.glb`,
    RobertHutchingsBumper: `${baseURL}/Bumper/Compressed Bumper/RobertHutchingsBumper.glb`,
    SharonFullmerBumper: `${baseURL}/Bumper/Compressed Bumper/SharonFullmerBumper.glb`,
    TammyMcCormickBumper: `${baseURL}/Bumper/Compressed Bumper/TammyMcCormickBumper.glb`,
    TimTraynorBumper: `${baseURL}/Bumper/Compressed Bumper/TimTraynorBumper.glb`,
  },
  SideSteps: {
    ChrisHillSideStepLeft: `${baseURL}/Side Step/Compressed Side Steps/ChrisHillSideStepLeft.glb`,
    ChrisHillSideStepRight: `${baseURL}/Side Step/Compressed Side Steps/ChrisHillSideStepRight.glb`,
    KarenLyndonLeftSideStep: `${baseURL}/Side Step/Compressed Side Steps/KarenLyndonLeftSideStep.glb`,
    KarenLyndonRightSideStep: `${baseURL}/Side Step/Compressed Side Steps/KarenLyndonRightSideStep.glb`,
    KevinSideStepLeft: `${baseURL}/Side Step/Compressed Side Steps/KevinSideStepLeft.glb`,
    KevinSideStepRight: `${baseURL}/Side Step/Compressed Side Steps/KevinSideStepRight.glb`,
    ReganSideStepLeft: `${baseURL}/Side Step/Compressed Side Steps/ReganSideStepLeft.glb`,
    ReganSideStepRight: `${baseURL}/Side Step/Compressed Side Steps/ReganSideStepRight.glb`,
  },
  Wheels: {
    AaronGordon5thWheel: `${baseURL}/Wheels/Compressed Wheel/AaronGordon5thWheel.glb`,
    AaronGordonLBWheel: `${baseURL}/Wheels/Compressed Wheel/AaronGordonWheelLB.glb`,
    AaronGordonLFWheel: `${baseURL}/Wheels/Compressed Wheel/AaronGordonWheelLF.glb`,
    AaronGordonRBWheel: `${baseURL}/Wheels/Compressed Wheel/AaronGordonRBWheel.glb`,
    AaronGordonRFWheel: `${baseURL}/Wheels/Compressed Wheel/AaronGordonRFWheel.glb`,
    Arkon5thWheel: `${baseURL}/Wheels/Compressed Wheel/Arkon5thWheel.glb`,
    ArkonLBWheel: `${baseURL}/Wheels/Compressed Wheel/ArkonLBWheel.glb`,
    ArkonLFWheel: `${baseURL}/Wheels/Compressed Wheel/ArkonLFWheel.glb`,
    ArkonRBWheel: `${baseURL}/Wheels/Compressed Wheel/ArkonRBWheel.glb`,
    ArkonRFWheel: `${baseURL}/Wheels/Compressed Wheel/ArkonRFWheel.glb`,
    BethanySmeltzer5thWheel: `${baseURL}/Wheels/Compressed Wheel/BethanySmeltzer5thWheel.glb`,
    BethanySmeltzerLBWheel: `${baseURL}/Wheels/Compressed Wheel/BethanySmeltzerLBWheel.glb`,
    BethanySmeltzerLFWheel: `${baseURL}/Wheels/Compressed Wheel/BethanySmeltzerLFWheel.glb`,
    BethanySmeltzerRBWheel: `${baseURL}/Wheels/Compressed Wheel/BethanySmeltzerRBWheel.glb`,
    BethanySmeltzerRFWheel: `${baseURL}/Wheels/Compressed Wheel/BethanySmeltzerRFWheel.glb`,
    BillHoughton5thWheel: `${baseURL}/Wheels/Compressed Wheel/BillHoughton5thWheel.glb`,
    BillHoughtonLBWheel: `${baseURL}/Wheels/Compressed Wheel/BillHoughtonLBWheel.glb`,
    BillHoughtonLFWheel: `${baseURL}/Wheels/Compressed Wheel/BillHoughtonLFWheel.glb`,
    BillHoughtonRBWheel: `${baseURL}/Wheels/Compressed Wheel/BillHoughtonRBWheel.glb`,
    BillHoughtonRFWheel: `${baseURL}/Wheels/Compressed Wheel/BillHoughtonRFWheel.glb`,
    BlackRhino5thWheel: `${baseURL}/Wheels/Compressed Wheel/BlackRhino5thWheel.glb`,
    BlackRhinoLBWheel: `${baseURL}/Wheels/Compressed Wheel/BlackRhinoLBWheel.glb`,
    BlackRhinoLFWheel: `${baseURL}/Wheels/Compressed Wheel/BlackRhinoLFWheel.glb`,
    BlackRhinoRBWheel: `${baseURL}/Wheels/Compressed Wheel/BlackRhinoRBWheel.glb`,
    BlackRhinoRFWheel: `${baseURL}/Wheels/Compressed Wheel/BlackRhinoRFWheel.glb`,
    BlitzL5thWheel: `${baseURL}/Wheels/Compressed Wheel/BlitzL5thWheel.glb`,
    BlitzLBWheel: `${baseURL}/Wheels/Compressed Wheel/BlitzLBWheel.glb`,
    BlitzLFWheel: `${baseURL}/Wheels/Compressed Wheel/BlitzLFWheel.glb`,
    BlitzRBWheel: `${baseURL}/Wheels/Compressed Wheel/BlitzRBWheel.glb`,
    BlitzRFWheel: `${baseURL}/Wheels/Compressed Wheel/BlitzRFWheel.glb`,
    Braydon5thWheel: `${baseURL}/Wheels/Compressed Wheel/Braydon5thWheel.glb`,
    BraydonLBWheel: `${baseURL}/Wheels/Compressed Wheel/BraydonLBWheel.glb`,
    BraydonLFWheel: `${baseURL}/Wheels/Compressed Wheel/BraydonLFWheel.glb`,
    BraydonRBWheel: `${baseURL}/Wheels/Compressed Wheel/BraydonRBWheel.glb`,
    BraydonRFWheel: `${baseURL}/Wheels/Compressed Wheel/BraydonRFWheel.glb`,
    CallieLemming5thWheel: `${baseURL}/Wheels/Compressed Wheel/CallieLemming5thWheel.glb`,
    CallieLemmingLBWheel: `${baseURL}/Wheels/Compressed Wheel/CallieLemmingLBWheel.glb`,
    CallieLemmingLFWheel: `${baseURL}/Wheels/Compressed Wheel/CallieLemmingLFWheel.glb`,
    CallieLemmingRBWheel: `${baseURL}/Wheels/Compressed Wheel/CallieLemmingRBWheel.glb`,
    CallieLemmingRFWheel: `${baseURL}/Wheels/Compressed Wheel/CallieLemmingRFWheel.glb`,
    ClaireGarette5thWheel: `${baseURL}/Wheels/Compressed Wheel/ClaireGarette5thWheel.glb`,
    ClaireGaretteLBWheel: `${baseURL}/Wheels/Compressed Wheel/ClaireGaretteLBWheel.glb`,
    ClaireGaretteLFWheel: `${baseURL}/Wheels/Compressed Wheel/ClaireGaretteLFWheel.glb`,
    ClaireGaretteRBWheel: `${baseURL}/Wheels/Compressed Wheel/ClaireGaretteRBWheel.glb`,
    ClaireGaretteRFWheel: `${baseURL}/Wheels/Compressed Wheel/ClaireGaretteRFWheel.glb`,
    ColterBahlau5thWheel: `${baseURL}/Wheels/Compressed Wheel/ColterBahlau5thWHeelRBWheel.glb`,
    ColterBahlauLBWheel: `${baseURL}/Wheels/Compressed Wheel/ColterBahlauLBWheel.glb`,
    ColterBahlauLFWheel: `${baseURL}/Wheels/Compressed Wheel/ColterBahlauLFWheel.glb`,
    ColterBahlauRBWheel: `${baseURL}/Wheels/Compressed Wheel/ColterBahlauRBWheel.glb`,
    ColterBahlauRFWheel: `${baseURL}/Wheels/Compressed Wheel/ColterBahlauRFWheel.glb`,
    CoreyJones5thWheel: `${baseURL}/Wheels/Compressed Wheel/CoreyJones5thWheel.glb`,
    CoreyJonesBLWheel: `${baseURL}/Wheels/Compressed Wheel/CoreyJonesBLWheel.glb`,
    CoreyJonesBRWheel: `${baseURL}/Wheels/Compressed Wheel/CoreyJonesBRWheel.glb`,
    CoreyJonesFLWheel: `${baseURL}/Wheels/Compressed Wheel/CoreyJonesFLWheel.glb`,
    CoreyJonesFRWheel: `${baseURL}/Wheels/Compressed Wheel/CoreyJonesFRWheel.glb`,
    DaniColeman5thWheel: `${baseURL}/Wheels/Compressed Wheel/DaniColeman5thWheel.glb`,
    DaniColemanBLWheel: `${baseURL}/Wheels/Compressed Wheel/DaniColemanBLWheel.glb`,
    DaniColemanBRWheel: `${baseURL}/Wheels/Compressed Wheel/DaniColemanBRWheel.glb`,
    DaniColemanFLWheel: `${baseURL}/Wheels/Compressed Wheel/DaniColemanFLWheel.glb`,
    DaniColemanFRWheel: `${baseURL}/Wheels/Compressed Wheel/DaniColemanFRWheel.glb`,
    DarleneMoscone5thWheel: `${baseURL}/Wheels/Compressed Wheel/DarleneMoscone5thWheel.glb`,
    DarleneMosconeBLWheel: `${baseURL}/Wheels/Compressed Wheel/DarleneMosconeBLWheel.glb`,
    DarleneMosconeBRWheel: `${baseURL}/Wheels/Compressed Wheel/DarleneMosconeBRWheel.glb`,
    DarleneMosconeFLWheel: `${baseURL}/Wheels/Compressed Wheel/DarleneMosconeFLWheel.glb`,
    DarleneMosconeFRWheel: `${baseURL}/Wheels/Compressed Wheel/DarleneMosconeFRWheel.glb`,
    DavidShafer5thWheel: `${baseURL}/Wheels/Compressed Wheel/DavidShafer5thWheel.glb`,
    DavidShaferBLWheel: `${baseURL}/Wheels/Compressed Wheel/DavidShaferBLWheel.glb`,
    DavidShaferBRWheel: `${baseURL}/Wheels/Compressed Wheel/DavidShaferBRWheel.glb`,
    DavidShaferFLWheel: `${baseURL}/Wheels/Compressed Wheel/DavidShaferFLWheel.glb`,
    DavidShaferFRWheel: `${baseURL}/Wheels/Compressed Wheel/DavidShaferFRWheel.glb`,
    DustinHemauer5thWheel: `${baseURL}/Wheels/Compressed Wheel/DustinHemauer5thWheel.glb`,
    DustinHemauerFLWheel: `${baseURL}/Wheels/Compressed Wheel/DustinHemauerFLWheel.glb`,
    DustinHemauerFRWheel: `${baseURL}/Wheels/Compressed Wheel/DustinHemauerFRWheel.glb`,
    DustinrHemauerBLWheel: `${baseURL}/Wheels/Compressed Wheel/DustinrHemauerBLWheel.glb`,
    DustinrHemauerBRWheel: `${baseURL}/Wheels/Compressed Wheel/DustinrHemauerBRWheel.glb`,
    GlenJones5thWheel: `${baseURL}/Wheels/Compressed Wheel/GlenJones5thWheel.glb`,
    GlenJonesBLWheel: `${baseURL}/Wheels/Compressed Wheel/GlenJonesBLWheel.glb`,
    GlenJonesBRWheel: `${baseURL}/Wheels/Compressed Wheel/GlenJonesBRWheel.glb`,
    GlenJonesFLWheel: `${baseURL}/Wheels/Compressed Wheel/GlenJonesFLWheel.glb`,
    GlenJonesFRWheel: `${baseURL}/Wheels/Compressed Wheel/GlenJonesFRWheel.glb`,
    JaredRenfroe5thWheel: `${baseURL}/Wheels/Compressed Wheel/JaredRenfroe5thWheel.glb`,
    JaredRenfroeBLWheel: `${baseURL}/Wheels/Compressed Wheel/JaredRenfroeBLWheel.glb`,
    JaredRenfroeBRWheel: `${baseURL}/Wheels/Compressed Wheel/JaredRenfroeBRWheel.glb`,
    JaredRenfroeFLWheel: `${baseURL}/Wheels/Compressed Wheel/JaredRenfroeFLWheel.glb`,
    JaredRenfroeFRWheel: `${baseURL}/Wheels/Compressed Wheel/JaredRenfroeFRWheel.glb`,
    JasonMartino5thWheel: `${baseURL}/Wheels/Compressed Wheel/JasonMartino5thWheel.glb`,
    JasonMartinoBLWheel: `${baseURL}/Wheels/Compressed Wheel/JasonMartinoBLWheel.glb`,
    JasonMartinoBRWheel: `${baseURL}/Wheels/Compressed Wheel/JasonMartinoBRWheel.glb`,
    JasonMartinoFLWheel: `${baseURL}/Wheels/Compressed Wheel/JasonMartinoFLWheel.glb`,
    JasonMartinoFRWheel: `${baseURL}/Wheels/Compressed Wheel/JasonMartinoFRWheel.glb`,
    JonathenMclaren5thWheel: `${baseURL}/Wheels/Compressed Wheel/JonathenMclaren5thWheel.glb`,
    JonathenMclarenBLWheel: `${baseURL}/Wheels/Compressed Wheel/JonathenMclarenBLWheel.glb`,
    JonathenMclarenBRWheel: `${baseURL}/Wheels/Compressed Wheel/JonathenMclarenBRWheel.glb`,
    JonathenMclarenFLWheel: `${baseURL}/Wheels/Compressed Wheel/JonathenMclarenFLWheel.glb`,
    JonathenMclarenFRWheel: `${baseURL}/Wheels/Compressed Wheel/JonathenMclarenFRWheel.glb`,
    JoyGarner5thWheel: `${baseURL}/Wheels/Compressed Wheel/JoyGarner5thWheel.glb`,
    JoyGarnerBLWheel: `${baseURL}/Wheels/Compressed Wheel/JoyGarnerBLWheel.glb`,
    JoyGarnerBRWheel: `${baseURL}/Wheels/Compressed Wheel/JoyGarnerBRWheel.glb`,
    JoyGarnerFLWheel: `${baseURL}/Wheels/Compressed Wheel/JoyGarnerFLWheel.glb`,
    JoyGarnerFRWheel: `${baseURL}/Wheels/Compressed Wheel/JoyGarnerFRWheel.glb`,
    KevinKan5thWheel: `${baseURL}/Wheels/Compressed Wheel/KevinKan5thWheel.glb`,
    KevinKanBLWheel: `${baseURL}/Wheels/Compressed Wheel/KevinKanBLWheel.glb`,
    KevinKanBRWheel: `${baseURL}/Wheels/Compressed Wheel/KevinKanBRWheel.glb`,
    KevinKanFLWheel: `${baseURL}/Wheels/Compressed Wheel/KevinKanFLWheel.glb`,
    KevinKanFRWheel: `${baseURL}/Wheels/Compressed Wheel/KevinKanFRWheel.glb`,
    MarkJHanscom5thWheel: `${baseURL}/Wheels/Compressed Wheel/MarkJHanscom5thWheel.glb`,
    MarkJHanscomBLWheel: `${baseURL}/Wheels/Compressed Wheel/MarkJHanscomBLWheel.glb`,
    MarkJHanscomBRWheel: `${baseURL}/Wheels/Compressed Wheel/MarkJHanscomBRWheel.glb`,
    MarkJHanscomFLWheel: `${baseURL}/Wheels/Compressed Wheel/MarkJHanscomFLWheel.glb`,
    MarkJHanscomFRWheel: `${baseURL}/Wheels/Compressed Wheel/MarkJHanscomFRWheel.glb`,
  },
  Hoods: {
    DarleneMoscocneHood: `${baseURL}/Hood/Compressed Hood/DarleneMoscocneHood.glb`,
    JessicaVenableHood: `${baseURL}/Hood/Compressed Hood/JessicaVenableHood.glb`,
    RobertHutchingsHood: `${baseURL}/Hood/Compressed Hood/RobertHutchingsHood.glb`,
    SimpleHood: `${baseURL}/Hood/Compressed Hood/SimpleHood.glb`,
    YaseanBruceHood: `${baseURL}/Hood/Compressed Hood/YaseanBruceHood.glb`,
    rubiconHood: `${baseURL}/Hood/Compressed Hood/rebiconhood new.glb`,
  },

  Grills: {
    ClaireGaretteGrill: `${baseURL}/Grill/Compressed Grill/ClaireGaretteGrill.glb`,
    FlatGrill: `${baseURL}/Grill/Compressed Grill/FlatGrill.glb`,
    GlenJonesGrill: `${baseURL}/Grill/Compressed Grill/GlenJonesGrill.glb`,
    MarkJHanscomGrill: `${baseURL}/Grill/Compressed Grill/MarkJHanscomGrill.glb`,
    MichaelKnappGrill: `${baseURL}/Grill/Compressed Grill/MichaelKnappGrill.glb`,
    RhiMcclendonGrill: `${baseURL}/Grill/Compressed Grill/RhiMcclendonGrill.glb`,
    RickyBeilchnerGrill: `${baseURL}/Grill/Compressed Grill/RickyBeilchnerGrill.glb`,
    ShawnOsburnGrill: `${baseURL}/Grill/Compressed Grill/ShawnOsburnGrill.glb`,
  },

  Doors: {
    FourDoorWranglerStructure: `${baseURL}/Door/Compressed Door/4doorWranglerStructure.glb`,
    RandyLyonDoorLB: `${baseURL}/Door/Compressed Door/RandyLyonDoorLB.glb`,
    RandyLyonDoorLF: `${baseURL}/Door/Compressed Door/RandyLyonDoorLF.glb`,
    RandyLyonDoorRB: `${baseURL}/Door/Compressed Door/RandyLyonDoorRB.glb`,
    RandyLyonDoorRF: `${baseURL}/Door/Compressed Door/RandyLyonDoorRF.glb`,
    SimpleDoorBL: `${baseURL}/Door/Compressed Door/SimpleDoorBL.glb`,
    SimpleDoorBR: `${baseURL}/Door/Compressed Door/SimpleDoorBR.glb`,
    SimpleDoorFL: `${baseURL}/Door/Compressed Door/SimpleDoorFL.glb`,
    SimpleDoorFR: `${baseURL}/Door/Compressed Door/SimpleDoorFR.glb`,
    SteveSmithDoorLB: `${baseURL}/Door/Compressed Door/SteveSmithDoorLB.glb`,
    SteveSmithDoorLF: `${baseURL}/Door/Compressed Door/SteveSmithDoorLF.glb`,
    SteveSmithDoorRB: `${baseURL}/Door/Compressed Door/SteveSmithDoorRB.glb`,
    SteveSmithDoorRF: `${baseURL}/Door/Compressed Door/SteveSmithDoorRF.glb`,
    TubularDoorLB: `${baseURL}/Door/Compressed Door/TubularDoorLB.glb`,
    TubularDoorLF: `${baseURL}/Door/Compressed Door/TubularDoorLF.glb`,
    TubularDoorRB: `${baseURL}/Door/Compressed Door/TubularDoorRB.glb`,
    TubularDoorRF: `${baseURL}/Door/Compressed Door/TubularDoorRF.glb`,
  },

  Others: {
    BriCarterTop: `${baseURL}/Other/Compressed Other/BriCarterTop.glb`,
    DarleneMoscocneSnorkel: `${baseURL}/Other/Compressed Other/DarleneMoscocneSnorkel.glb`,
    JamesSimpsonBackRoofRack: `${baseURL}/Other/Compressed Other/JamesSimpsonBackRoofRack.glb`,
    JamesSimpsonFrontRoofRack: `${baseURL}/Other/Compressed Other/JamesSimpsonFrontRoofRack.glb`,
    AdamWagnerFenderLB: `${baseURL}/Other/Compressed Other/AdamWagnerFenderLB.glb`,
    AdamWagnerFenderLF: `${baseURL}/Other/Compressed Other/AdamWagnerFenderLF.glb`,
    AdamWagnerFenderRB: `${baseURL}/Other/Compressed Other/AdamWagnerFenderRB.glb`,
    AdamWagnerFenderRF: `${baseURL}/Other/Compressed Other/AdamWagnerFenderRF.glb`,
    AndreaSimsFenderLB: `${baseURL}/Other/Compressed Other/AndreaSimsFenderLB.glb`,
    AndreaSimsFenderLF: `${baseURL}/Other/Compressed Other/AndreaSimsFenderLF.glb`,
    AndreaSimsFenderRB: `${baseURL}/Other/Compressed Other/AndreaSimsFenderRB.glb`,
    AndreaSimsFenderRF: `${baseURL}/Other/Compressed Other/AndreaSimsFenderRF.glb`,
    ChristinaCabraFenderLB: `${baseURL}/Other/Compressed Other/ChristinaCabraFenderLB.glb`,
    ChristinaCabraFenderLF: `${baseURL}/Other/Compressed Other/ChristinaCabraFenderLF.glb`,
    ChristinaCabraFenderRB: `${baseURL}/Other/Compressed Other/ChristinaCabraFenderRB.glb`,
    ChristinaCabraFenderRF: `${baseURL}/Other/Compressed Other/ChristinaCabraFenderRF.glb`,
    SnowwolfFenderLB: `${baseURL}/Other/Compressed Other/SnowwolfFenderLB.glb`,
    SnowwolfFenderLF: `${baseURL}/Other/Compressed Other/SnowwolfFenderLF.glb`,
    SnowwolfFenderRB: `${baseURL}/Other/Compressed Other/SnowwolfFenderRB.glb`,
    SnowwolfFenderRF: `${baseURL}/Other/Compressed Other/SnowwolfFenderRF.glb`,
    ToyotaTacomaFenderLB: `${baseURL}/Other/Compressed Other/ToyotaTacomaFenderLB.glb`,
    ToyotaTacomaFenderLF: `${baseURL}/Other/Compressed Other/ToyotaTacomaFenderLF.glb`,
    ToyotaTacomaFenderRB: `${baseURL}/Other/Compressed Other/ToyotaTacomaFenderRB.glb`,
    ToyotaTacomaFenderRF: `${baseURL}/Other/Compressed Other/ToyotaTacomaFenderRF.glb`,
  },
};


