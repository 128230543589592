import React, { useState, useEffect } from "react";
import { Modal } from "@mui/material";
import formImage from "../assets/images/form-image.jpg";
import { Button, Divider, Grid } from "@mui/material";
import { Close } from "@mui/icons-material";
import "../assets/css/signinPopup.css";
import { login, signup, forgetPassword } from "../Utilities/Auth";

const SigninPopup = ({ open, handleShowPopup }) => {
  const [currentScreen, setCurrentScreen] = useState("signin");
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const [fullName, setFullName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleForgotPassword = async () => {
    const response = await forgetPassword({
      emailAddress: email,
    });
    if (response.IsSuccess === true && response.Status_Code === 200) {
      setSuccessMessage(response.message);
      setEmail("");
      setPassword("");
    } else {
      setError(response.message);
    }
  };

  const handleSignin = async () => {
    const response = await login({
      email: email,
      password: password,
    });
    if (response.IsSuccess === true && response.Status_Code === 200) {
      const userData = response.Data.User;
      localStorage.setItem("Web_User", JSON.stringify(userData));
      localStorage.setItem("Web_Token", response.Data.Token);
      setEmail("");
      setPassword("");
      handleShowPopup();
    } else {
      setError(response.message);
    }
  };

  const handleSignup = async () => {
    try {
      if (email === "") {
        setError("Please enter a valid email!");
        return;
      } else if (fullName == "") {
        setError("Please enter your full name!");
        return;
      } else if (phone == "") {
        setError("Please enter your phone number!");
        return;
      } else if (password == "") {
        setError("Please enter a strong password!");
        return;
      } else {
        const response = await signup({
          emailAddress: email,
          fullName: fullName,
          password: password,
          userGroupId: "website",
          contact: phone,
          country: "",
          IsActive: true,
          User: "website",
        });
        if (response.IsSuccess === true && response.Status_Code === 200) {
          // const userData = response.Data.User;
          // localStorage.setItem("Web_User", JSON.stringify(userData));
          // localStorage.setItem("Web_Token", response.Data.Token);
          setEmail("");
          setPassword("");
          setFullName("");
          setPhone("");
          setError("");
          setCurrentScreen("signin");
        } else {
          setError(response.message);
        }
      }
    } catch (error) {
      setEmail(error);
    }
  };

  useEffect(() => {
    setCurrentScreen("signin");
    setEmail("");
    setPassword("");
    setFullName("");
    setPhone("");
    setError("");
  }, [open]);

  return (
    <Modal
      open={open}
      onClose={handleShowPopup}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className="card-container ">
        <Grid container spacing={2}>
          <Grid md={7} sm={12} xs={12} item>
            <button
              onClick={handleShowPopup}
              style={{
                position: "absolute",
                left: 10,
                top: 10,
                color: "white",
                backgroundColor: "transparent",
              }}
            >
              <Close />
            </button>
            {currentScreen === "signin" ? (
              <div className="form-grid">
                <p className="contact-form-heading Akira-Expanded">WELCOME</p>
                <p style={{ color: "white", marginTop: -10 }}>
                  Sign into your account
                </p>
                <p style={{ color: "red" }}>{error !== "" && error}</p>
                <Grid container spacing={2}>
                  <Grid md={12} sm={12} item style={{ width: "90%" }}>
                    <input
                      type="email"
                      placeholder="Email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      style={{
                        width: "100%",
                        backgroundColor: "#393939",
                        color: "#fff",
                        fontSize: 14,
                        padding: "3px 10px",
                        border: "none",
                        height: 40,
                      }}
                    />
                  </Grid>
                  <Grid md={12} sm={12} item style={{ width: "90%" }}>
                    <input
                      type="password"
                      placeholder="Password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      style={{
                        width: "100%",
                        backgroundColor: "#393939",
                        color: "#fff",
                        fontSize: 14,
                        padding: "3px 10px",
                        border: "none",
                        height: 40,
                      }}
                    />
                  </Grid>
                  <Grid
                    md={6}
                    item
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                    }}
                  >
                    <a
                      style={{
                        color: "#ffffff",
                        cursor: "pointer",
                        fontWeight: "400",
                      }}
                      onClick={(e) => {
                        setCurrentScreen("forget");
                        setError("");
                      }}
                    >
                      Forgot Password?
                    </a>
                  </Grid>
                  <Grid
                    md={6}
                    item
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    <Button onClick={handleSignin} className="main-button">
                      Sign In
                    </Button>
                  </Grid>
                </Grid>

                <div
                  className="divider-grid"
                  style={{ marginTop: 50, marginBottom: 10, color: "#ffffff" }}
                ></div>

                <p style={{ color: "white" }}>
                  Dont have an account?{" "}
                  <a
                    style={{
                      color: "#ffffff",
                      cursor: "pointer",
                      fontWeight: "800",
                    }}
                    onClick={(e) => {
                      setCurrentScreen("signup");
                      setError("");
                    }}
                  >
                    SignUp
                  </a>
                </p>
              </div>
            ) : currentScreen === "signup" ? (
              <div className="form-grid">
                <p className="contact-form-heading Akira-Expanded">WELCOME</p>
                <p style={{ color: "white", marginTop: -10 }}>
                  Signup for your free account
                </p>
                <p style={{ color: "red" }}>{error !== "" && error}</p>
                <Grid container spacing={2}>
                  <Grid md={12} sm={12} item style={{ width: "90%" }}>
                    <input
                      type="text"
                      placeholder="Full Name"
                      value={fullName}
                      onChange={(e) => setFullName(e.target.value)}
                      style={{
                        width: "100%",
                        backgroundColor: "#393939",
                        color: "#fff",
                        fontSize: 14,
                        padding: "3px 10px",
                        border: "none",
                        height: 40,
                      }}
                    />
                  </Grid>
                  <Grid md={12} sm={12} item style={{ width: "90%" }}>
                    <input
                      type="text"
                      placeholder="Phone No."
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                      style={{
                        width: "100%",
                        backgroundColor: "#393939",
                        color: "#fff",
                        fontSize: 14,
                        padding: "3px 10px",
                        border: "none",
                        height: 40,
                      }}
                    />
                  </Grid>
                  <Grid md={12} sm={12} item style={{ width: "90%" }}>
                    <input
                      type="email"
                      placeholder="Email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      style={{
                        width: "100%",
                        backgroundColor: "#393939",
                        color: "#fff",
                        fontSize: 14,
                        padding: "3px 10px",
                        border: "none",
                        height: 40,
                      }}
                    />
                  </Grid>
                  <Grid md={12} sm={12} item style={{ width: "90%" }}>
                    <input
                      type="password"
                      placeholder="Password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      style={{
                        width: "100%",
                        backgroundColor: "#393939",
                        color: "#fff",
                        fontSize: 14,
                        padding: "3px 10px",
                        border: "none",
                        height: 40,
                      }}
                    />
                  </Grid>
                  <Grid
                    md={12}
                    xs={12}
                    item
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Button onClick={handleSignup} className="main-button">
                      Sign Up
                    </Button>
                  </Grid>
                </Grid>

                <div
                  className="divider-grid"
                  style={{ marginTop: 30, marginBottom: 10, color: "#ffffff" }}
                ></div>

                <p style={{ color: "white" }}>
                  Already have an account?{" "}
                  <a
                    style={{
                      color: "#ffffff",
                      cursor: "pointer",
                      fontWeight: "800",
                    }}
                    onClick={(e) => {
                      setCurrentScreen("signin");
                      setError("");
                    }}
                  >
                    SignIn
                  </a>
                </p>
              </div>
            ) : (
              <div className="form-grid">
                <p className="contact-form-heading Akira-Expanded">
                  FORGOT PASSWORD
                </p>
                <p style={{ color: "white", marginTop: -10 }}>
                  Input your email to reset password
                </p>
                <p style={{ color: "#fff" }}>
                  {successMessage !== "" && successMessage}
                </p>
                <p style={{ color: "red" }}>{error !== "" && error}</p>
                <Grid container spacing={2}>
                  <Grid md={12} xs={12} sm={12} item style={{ width: "90%" }}>
                    <input
                      type="email"
                      placeholder="Email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      style={{
                        width: "100%",
                        backgroundColor: "#393939",
                        color: "#fff",
                        fontSize: 14,
                        padding: "3px 10px",
                        border: "none",
                        height: 40,
                      }}
                    />
                  </Grid>
                  <Grid
                    md={12}
                    xs={12}
                    item
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Button
                      onClick={handleForgotPassword}
                      className="main-button"
                    >
                      Forgot
                    </Button>
                  </Grid>
                </Grid>

                <div
                  className="divider-grid"
                  style={{ marginTop: 50, marginBottom: 10, color: "#ffffff" }}
                ></div>

                <p style={{ color: "white" }}>
                  Back to Signint?{" "}
                  <a
                    style={{
                      color: "#ffffff",
                      cursor: "pointer",
                      fontWeight: "800",
                    }}
                    onClick={(e) => {
                      setCurrentScreen("signin");
                      setError("");
                    }}
                  >
                    Signin
                  </a>
                </p>
              </div>
            )}
          </Grid>
          <Grid md={5} sm={0} item className="form-image">
            <img
              src={formImage}
              className="image-grid"
              alt="jeep"
              width={"70%"}
            />
          </Grid>
        </Grid>
      </div>
    </Modal>
  );
};

export default SigninPopup;
