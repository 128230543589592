import React, { useState } from "react";
import { Link } from "react-router-dom";
import logo from "../assets/svg/logo_black.svg";
import { Grid } from "@mui/material";
import { TextField } from "@mui/material";
import { Divider } from "@mui/material";
import "../assets/css/Footer.css";
import { Facebook, LinkedIn, Twitter, Instagram } from "@mui/icons-material";
import facebook_icon from "../assets/svg/facebook_icon.svg";
import instagram_icon from "../assets/svg/instagram_icon_border.svg";
import footerIcon from "../assets/illusionx.png";

const Footer = () => {
  const [email, setEmail] = useState("");
  return (
    <div className="footer">
      <div className="footer-grid">
        <Grid container spacing={3}>
          <Grid item md={1} className="empty-section"></Grid>
          <Grid item md={7} sm={12} xs={12} className="footer-logo">
            <img
              src={logo}
              alt="logo"
              className="logo"
              style={{ marginLeft: 10 }}
            />
          </Grid>
          <Grid item md={2} sm={12} className="footer-text">
            <p className="footer-section-header Gilroy-Bold">Quick Links</p>
            <Link className="footer-link" to={"/"}>
              <p className="footer-link-text">Home</p>
            </Link>
            <Link
              className="footer-link"
              target="_blank"
              to={"https://my3djeep.printify.me/products"}
            >
              <p className="footer-link-text">Shop</p>
            </Link>
            <Link className="footer-link" to={"/about"}>
              <p className="footer-link-text">About</p>
            </Link>
            <Link className="footer-link" to={"/contact"}>
              <p className="footer-link-text">Contact </p>
            </Link>
          </Grid>
          {/* <Grid item md={2} sm={12} className="footer-text">
            <p className="footer-section-header Gilroy-Bold">Shop Products</p>
            <Link className="footer-link">
              <p className="footer-link-text">Lorem ipsum</p>
            </Link>
            <Link className="footer-link">
              <p className="footer-link-text">Lorem ipsum</p>
            </Link>
            <Link className="footer-link">
              <p className="footer-link-text">Lorem ipsum</p>
            </Link>
            <Link className="footer-link">
              <p className="footer-link-text">Lorem ipsum </p>
            </Link>
          </Grid> */}
          <Grid item md={2} sm={12} className="footer-text">
            <p className="footer-section-header Gilroy-Bold">Get in Touch</p>
            <Link className="footer-link" to={"/"}>
              <p className="footer-link-text">MY3DJEEP</p>
            </Link>
            <Link
              className="footer-link"
              to={"https://wa.me/+16124442262"}
              target="_blank"
            >
              <p className="footer-link-text">612-444-2262</p>
            </Link>
            <Link className="footer-link" to={"mailto:my3djeep@gmail.com"}>
              <p className="footer-link-text">my3djeep@gmail.com</p>
            </Link>
            <Link className="footer-link">
              <p className="footer-link-text">Rogers, MN 55374</p>
            </Link>
          </Grid>
        </Grid>
      </div>
      <div className="footer-divider"></div>
      <div className="footer-social-icons">
        <a href="https://www.instagram.com/my3djeep" target="_blank">
          <button
            style={{
              // height: "40px",
              // width: "40px",
              // borderRadius: "20px",
              border: "none",
              backgroundColor: "transparent",
              // margin: "0px 0px",
            }}
          >
            {/* <Instagram style={{ color: "#FF3132" }} /> */}
            <img
              src={instagram_icon}
              width={"100%"}
              style={{ backgroundColor: "white" }}
            />
          </button>
        </a>
        <a href="https://www.facebook.com/my3djeep" target="_blank">
          <button
            style={{
              // height: "40px",
              // width: "40px",
              // borderRadius: "20px",
              border: "none",
              backgroundColor: "transparent",
              // margin: "0px 0px",
            }}
          >
            {/* <Facebook style={{ color: "#FF3132" }} /> */}
            <img
              src={facebook_icon}
              width={"100%"}
              style={{ backgroundColor: "white" }}
            />
          </button>
        </a>
      </div>
      <div className="footer-copyright-section">
        <p>
          Copyright 2024 @ My 3d Jeep, Design and Developed By{" "}
          <a href="https://illusionx.co/" target="_parent">
            <img
              src={footerIcon}
              style={{
                width: 120,
                height: 28,
              }}
            />
          </a>
        </p>
        <p>Privacy Policy</p>
      </div>
    </div>
  );
};

export default Footer;
