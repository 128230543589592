import featureImage1 from "../../assets/images/featureImage1.png";
import featureImage2 from "../../assets/images/featureImage2.png";
import gladiator from "../../assets/images/gladiator.png";
export const products = [
    {
      _id: '0001',
      name: '4D Wrangler',
      description: 'Your custom Jeep will be custom designed, 3D Printed, assembled and shipped in a 4-5 week time frame.',
      image: featureImage1,
      price: 120,
    },
    {
      _id: '0003',
      name: '2D Wrangler',
      description: 'Your custom Jeep will be custom designed, 3D Printed, assembled and shipped in a 4-5 week time frame.',
      image: featureImage2,
      price: 120,
    },
    {
      _id: '0002',
      name: '4D Gladiator',
      description: 'Your custom Jeep will be custom designed, 3D Printed, assembled and shipped in a 4-5 week time frame.',
      image: gladiator,
      price: 140,
    },
  ];


  export const FourDoorWranglerParts = [
    {
      name: "BUMPER",
      count: 20,
      translate: 1800,
    },
    {
      name: "GRILL",
      count: 8,
      translate: 600,
    },
    {
      name: "HOOD",
      count: 6,
      translate: 300,
    },
    {
      name: "DOOR",
      count: 4,
      translate: 200,
    },
    {
      name: "RIMS",
      count: 22,
      translate: 2000,
    },
    {
      name: "SIDE STEPS",
      count: 4,
      translate: 200,
    },
    {
      name: "OTHERS",
      count: 7,
      translate: 500,
    },
  ];

  export const FourDoorGradiaterParts = [
    {
      name: "BUMPER",
      count: 23,
      translate: 1800,
    },
    {
      name: "GRILL",
      count: 8,
      translate: 600,
    },
    {
      name: "HOOD",
      count: 6,
      translate: 300,
    },
    {
      name: "DOOR",
      count: 4,
      translate: 200,
    },
    {
      name: "RIMS",
      count: 20,
      translate: 2000,
    },
    {
      name: "SIDE STEPS",
      count: 3,
      translate: 200,
    },
    {
      name: "OTHERS",
      count: 7,
      translate: 500,
    },
  ];

  export const TwoDoorWranglarParts = [
    {
      name: "BUMPER",
      count: 23,
      translate: 1800,
    },
    {
      name: "GRILL",
      count: 8,
      translate: 600,
    },
    {
      name: "HOOD",
      count: 6,
      translate: 300,
    },
    {
      name: "DOOR",
      count: 1,
      translate: 200,
    },
    {
      name: "RIMS",
      count: 20,
      translate: 2000,
    },
    {
      name: "SIDE STEPS",
      count: 2,
      translate: 200,
    },
    {
      name: "OTHERS",
      count: 6,
      translate: 400,
    },
  ];