import React, { useState, useEffect } from "react";
import { Button, Divider, Grid } from "@mui/material";

import { Link, useNavigate } from "react-router-dom";
import homeHero from "../assets/svg/homePageHero.svg";
import { changePassword } from "../Utilities/Auth";

const Profile = () => {
  const navigation = useNavigate();

  const [user, setUSer] = useState(null);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const [OldPassword, setOldPassword] = useState("");
  const [NewPassword, setNewPassword] = useState("");
  const [ConfirmPassword, setConfirmPassword] = useState("");

  const [cart, setCart] = useState([]);
  const [cartTotal, setCartTotal] = useState(0);
  const [selectedMenu, setSelectedMenu] = useState("profile");

  useEffect(() => {
    const cart = localStorage.getItem("Cart");
    if (cart) {
      const currentCart = JSON.parse(cart);
      setCart(currentCart);
      let total = 0;
      currentCart.map((item) => {
        total = item.productPrice * item.Quantity;
      });
      setCartTotal(total);
    }
  }, []);

  useEffect(() => {
    const User = localStorage.getItem("Web_User");
    if (User) {
      setUSer(JSON.parse(User));
    } else {
      navigation("/");
    }
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setError("");
      setSuccess("");
    }, 3000);
  }, [error, success]);

  const handleChangePassword = async () => {
    if (NewPassword === "") {
      setSuccess("");
      setError("Enter New Password.");
      return;
    } else if (NewPassword !== ConfirmPassword) {
      setSuccess("");
      setError("New Password and Confirm Password must match.");
      return;
    } else if (OldPassword === "") {
      setSuccess("");
      setError("Enter your old password.");
      return;
    }

    const resp = await changePassword({
      userID: user.id,
      password: NewPassword,
      oldPassword: OldPassword,
    });
    if (resp.Status_Code === 200 && resp.IsSuccess === true) {
      setSuccess(resp.message);
      setError("");
      setNewPassword("");
      setConfirmPassword("");
    } else {
      setSuccess("");
      setError(resp.message);
    }
  };

  const handleLogout = async () => {
    await localStorage.removeItem("Web_User");
    await localStorage.removeItem("Web_Token");
    navigation("/");
  };

  return (
    <div>
      <div className="shoppage-hero-container">
        <Grid container spacing={2}>
          <Grid
            item
            md={6}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
            }}
          >
            <div
              style={{
                width: "70%",
                color: "white",
              }}
            >
              <p className="contact-hero-title">PROFILE</p>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                }}
              >
                <p
                  style={{
                    fontSize: "22px",
                    fontWeight: "500",
                  }}
                >
                  HOME - PROFILE
                </p>
                <div className="divider-hero"></div>
              </div>
            </div>
          </Grid>
          <Grid item md={6} className="hero-section-image">
            <img src={homeHero} alt="" style={{ width: "90%" }} />
          </Grid>
        </Grid>
      </div>

      <div
        style={{
          width: "50%",
          height: 50,
          backgroundColor: "#2b2b2b",
          marginTop: -50,
          borderTopRightRadius: 50,
        }}
      ></div>

      <div className="profile-form-main-container">
        <div className="profile-form-container">
          <Grid container spacing={2}>
            <Grid md={3} sm={12} xs={12} item>
              <div className="profile-menus">
                <div
                  style={{
                    width: "100%",
                  }}
                >
                  <Button
                    style={{
                      width: "100%",
                      borderBottom:
                        selectedMenu !== "profile"
                          ? "1px solid #f0f0f0"
                          : "none",
                      backgroundImage:
                        selectedMenu === "profile"
                          ? "linear-gradient(to right, #ff4336, #e97644)"
                          : "",
                      color: "#fff",
                      padding: "10px 15px",
                      fontSize: 14,
                      borderRadius: 0,
                    }}
                    onClick={(e) => setSelectedMenu("profile")}
                  >
                    PROFILE
                  </Button>
                  <Button
                    style={{
                      width: "100%",
                      borderBottom:
                        selectedMenu !== "order" ? "1px solid #f0f0f0" : "none",
                      backgroundImage:
                        selectedMenu === "order"
                          ? "linear-gradient(to right, #ff4336, #e97644)"
                          : "",
                      color: "#fff",
                      padding: "10px 15px",
                      fontSize: 14,
                      borderRadius: 0,
                    }}
                    onClick={(e) => setSelectedMenu("order")}
                  >
                    YOUR ORDER
                  </Button>
                  <Button
                    style={{
                      width: "100%",
                      borderBottom:
                        selectedMenu !== "purchases"
                          ? "1px solid #f0f0f0"
                          : "none",
                      backgroundImage:
                        selectedMenu === "purchases"
                          ? "linear-gradient(to right, #ff4336, #e97644)"
                          : "",
                      color: "#fff",
                      padding: "10px 15px",
                      fontSize: 14,
                      borderRadius: 0,
                    }}
                    onClick={(e) => setSelectedMenu("purchases")}
                  >
                    YOUR PURCHASES
                  </Button>
                  <Button
                    style={{
                      width: "100%",
                      borderBottom:
                        selectedMenu !== "password"
                          ? "1px solid #f0f0f0"
                          : "none",
                      backgroundImage:
                        selectedMenu === "password"
                          ? "linear-gradient(to right, #ff4336, #e97644)"
                          : "",
                      color: "#fff",
                      padding: "10px 15px",
                      fontSize: 14,
                      borderRadius: 0,
                    }}
                    onClick={(e) => setSelectedMenu("password")}
                  >
                    CHANGE PASSWORD
                  </Button>
                </div>
                <div
                  style={{
                    width: "100%",
                  }}
                >
                  <Button
                    style={{
                      width: "100%",
                      backgroundImage:
                        "linear-gradient(to right, #ff4336, #e97644)",
                      color: "#fff",
                      padding: "10px 15px",
                      fontSize: 14,
                      fontWeight: "bold",
                      borderRadius: 0,
                    }}
                    onClick={handleLogout}
                  >
                    LOGOUT
                  </Button>
                </div>
              </div>
            </Grid>
            {selectedMenu === "profile" ? (
              <Grid md={9} sm={12} xs={12} item>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    paddingRight: 20,
                  }}
                >
                  <p className="contact-form-heading">PROFILE</p>
                </div>
                <Grid container spacing={2}>
                  <Grid md={12} sm={12} item style={{ width: "90%" }}>
                    <input
                      type="text"
                      value={user !== null ? user.fullName : ""}
                      disabled
                      placeholder="Full Name"
                      style={{
                        width: "100%",
                        backgroundColor: "#393939",
                        fontSize: 14,
                        padding: "3px 10px",
                        border: "none",
                        height: 40,
                        color: "#fff",
                      }}
                    />
                  </Grid>
                  <Grid md={6} sm={12} item style={{ width: "90%" }}>
                    <input
                      type="text"
                      placeholder="Contact"
                      value={user !== null ? user.contact : ""}
                      disabled
                      style={{
                        width: "100%",
                        backgroundColor: "#393939",
                        fontSize: 14,
                        padding: "3px 10px",
                        border: "none",
                        height: 40,
                        color: "#fff",
                      }}
                    />
                  </Grid>
                  <Grid md={6} sm={12} item style={{ width: "90%" }}>
                    <input
                      type="text"
                      placeholder="Email Address"
                      value={user !== null ? user.emailAddress : ""}
                      disabled
                      style={{
                        width: "100%",
                        backgroundColor: "#393939",
                        fontSize: 14,
                        padding: "3px 10px",
                        border: "none",
                        height: 40,
                        color: "#fff",
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            ) : selectedMenu === "password" ? (
              <Grid md={9} sm={12} xs={12} item>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    paddingRight: 20,
                  }}
                >
                  <p className="contact-form-heading">CHANGE PASSWORD</p>
                  {error !== "" && (
                    <p style={{ color: "red", fontSize: 14 }}>{error}</p>
                  )}
                  {success !== "" && (
                    <p style={{ color: "green", fontSize: 14 }}>{success}</p>
                  )}
                </div>
                <Grid container spacing={2}>
                  <Grid md={12} sm={12} item style={{ width: "90%" }}>
                    <input
                      type="text"
                      placeholder="Old Password"
                      value={OldPassword}
                      onChange={(e) => setOldPassword(e.target.value)}
                      style={{
                        width: "100%",
                        backgroundColor: "#393939",
                        fontSize: 14,
                        padding: "3px 10px",
                        border: "none",
                        height: 40,
                        color: "#fff",
                      }}
                    />
                  </Grid>
                  <Grid md={6} sm={12} item style={{ width: "90%" }}>
                    <input
                      type="password"
                      placeholder="New Password"
                      value={NewPassword}
                      onChange={(e) => setNewPassword(e.target.value)}
                      style={{
                        width: "100%",
                        backgroundColor: "#393939",
                        fontSize: 14,
                        padding: "3px 10px",
                        border: "none",
                        height: 40,
                        color: "#fff",
                      }}
                    />
                  </Grid>
                  <Grid md={6} sm={12} item style={{ width: "90%" }}>
                    <input
                      type="password"
                      placeholder="Confirm Password"
                      value={ConfirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      style={{
                        width: "100%",
                        backgroundColor: "#393939",
                        fontSize: 14,
                        padding: "3px 10px",
                        border: "none",
                        height: 40,
                        color: "#fff",
                      }}
                    />
                  </Grid>
                  <Grid
                    md={12}
                    sm={12}
                    item
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    <Button
                      style={{
                        width: "150px",
                        backgroundImage:
                          "linear-gradient(to right, #ff4336, #e97644)",
                        color: "#fff",
                        padding: "10px 15px",
                        fontSize: 14,
                        borderRadius: 0,
                      }}
                      onClick={(e) => handleChangePassword()}
                    >
                      SUBMIT
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            ) : selectedMenu === "order" ? (
              <Grid md={9} sm={12} xs={12} item>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    paddingRight: 20,
                  }}
                >
                  <p className="contact-form-heading">YOUR ORDER</p>
                </div>
              </Grid>
            ) : (
              <Grid md={9} sm={12} xs={12} item>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    paddingRight: 20,
                  }}
                >
                  <p className="contact-form-heading">YOUR PURCHASES</p>
                </div>
              </Grid>
            )}
          </Grid>
        </div>
      </div>

      <div className="mid-banner-2">
        <div>
          <p
            style={{
              fontWeight: "500",
              fontSize: "22px",
            }}
          >
            DRIVE BEYOND BOUNDARIES
          </p>
        </div>
        <p
          style={{
            fontWeight: "800",
            fontSize: "44px",
            lineHeight: "52px",
            textAlign: "center",
          }}
        >
          CUSTOMIZE DESIGN
        </p>
        <Link
          to="/build-your-own"
          style={{ textDecoration: "none", color: "black" }}
        >
          <Button className="main-button">Customize Your Jeep</Button>
        </Link>
      </div>
    </div>
  );
};

export default Profile;
