
import useGLBLoader from "../../../../useGlbLoader";
import { FourDoorGradiatorParts } from "../Constants/ModelConstants";

const useHoodContainer = (visibleItems) => {

  let darleneMoscocneHood = useGLBLoader(
    FourDoorGradiatorParts.Hoods.DarleneMoscocneHood,
    visibleItems.includes("Darlene Moscocne Hood")
  );

  let jessicaVenableHood = useGLBLoader(
    FourDoorGradiatorParts.Hoods.JessicaVenableHood,
    visibleItems.includes("Jessica Venable Hood")
  );

  let robertHutchingsHood = useGLBLoader(
    FourDoorGradiatorParts.Hoods.RobertHutchingsHood,
    visibleItems.includes("Robert Hutchings Hood")
  );

  let simpleHood = useGLBLoader(
    FourDoorGradiatorParts.Hoods.SimpleHood,
    visibleItems.includes("Simple Hood")
  );

  let yaseanBruceHood = useGLBLoader(
    FourDoorGradiatorParts.Hoods.YaseanBruceHood,
    visibleItems.includes("Yasean Bruce Hood")
  );

  let rubiconHood = useGLBLoader(
    FourDoorGradiatorParts.Hoods.rubiconHood,
    visibleItems.includes("Rubicon Hood")
  );

  const hoods = [
    { name: "Darlene Moscocne Hood", model: darleneMoscocneHood },
    { name: "Jessica Venable Hood", model: jessicaVenableHood },
    { name: "Robert Hutchings Hood", model: robertHutchingsHood },
    { name: "Simple Hood", model: simpleHood },
    { name: "Yasean Bruce Hood", model: yaseanBruceHood },
    { name: "Rubicon Hood", model: rubiconHood },
  ];

  return {
    hoods
  };
};

export default useHoodContainer;
