import React from "react";

const ModelLoader = ({ src }) => {
  return (
    <div className="custom-loader">
      {src && (
        <img
          src={src}
          className="model-loader-image"
          alt="Model Loader Image"
        />
      )}
      <div className="loader"></div>
    </div>
  );
};

export default ModelLoader;
