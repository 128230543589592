import { useEffect, useState } from "react";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader";

function useGLBLoader(modelPath, visible) {
  const [model, setModel] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (visible) {
      setLoading(true);

      const dracoLoader = new DRACOLoader();
      dracoLoader.setDecoderConfig({ type: "js" });
      dracoLoader.setDecoderPath("https://www.gstatic.com/draco/v1/decoders/");

      const gltfLoader = new GLTFLoader();
      gltfLoader.setDRACOLoader(dracoLoader); 

      gltfLoader.load(
        modelPath,
        (gltf) => {

          setModel(gltf);
          setLoading(false);
        },
        undefined,
        (error) => {
          console.error("An error happened", error);
          setLoading(false);
        }
      );
    }
  }, [modelPath, visible]);

  return { model, loading };
}

export default useGLBLoader;
