import useGLBLoader from '../../../../useGlbLoader.js';
import { TwoDoorWranglerParts } from '../Constants/ModelConstants.js';

const useHoodContainer = (visibleItems) => {

  let darleneMosconeHood = useGLBLoader(
    TwoDoorWranglerParts.Hoods.DarleneMosconeHood,
    visibleItems.includes("Darlene Moscone Hood")
  );

  let jessicaVenableHood = useGLBLoader(
    TwoDoorWranglerParts.Hoods.JessicaVenableHood,
    visibleItems.includes("Jessica Venable Hood")
  );

  let robertHutchingsHood = useGLBLoader(
    TwoDoorWranglerParts.Hoods.RobertHutchingsHood,
    visibleItems.includes("Robert Hutchings Hood")
  );

  let simpleHood = useGLBLoader(
    TwoDoorWranglerParts.Hoods.SimpleHood,
    visibleItems.includes("Simple Hood")
  );

  let yaseanBruceHood = useGLBLoader(
    TwoDoorWranglerParts.Hoods.YaseanBruceHood,
    visibleItems.includes("Yasean Bruce Hood")
  );

  let rubiconHood = useGLBLoader(
    TwoDoorWranglerParts.Hoods.rubiconHood,
    visibleItems.includes("Rubicon Hood")
  );

  const hoods = [
    { name: "Darlene Moscone Hood", model: darleneMosconeHood },
    { name: "Jessica Venable Hood", model: jessicaVenableHood },
    { name: "Robert Hutchings Hood", model: robertHutchingsHood },
    { name: "Simple Hood", model: simpleHood },
    { name: "Yasean Bruce Hood", model: yaseanBruceHood },
    { name: "Rubicon Hood", model: rubiconHood },
  ];

  return {
    hoods
  };
};

export default useHoodContainer;
