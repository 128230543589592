import useGLBLoader from '../../../../useGlbLoader.js';
import { TwoDoorWranglerParts } from '../Constants/ModelConstants.js';

const useSideStepContainer = (visibleItems) => {
  let chrisWilsonLeftSideStep = useGLBLoader(
    TwoDoorWranglerParts.SideSteps.ChrisWilsonLeftSideStep,
    visibleItems.includes("Chris Hill Side Step")
  );

  let chrisWilsonRightSideStep = useGLBLoader(
    TwoDoorWranglerParts.SideSteps.ChrisWilsonRightSideStep,
    visibleItems.includes("Chris Hill Side Step")
  );

  let yaseanBruceLeftSideStep = useGLBLoader(
    TwoDoorWranglerParts.SideSteps.YaseanBruceLeftSideStep,
    visibleItems.includes("Yasean Bruce Side Step")
  );

  let yaseanBruceRightSideStep = useGLBLoader(
    TwoDoorWranglerParts.SideSteps.YaseanBruceRightSideStep,
    visibleItems.includes("Yasean Bruce Side Step")
  );

  // Group the models into arrays
  let chrisWilsonSideSteps = [
    chrisWilsonLeftSideStep,
    chrisWilsonRightSideStep,
  ];

  let yaseanBruceSideSteps = [
    yaseanBruceLeftSideStep,
    yaseanBruceRightSideStep,
  ];

  // Create an array to hold all the side steps
  const sideStepsArray = [
    { name: "Chris Hill Side Step", models: chrisWilsonSideSteps },
    { name: "Yasean Bruce Side Step", models: yaseanBruceSideSteps },
  ];

  return {
    sideStepsArray,
  };
};

export default useSideStepContainer;
