import React, { useState, useRef, useEffect } from "react";
import { Button, Divider, Grid } from "@mui/material";
import { Delete, LocalMall } from "@mui/icons-material";

import { Link, useNavigate } from "react-router-dom";
import homeHero from "../assets/svg/homePageHero.svg";
import bumper from "../assets/images/bumper.png";
import body from "../assets/images/body.png";

const Wishlist = () => {
  const navigation = useNavigate();

  const [WishList, setWishList] = useState([]);
  // const [WishListTotal, setWishListTotal] = useState(0);

  const [cart, setCart] = useState([
    { quantity: 1, price: 1500 },
    { quantity: 1, price: 3500 },
  ]);

  const handleDelete = async (id) => {
    const current = WishList;
    const newList = await current.filter((item) => {
      return item.id !== id;
    });
    localStorage.removeItem("Wishlist");
    localStorage.setItem("Wishlist", JSON.stringify(newList));
    setWishList(newList);
  };

  const handleAddToCart = async (product) => {
    let currentCart = [];
    const cart = localStorage.getItem("Cart");
    if (cart) {
      currentCart = JSON.parse(cart);
    }

    const index = currentCart.findIndex((item) => {
      return item.id === product.id;
    });
    if (index !== -1) {
      const filtered = currentCart.filter((item) => {
        return item.id === product.id;
      });
      currentCart.splice(index, 1);
      currentCart.push({
        id: filtered[0].id,
        productName: filtered[0].productName,
        productDescription: filtered[0].productDescription,
        productImage: filtered[0].productImage,
        productPrice: filtered[0].productPrice,
        Quantity: filtered[0].Quantity + 1,
        vehicleParts: filtered[0].vehicleParts,
      });
    } else {
      currentCart.push({
        id: product.id,
        productName: product.productName,
        productDescription: product.productDescription,
        productImage: product.productImage,
        productPrice: product.productPrice,
        Quantity: 1,
        vehicleParts: product.vehicleParts,
      });
    }

    localStorage.removeItem("Cart");
    localStorage.setItem("Cart", JSON.stringify(currentCart));

    const current = WishList;
    const newList = await current.filter((item) => {
      return item.id !== product.id;
    });
    localStorage.removeItem("Wishlist");
    localStorage.setItem("Wishlist", JSON.stringify(newList));
    setWishList(newList);

    navigation("/cart");
  };

  useEffect(() => {
    window.scrollTo(0, 700);
  }, []);

  useEffect(() => {
    const wishlist = localStorage.getItem("Wishlist");
    if (wishlist) {
      const currentwishlist = JSON.parse(wishlist);
      setWishList(currentwishlist);
    }
  }, []);

  return (
    <div>
      <div className="shoppage-hero-container">
        <Grid container spacing={2}>
          <Grid
            item
            md={6}
            sm={12}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
            }}
          >
            <div className="cart-hero-container">
              <p className="cart-hero-title  Akira-Expanded">Wishlist</p>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                }}
              >
                <p className="cart-hero-description Montserrat-Regular">
                  HOME - Wishlist
                </p>
                <div className="divider-hero"></div>
              </div>
            </div>
          </Grid>
          <Grid item md={6} sm={0} className="hero-section-image">
            <img src={homeHero} alt="" style={{ width: "100%" }} />
          </Grid>
        </Grid>
      </div>

      <div
        style={{
          width: "50%",
          height: 50,
          backgroundColor: "#111111",
          marginTop: -50,
          borderTopRightRadius: 50,
        }}
      ></div>

      <div className="cart-table-background">
        <table className="cart-table-container">
          <thead className="cart-table-head">
            <th className="cart-table-column cart-table-main-title Akira-Expanded">
              ITEMS
            </th>
            <th className="cart-table-column cart-mid-column cart-table-main-title Akira-Expanded">
              VEHICLE PARTS
            </th>
            <th className="cart-table-column cart-mid-column cart-table-main-title Akira-Expanded">
              PRICING
            </th>
            <th className="cart-table-column cart-table-main-title Akira-Expanded">
              ACTIONS
            </th>
          </thead>
          <tbody>
            {WishList.map((item) => {
              return (
                <tr className="cart-table-row">
                  <td className="cart-table-body-column cart-table-main-title">
                    <img className="cart-image" src={item.productImage} />
                  </td>
                  <td className="cart-table-body-column cart-table-main-title">
                    <span>{item.productName}</span>
                    <br />
                    <span style={{ fontSize: 13 }}>
                      {item.vehicleParts.join(", ")}
                    </span>
                  </td>
                  <td className="cart-table-body-column cart-table-description">
                    <p className="cart-table-price">
                      {" "}
                      {"$" + item.price !== undefined &&
                        item.price !== null &&
                        item.productPrice.toLocaleString()}
                    </p>
                  </td>
                  <td className="cart-table-body-column">
                    <div className="cart-table-price-container">
                      <Button
                        onClick={(e) => handleAddToCart(item)}
                        className="wishlist-add-to-cart"
                      >
                        <span className="wishlist-add-to-cart-text">
                          Add To Cart
                        </span>
                        <LocalMall className="wishlist-add-to-cart-icon" />
                      </Button>
                      <Button
                        onClick={(e) => handleDelete(item.id)}
                        className="main-button cart-table-button"
                      >
                        <Delete />
                      </Button>
                    </div>
                  </td>
                </tr>
              );
            })}

            {/* <tr className="cart-table-row">
              <td className="cart-table-body-column cart-table-main-title">
                <img className="cart-image" src={body} />
              </td>
              <td className="cart-table-body-column cart-table-main-title">
                BODY
              </td>
              <td className="cart-table-body-column cart-table-description">
                <p className="cart-table-price">$3,500</p>
              </td>
              <td className="cart-table-body-column">
                <div className="cart-table-price-container">
                  <Button className="wishlist-add-to-cart">
                    <span className="wishlist-add-to-cart-text">
                      Add To Cart
                    </span>
                    <LocalMall className="wishlist-add-to-cart-icon" />
                  </Button>
                  <Button className="main-button cart-table-button">
                    <Delete />
                  </Button>
                </div>
              </td>
            </tr> */}
          </tbody>
          {/* <td
            colSpan={4}
            style={{
              width: "100%",
              backgroundColor: "#505050",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <span className="cart-total">TOTAL PRICE</span>
              <span className="cart-table-price">{"$" + cartTotal}</span>
            </div>
          </td> */}
        </table>
      </div>

      <div className="mid-banner-2">
        <div>
          <p
            style={{
              fontSize: "18px",
            }}
            className="Montserrat-Regular"
          >
            DRIVE BEYOND BOUNDARIES
          </p>
        </div>
        <p
          style={{
            fontSize: "36px",
            lineHeight: "52px",
            textAlign: "center",
          }}
          className="Akira-Expanded"
        >
          CUSTOMIZE DESIGN
        </p>
        <Link
          onClick={(e) => {
            localStorage.removeItem("modelParts");
          }}
          to="/build-your-own?q=0001"
          style={{ textDecoration: "none", color: "black" }}
        >
          <Button className="main-button">Customize Your Jeep</Button>
        </Link>
      </div>
    </div>
  );
};

export default Wishlist;
