import useGLBLoader from '../../../../useGlbLoader.js';
import { FourDoorGradiatorParts } from '../Constants/ModelConstants.js';

const useSideStepContainer = (visibleItems) => {
  let chrisHillSideStepLeft = useGLBLoader(
    FourDoorGradiatorParts.SideSteps.ChrisHillSideStepLeft,
    visibleItems.includes("Chris Hill Side Step")
  );

  let chrisHillSideStepRight = useGLBLoader(
    FourDoorGradiatorParts.SideSteps.ChrisHillSideStepRight,
    visibleItems.includes("Chris Hill Side Step")
  );

  let karenLyndonLeftSideStep = useGLBLoader(
    FourDoorGradiatorParts.SideSteps.KarenLyndonLeftSideStep,
    visibleItems.includes("Karen Lyndon Side Step")
  );

  let karenLyndonRightSideStep = useGLBLoader(
    FourDoorGradiatorParts.SideSteps.KarenLyndonRightSideStep,
    visibleItems.includes("Karen Lyndon Side Step")
  );

  let kevinSideStepLeft = useGLBLoader(
    FourDoorGradiatorParts.SideSteps.KevinSideStepLeft,
    visibleItems.includes("Kevin Side Step")
  );

  let kevinSideStepRight = useGLBLoader(
    FourDoorGradiatorParts.SideSteps.KevinSideStepRight,
    visibleItems.includes("Kevin Side Step")
  );

  let reganSideStepLeft = useGLBLoader(
    FourDoorGradiatorParts.SideSteps.ReganSideStepLeft,
    visibleItems.includes("Regan Side Step")
  );

  let reganSideStepRight = useGLBLoader(
    FourDoorGradiatorParts.SideSteps.ReganSideStepRight,
    visibleItems.includes("Regan Side Step")
  );

  // Group the models into arrays
  let chrisHillSideSteps = [
    chrisHillSideStepLeft,
    chrisHillSideStepRight,
  ];

  let karenLyndonSideSteps = [
    karenLyndonLeftSideStep,
    karenLyndonRightSideStep,
  ];

  let kevinSideSteps = [
    kevinSideStepLeft,
    kevinSideStepRight,
  ];

  let reganSideSteps = [
    reganSideStepLeft,
    reganSideStepRight,
  ];

  // Create an array to hold all the side steps
  const sideStepsArray = [
    { name: "Chris Hill Side Step", models: chrisHillSideSteps },
    { name: "Karen Lyndon Side Step", models: karenLyndonSideSteps },
    { name: "Kevin Side Step", models: kevinSideSteps },
    { name: "Regan Side Step", models: reganSideSteps },
  ];

  return {
    sideStepsArray,
  };
};

export default useSideStepContainer;
