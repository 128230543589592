// Bumpers
import CoreyJonesBumperImage from "../../../../assets/GradiaterImages/CoreyJonesBumper.png";
import CoveredBumperImage from "../../../../assets/GradiaterImages/CoveredBumper.png";
import DarleneMosconeBumperImage from "../../../../assets/GradiaterImages/DarleneMosconeBumper.png";
import DustinHemeurRig2BumperImage from "../../../../assets/GradiaterImages/DustinHemeurRig2Bumper.png";
import DustinHemeurRig3BumperImage from "../../../../assets/GradiaterImages/DustinHemeurRig3Bumper.png";
import ElizabethVanesBumperImage from "../../../../assets/GradiaterImages/ElizabethVanesBumper.png";
import GlenJonesBumperImage from "../../../../assets/GradiaterImages/GlenJonesBumper.png";
import JasonCunninghamBumperImage from "../../../../assets/GradiaterImages/JasonCunninghamBumper.png";
import JasonMartinoBumperImage from "../../../../assets/GradiaterImages/JasonMartinoBumper.png";
import JessicaVenableBumperImage from "../../../../assets/GradiaterImages/JessicaVenableBumper.png";
import JonathanMclarenBumperImage from "../../../../assets/GradiaterImages/JonathanMclarenBumper.png";
import JoyGarnerBumperImage from "../../../../assets/GradiaterImages/JoyGarnerBumper.png";
import KevinKanBumperImage from "../../../../assets/GradiaterImages/KevinKanBumper.png";
import KevinOotenBumperImage from "../../../../assets/GradiaterImages/KevinOotenBumper.png";
import LeslieMedranoBumperImage from "../../../../assets/GradiaterImages/LeslieMedranoBumper.png";
import RIckValentinBumperImage from "../../../../assets/GradiaterImages/RIckValentinBumper.png";
import RobertHutchingsBumperImage from "../../../../assets/GradiaterImages/RobertHutchingsBumper.png";
import SharonFullmerBumperImage from "../../../../assets/GradiaterImages/SharonFullmerBumper.png";
import TammyMcCormickBumperImage from "../../../../assets/GradiaterImages/TammyMcCormickBumper.png";
import TimTraynorBumperImage from "../../../../assets/GradiaterImages/TimTraynorBumper.png";

// Doors
import RandyLyonDoor from "../../../../assets/GradiaterImages/RandyLyonDoor.png";
import SimpleDoor from "../../../../assets/GradiaterImages/SimpleDoor.png";
import SteveSmithDoor from "../../../../assets/GradiaterImages/SteveSmithDoor.png";
import TubularDoor from "../../../../assets/GradiaterImages/TubularDoor.png";

// Others
import DarleneMoscocneSnorkel from "../../../../assets/GradiaterImages/DarleneMoscocneSnorkel.png";
import JamesSimpsonRoofRack from "../../../../assets/GradiaterImages/JamesSimpsonRoofRack.png";
import AdamWagner from "../../../../assets/NewTwoDoorImages/AdamWagner.png";
import AndreaSimsFender from "../../../../assets/NewTwoDoorImages/AndreaSimsFender.png";
import ChristinaCabraFender from "../../../../assets/NewTwoDoorImages/ChristinaCabraFender.png";
import SnowwolfFender from "../../../../assets/NewTwoDoorImages/SnowwolfFender.png";
import ToyotaTacomaFender from "../../../../assets/NewTwoDoorImages/ToyotaTacomaFender.png";


// Hood
import SimpleHood from "../../../../assets/GradiaterImages/SimpleHood.png";
import YaseanBruceHood from "../../../../assets/GradiaterImages/YaseanBruceHood.png";
import JessicaVenableHood from "../../../../assets/GradiaterImages/JessicaVenableHood.png";
import DarleneMoscocneHood from "../../../../assets/GradiaterImages/DarleneMoscocneHood.png";
import RobertHutchingsHood from "../../../../assets/GradiaterImages/RobertHutchingsHood.png";
import RubiconHood from "../../../../assets/NewTwoDoorImages/rubiconhood.png";
// Grills
import FlatGrill from "../../../../assets/GradiaterImages/FlatGrill.png";
import GlenJonesGrill from "../../../../assets/GradiaterImages/GlenJonesGrill.png";
import ShawnOsburnGrill from "../../../../assets/GradiaterImages/ShawnOsburnGrill.png";
import MarkJHanscomGrill from "../../../../assets/GradiaterImages/MarkJHanscomGrill.png";
import MichaelKnappGrill from "../../../../assets/GradiaterImages/MichaelKnappGrill.png";
import RhiMcclendonGrill from "../../../../assets/GradiaterImages/RhiMcclendonGrill.png";
import ClaireGaretteGrill from "../../../../assets/GradiaterImages/ClaireGaretteGrill.png";
import RickyBeilchnerGrill from "../../../../assets/GradiaterImages/RickyBeilchnerGrill.png";

// SideSteps
import ChrisHillSideStep from "../../../../assets/GradiaterImages/ChrisHillSideStep.png";
import KarenLyndonSideStep from "../../../../assets/GradiaterImages/KarenLyndonSideStep.png";
import KevinSideStep from "../../../../assets/GradiaterImages/KevinSideStep.png";
import ReganSideStep from "../../../../assets/GradiaterImages/ReganSideStep.png";

// Wheels
import AaronGordonLBWheel from "../../../../assets/GradiaterImages/AaronGordonLBWheel.png";
import ArkonLBWheel from "../../../../assets/GradiaterImages/ArkonLBWheel.png";
import BethanySmeltzerLBWheel from "../../../../assets/GradiaterImages/BethanySmeltzerLBWheel.png";
import BillHoughtonLBWheel from "../../../../assets/GradiaterImages/BillHoughtonLBWheel.png";
import BlackRhinoLBWheel from "../../../../assets/GradiaterImages/BlackRhinoLBWheel.png";
import BlitzLBWheel from "../../../../assets/GradiaterImages/BlitzLBWheel.png";
import BraydonLBWheel from "../../../../assets/GradiaterImages/BraydonLBWheel.png";
import CallieLemmingLBWheel from "../../../../assets/GradiaterImages/CallieLemmingLBWheel.png";
import ClaireGaretteLBWheel from "../../../../assets/GradiaterImages/ClaireGaretteLBWheel.png";
import ColterBahlauLBWheel from "../../../../assets/GradiaterImages/ColterBahlauLBWheel.png";
import CoreyJonesBLWheel from "../../../../assets/GradiaterImages/CoreyJonesBLWheel.png";
import DaniColemanBLWheel from "../../../../assets/GradiaterImages/DaniColemanBLWheel.png";
import DarleneMosconeBLWheel from "../../../../assets/GradiaterImages/DarleneMosconeBLWheel.png";
import DavidShaferBLWheel from "../../../../assets/GradiaterImages/DavidShaferBLWheel.png";
import DustinHemauerBLWheel from "../../../../assets/GradiaterImages/DustinHemauerBLWheel.png";
import GlenJonesBLWheel from "../../../../assets/GradiaterImages/GlenJonesBLWheel.png";
import JaredRenfroeBLWheel from "../../../../assets/GradiaterImages/JaredRenfroeBLWheel.png";
import JasonMartinoFRheel from "../../../../assets/GradiaterImages/JasonMartinoFRWheel.png";
import JonathenMclarenWheel from "../../../../assets/GradiaterImages/JonathenMclarenWheel.png";
import JoyGarnerWheel from "../../../../assets/GradiaterImages/JoyGarnerWheel.png";
import KevinKanWheel from "../../../../assets/GradiaterImages/KevinKanWheel.png";
import MarkJHanscomWheel from "../../../../assets/GradiaterImages/MarkJHanscomWheel.png";

export const modelImages = {
  bumperImages: {
    CoreyJonesBumperImage,
    CoveredBumperImage,
    DarleneMosconeBumperImage,
    DustinHemeurRig2BumperImage,
    DustinHemeurRig3BumperImage,
    ElizabethVanesBumperImage,
    GlenJonesBumperImage,
    JasonCunninghamBumperImage,
    JasonMartinoBumperImage,
    JessicaVenableBumperImage,
    JonathanMclarenBumperImage,
    JoyGarnerBumperImage,
    KevinKanBumperImage,
    KevinOotenBumperImage,
    LeslieMedranoBumperImage,
    RIckValentinBumperImage,
    RobertHutchingsBumperImage,
    SharonFullmerBumperImage,
    TammyMcCormickBumperImage,
    TimTraynorBumperImage,
  },
  doorImages: {
    RandyLyonDoor,
    SimpleDoor,
    SteveSmithDoor,
    TubularDoor,
  },
  otherImages: {
    DarleneMoscocneSnorkel,
    JamesSimpsonRoofRack,
    AdamWagner,
    AndreaSimsFender,
    ChristinaCabraFender,
    SnowwolfFender,
    ToyotaTacomaFender,
  },
  hoodImages: {
    DarleneMoscocneHood,
    JessicaVenableHood,
    RobertHutchingsHood,
    SimpleHood,
    YaseanBruceHood,
    RubiconHood
  },
  grillImages: {
    ClaireGaretteGrill,
    FlatGrill,
    GlenJonesGrill,
    MarkJHanscomGrill,
    MichaelKnappGrill,
    RhiMcclendonGrill,
    RickyBeilchnerGrill,
    ShawnOsburnGrill,
  },
  sideStepImages: {
    ChrisHillSideStep,
    KarenLyndonSideStep,
    KevinSideStep,
    ReganSideStep,
  },
  wheelImages: {
    AaronGordonLBWheel,
    ArkonLBWheel,
    BethanySmeltzerLBWheel,
    BillHoughtonLBWheel,
    BlackRhinoLBWheel,
    BlitzLBWheel,
    BraydonLBWheel,
    CallieLemmingLBWheel,
    ClaireGaretteLBWheel,
    ColterBahlauLBWheel,
    CoreyJonesBLWheel,
    DaniColemanBLWheel,
    DarleneMosconeBLWheel,
    DavidShaferBLWheel,
    DustinHemauerBLWheel,
    GlenJonesBLWheel,
    JaredRenfroeBLWheel,
    JonathenMclarenWheel,
    JoyGarnerWheel,
    KevinKanWheel,
    MarkJHanscomWheel,
    JasonMartinoFRheel,

  },
};
