import { LocalMall } from "@mui/icons-material";
import { Button } from "@mui/material";
import React from "react";

const ProductDetail = ({
  productDetails,
  TotalPartsPrice,
  visibleItems,
  handleAddToCart,
}) => {
  return (
    <div className="product-detail-container">
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          paddingRight: 20,
        }}
      >
        <p
          className="product-detail-heading  Akira-Expanded"
          style={{ color: "black" }}
        >
          PRODUCT DETAIL
        </p>
        <div className="product-detail-header-divider"></div>
      </div>

      <div style={{ textAlign: "left", padding: "0px 20px" }}>
        <div className="about-vision-text">
          <p style={{ margin: "10px 10px" }}>
            Your custom Jeep will be custom designed, 3D Printed, assembled and
            shipped in a 4-5 week time frame. You will be receiving regular
            updates via the email provided at check out. We look forward to
            turning your Jeep into a MY3DJEEP!
          </p>
        </div>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            margin: "20px 0px",
          }}
        >
          <div className="product-detail-shop-button">
            <div style={{ margin: "0px 10px" }}>
              <p className="product-price-text" style={{ color: "#000" }}>
                Vehicle Price
              </p>
              <p className="product-price-number">
                {productDetails !== null
                  ? "$ " +
                    (
                      productDetails.price + TotalPartsPrice
                    ).toLocaleString()
                  : "$ 0"}
              </p>
            </div>

            <Button
              style={{
                width: 230,
                height: 50,
                borderRadius: 0,
                margin: "0px 5px",
              }}
              className="main-button"
              onClick={(e) =>
                handleAddToCart({
                  _id: productDetails !== null ? productDetails._id : "",
                  name: productDetails !== null ? productDetails.name : "",
                  description:
                    productDetails !== null
                      ? productDetails.description
                      : "",
                  titleImageURL:
                    productDetails !== null
                      ? productDetails.titleImageURL
                      : "",
                  price: productDetails !== null ? productDetails.price : 0,
                  vehicleParts: visibleItems,
                })
              }
            >
              Add To Cart
              <LocalMall
                style={{
                  margin: "0px 10px",
                  color: "#ffffff",
                  fontSize: "28px",
                }}
              />
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductDetail;
