import useGLBLoader from "../../../../useGlbLoader";
import { FourDoorGradiatorParts } from "../Constants/ModelConstants";

const useBumperContainer = (visibleItems) => {
  
  let coreyJonesBumper = useGLBLoader(
    FourDoorGradiatorParts.Bumpers.CoreyJonesBumper,
    visibleItems.includes("Corey Jones Bumper")
  );
  
  let coveredBumper = useGLBLoader(
    FourDoorGradiatorParts.Bumpers.CoveredBumper,
    visibleItems.includes("Covered Bumper")
  );
  
  let darleneMosconeBumper = useGLBLoader(
    FourDoorGradiatorParts.Bumpers.DarleneMosconeBumper,
    visibleItems.includes("Darlene Moscone Bumper")
  );
  
  let dustinHemeurRig2Bumper = useGLBLoader(
    FourDoorGradiatorParts.Bumpers.DustinHemeurRig2Bumper,
    visibleItems.includes("Dustin Hemeur Rig 2 Bumper")
  );
  
  let dustinHemeurRig3Bumper = useGLBLoader(
    FourDoorGradiatorParts.Bumpers.DustinHemeurRig3Bumper,
    visibleItems.includes("Dustin Hemeur Rig 3 Bumper")
  );
  
  let elizabethVanesBumper = useGLBLoader(
    FourDoorGradiatorParts.Bumpers.ElizabethVanesBumper,
    visibleItems.includes("Elizabeth Vanes Bumper")
  );
  
  let glenJonesBumper = useGLBLoader(
    FourDoorGradiatorParts.Bumpers.GlenJonesBumper,
    visibleItems.includes("Glen Jones Bumper")
  );
  
  let jasonCunninghamBumper = useGLBLoader(
    FourDoorGradiatorParts.Bumpers.JasonCunninghamBumper,
    visibleItems.includes("Jason Cunningham Bumper")
  );
  
  let jasonMartinoBumper = useGLBLoader(
    FourDoorGradiatorParts.Bumpers.JasonMartinoBumper,
    visibleItems.includes("Jason Martino Bumper")
  );
  
  let jessicaVenableBumper = useGLBLoader(
    FourDoorGradiatorParts.Bumpers.JessicaVenableBumper,
    visibleItems.includes("Jessica Venable Bumper")
  );
  
  let jonathanMclarenBumper = useGLBLoader(
    FourDoorGradiatorParts.Bumpers.JonathanMclarenBumper,
    visibleItems.includes("Jonathan Mclaren Bumper")
  );
  
  let joyGarnerBumper = useGLBLoader(
    FourDoorGradiatorParts.Bumpers.JoyGarnerBumper,
    visibleItems.includes("Joy Garner Bumper")
  );
  
  let kevinKanBumper = useGLBLoader(
    FourDoorGradiatorParts.Bumpers.KevinKanBumper,
    visibleItems.includes("Kevin Kan Bumper")
  );
  
  let kevinOotenBumper = useGLBLoader(
    FourDoorGradiatorParts.Bumpers.KevinOotenBumper,
    visibleItems.includes("Kevin Ooten Bumper")
  );
  
  let leslieMedranoBumper = useGLBLoader(
    FourDoorGradiatorParts.Bumpers.LeslieMedranoBumper,
    visibleItems.includes("Leslie Medrano Bumper")
  );
  
  let rickValentinBumper = useGLBLoader(
    FourDoorGradiatorParts.Bumpers.RIckValentinBumper,
    visibleItems.includes("Rick Valentin Bumper")
  );
  
  let robertHutchingsBumper = useGLBLoader(
    FourDoorGradiatorParts.Bumpers.RobertHutchingsBumper,
    visibleItems.includes("Robert Hutchings Bumper")
  );
  
  let sharonFullmerBumper = useGLBLoader(
    FourDoorGradiatorParts.Bumpers.SharonFullmerBumper,
    visibleItems.includes("Sharon Fullmer Bumper")
  );
  
  let tammyMcCormickBumper = useGLBLoader(
    FourDoorGradiatorParts.Bumpers.TammyMcCormickBumper,
    visibleItems.includes("Tammy McCormick Bumper")
  );
  
  let timTraynorBumper = useGLBLoader(
    FourDoorGradiatorParts.Bumpers.TimTraynorBumper,
    visibleItems.includes("Tim Traynor Bumper")
  );

  const bumpers = [
    { name: "Corey Jones Bumper", model: coreyJonesBumper },
    { name: "Covered Bumper", model: coveredBumper },
    { name: "Darlene Moscone Bumper", model: darleneMosconeBumper },
    { name: "Dustin Hemeur Rig 2 Bumper", model: dustinHemeurRig2Bumper },
    { name: "Dustin Hemeur Rig 3 Bumper", model: dustinHemeurRig3Bumper },
    { name: "Elizabeth Vanes Bumper", model: elizabethVanesBumper },
    { name: "Glen Jones Bumper", model: glenJonesBumper },
    { name: "Jason Cunningham Bumper", model: jasonCunninghamBumper },
    { name: "Jason Martino Bumper", model: jasonMartinoBumper },
    { name: "Jessica Venable Bumper", model: jessicaVenableBumper },
    { name: "Jonathan Mclaren Bumper", model: jonathanMclarenBumper },
    { name: "Joy Garner Bumper", model: joyGarnerBumper },
    { name: "Kevin Kan Bumper", model: kevinKanBumper },
    { name: "Kevin Ooten Bumper", model: kevinOotenBumper },
    { name: "Leslie Medrano Bumper", model: leslieMedranoBumper },
    { name: "Rick Valentin Bumper", model: rickValentinBumper },
    { name: "Robert Hutchings Bumper", model: robertHutchingsBumper },
    { name: "Sharon Fullmer Bumper", model: sharonFullmerBumper },
    { name: "Tammy McCormick Bumper", model: tammyMcCormickBumper },
    { name: "Tim Traynor Bumper", model: timTraynorBumper },
  ];

  return {
    bumpers
  };
};

export default useBumperContainer;
