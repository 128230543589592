import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "#2b2b2b",
  border: "2px solid #000",
  boxShadow: 24,
  p: 2,
};

export default function PopupModal({ open, modalText, handleClose }) {
  return (
    <div>
      <Modal
        keepMounted
        open={open}
        onClose={handleClose}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={style}>
          <Typography
            color={"#fff"}
            fontWeight={"600"}
            id="keep-mounted-modal-title"
            variant="h6"
            component="h6"
          >
            Confirmation
          </Typography>
          <Typography
            color={"#fff"}
            id="keep-mounted-modal-description"
            sx={{ mt: 2 }}
          >
            {modalText}
          </Typography>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button onClick={handleClose} className="main-button">
              Close
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
