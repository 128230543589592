// Bumpers
import CoreyJonesBumper from "../../../../../assets/NewTwoDoorImages/CoreyJonesBumper.png";
import CoveredBumper from "../../../../../assets/NewTwoDoorImages/CoveredBumper.png";
import DarleneMosconeBumper from "../../../../../assets/NewTwoDoorImages/DarleneMosconeBumper.png";
import DustinHemeurRig2Bumper from "../../../../../assets/NewTwoDoorImages/DustinHemeurRig2Bumper.png";
import DustinHemeurRig3Bumper from "../../../../../assets/NewTwoDoorImages/DustinHemeurRig3Bumper.png";
import ElizabethVanesBumper from "../../../../../assets/NewTwoDoorImages/ElizabethVanesBumper.png";
import GlenJonesBumper from "../../../../../assets/NewTwoDoorImages/GlenJonesBumper.png";
import JasonCunninghamBumper from "../../../../../assets/NewTwoDoorImages/JasonCunninghamBumper.png";
import JasonMartinoBumper from "../../../../../assets/NewTwoDoorImages/JasonMartinoBumper.png";
import JessicaVenableBumper from "../../../../../assets/NewTwoDoorImages/JessicaVenableBumper.png";
import JonathanMclarenBumper from "../../../../../assets/NewTwoDoorImages/JonathanMclarenBumper.png";
import JoyGarnerBumper from "../../../../../assets/NewTwoDoorImages/JoyGarnerBumper.png";
import KevinKanBumper from "../../../../../assets/NewTwoDoorImages/KevinKanBumper.png";
import KevinOotenBumper from "../../../../../assets/NewTwoDoorImages/KevinOotenBumper.png";
import LeslieMedranoBumper from "../../../../../assets/NewTwoDoorImages/LeslieMedranoBumper.png";
import RIckValentinBumper from "../../../../../assets/NewTwoDoorImages/RIckValentinBumper.png";
import RobertHutchingsBumper from "../../../../../assets/NewTwoDoorImages/RobertHutchingsBumper.png";
import SharonFullmerBumper from "../../../../../assets/NewTwoDoorImages/SharonFullmerBumper.png";
import TammyMcCormickBumper from "../../../../../assets/NewTwoDoorImages/TammyMcCormickBumper.png";
import TimTraynorBumper from "../../../../../assets/NewTwoDoorImages/TimTraynorBumper.png";

// Doors
import SimpleDoor from "../../../../../assets/NewTwoDoorImages/SimpleDoor.png";

// Hoods
import RubiconHood from "../../../../../assets/NewTwoDoorImages/rubiconhood.png";
import DarleneMoscocneHood from "../../../../../assets/NewTwoDoorImages/DarleneMoscocneHood.bbd48471eece16fda35a.png";
import JessicaVenableHood from "../../../../../assets/NewTwoDoorImages/JessicaVenableHood.2cde0aa8e0ebaf31c753.png";
import RobertHutchingsHood from "../../../../../assets/NewTwoDoorImages/RobertHutchingsHood.png";
import SimpleHood from "../../../../../assets/NewTwoDoorImages/SimpleHood.png";
import YaseanBruceHood from "../../../../../assets/NewTwoDoorImages/YaseanBruceHood.png";

// Grills
import ClaireGaretteGrill from "../../../../../assets/NewTwoDoorImages/ClaireGaretteGrill.png";
import FlatGrill from "../../../../../assets/NewTwoDoorImages/FlatGrill.png";
import GlenJonesGrill from "../../../../../assets/NewTwoDoorImages/GlenJonesGrill.png";
import MarkJHanscomGrill from "../../../../../assets/NewTwoDoorImages/MarkJHanscomGrill.png";
import MichaelKnappGrill from "../../../../../assets/NewTwoDoorImages/MichaelKnappGrill.png";
import RhiMcclendonGrill from "../../../../../assets/NewTwoDoorImages/RhiMcclendonGrill.png";
import RickyBeilchnerGrill from "../../../../../assets/NewTwoDoorImages/RickyBeilchnerGrill.png";
import ShawnOsburnGrill from "../../../../../assets/NewTwoDoorImages/ShawnOsburnGrill.png";

// SideSteps
import ChrisHillSideStep from "../../../../../assets/NewTwoDoorImages/ChrisHillSideStep.png";
import YaseanBruceSideStep from "../../../../../assets/NewTwoDoorImages/YaseanBruceSideStep.png";

// Wheels
import AaronGordonLBWheel from "../../../../../assets/NewTwoDoorImages/AaronGordonLBWheel.png";
import ArkonLBWheel from "../../../../../assets/NewTwoDoorImages/ArkonLBWheel.png";
import BethanySmeltzerLBWheel from "../../../../../assets/NewTwoDoorImages/BethanySmeltzerLBWheel.png";
import BillHoughtonLBWheel from "../../../../../assets/NewTwoDoorImages/BillHoughtonLBWheel.png";
import BlackRhinoLBWheel from "../../../../../assets/NewTwoDoorImages/BlackRhinoLBWheel.png";
import BlitzLBWheel from "../../../../../assets/NewTwoDoorImages/BlitzLBWheel.png";
import BraydonLBWheel from "../../../../../assets/NewTwoDoorImages/BraydonLBWheel.png";
import CallieLemmingLBWheel from "../../../../../assets/NewTwoDoorImages/CallieLemmingLBWheel.png";
import ClaireGaretteLBWheel from "../../../../../assets/NewTwoDoorImages/ClaireGaretteLBWheel.png";
import ColterBahlauLBWheel from "../../../../../assets/NewTwoDoorImages/ColterBahlauLBWheel.png";
import CoreyJonesBLWheel from "../../../../../assets/NewTwoDoorImages/CoreyJonesBLWheel.png";
import DaniColemanBLWheel from "../../../../../assets/NewTwoDoorImages/DaniColemanBLWheel.png";
import DarleneMosconeBLWheel from "../../../../../assets/NewTwoDoorImages/DarleneMosconeBLWheel.png";
import DavidShaferBLWheel from "../../../../../assets/NewTwoDoorImages/DavidShaferBLWheel.png";
import DustinHemauerBLWheel from "../../../../../assets/NewTwoDoorImages/DustinHemauerBLWheel.png";
import GlenJonesBLWheel from "../../../../../assets/NewTwoDoorImages/GlenJonesBLWheel.png";
import JaredRenfroeBLWheel from "../../../../../assets/NewTwoDoorImages/JaredRenfroeBLWheel.png";
import JasonMartinoFRWheel from "../../../../../assets/NewTwoDoorImages/JasonMartinoFRWheel.png";
import JonathenMclarenWheel from "../../../../../assets/NewTwoDoorImages/JonathenMclarenWheel.png";
import JoyGarnerWheel from "../../../../../assets/NewTwoDoorImages/JoyGarnerWheel.png";
import KevinKanWheel from "../../../../../assets/NewTwoDoorImages/KevinKanWheel.png";
import MarkJHanscomWheel from "../../../../../assets/NewTwoDoorImages/MarkJHanscomWheel.png";

// Other
import DarleneMoscocneSnorkel from "../../../../../assets/NewTwoDoorImages/DarleneMoscocneSnorkel.png";
import AdamWagner from "../../../../../assets/NewTwoDoorImages/AdamWagner.png";
import AndreaSimsFender from "../../../../../assets/NewTwoDoorImages/AndreaSimsFender.png";
import ChristinaCabraFender from "../../../../../assets/NewTwoDoorImages/ChristinaCabraFender.png";
import SnowwolfFender from "../../../../../assets/NewTwoDoorImages/SnowwolfFender.png";
import ToyotaTacomaFender from "../../../../../assets/NewTwoDoorImages/ToyotaTacomaFender.png";

export const NewTwoDoorImages = {
  wheelImages: {
    AaronGordonLBWheel,
    ArkonLBWheel,
    BethanySmeltzerLBWheel,
    BillHoughtonLBWheel,
    BlackRhinoLBWheel,
    BlitzLBWheel,
    BraydonLBWheel,
    CallieLemmingLBWheel,
    ClaireGaretteLBWheel,
    ColterBahlauLBWheel,
    CoreyJonesBLWheel,
    DaniColemanBLWheel,
    DarleneMosconeBLWheel,
    DavidShaferBLWheel,
    DustinHemauerBLWheel,
    GlenJonesBLWheel,
    JaredRenfroeBLWheel,
    JonathenMclarenWheel,
    JoyGarnerWheel,
    KevinKanWheel,
    MarkJHanscomWheel,
    JasonMartinoFRWheel,
  },
  bumperImages: {
    CoreyJonesBumper,
    CoveredBumper,
    DarleneMosconeBumper,
    DustinHemeurRig2Bumper,
    DustinHemeurRig3Bumper,
    ElizabethVanesBumper,
    GlenJonesBumper,
    JasonCunninghamBumper,
    JasonMartinoBumper,
    JessicaVenableBumper,
    JonathanMclarenBumper,
    JoyGarnerBumper,
    KevinKanBumper,
    KevinOotenBumper,
    LeslieMedranoBumper,
    RIckValentinBumper,
    RobertHutchingsBumper,
    SharonFullmerBumper,
    TammyMcCormickBumper,
    TimTraynorBumper,
  },
  grillImages: {
    ClaireGaretteGrill,
    FlatGrill,
    GlenJonesGrill,
    MarkJHanscomGrill,
    MichaelKnappGrill,
    RhiMcclendonGrill,
    RickyBeilchnerGrill,
    ShawnOsburnGrill,
  },
  sideStepImages: {
    ChrisHillSideStep,
    YaseanBruceSideStep,
  },
  hoodImages: {
    DarleneMoscocneHood,
    JessicaVenableHood,
    RobertHutchingsHood,
    SimpleHood,
    YaseanBruceHood,
    RubiconHood,
  },
  doorImages: {
    SimpleDoor,
  },
  otherImages: {
    DarleneMoscocneSnorkel,
    AdamWagner,
    AndreaSimsFender,
    ChristinaCabraFender,
    SnowwolfFender,
    ToyotaTacomaFender,
  },
};
