import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Select from "react-select";
// import logo from "../logo.svg";
// import logo from "../assets/svg/Logo-Descriptive-White.svg";
import { Button } from "@mui/material";
import logo from "../assets/svg/logo_black.svg";
import "../assets/css/Navbar.css";
import { Search, Favorite, LocalMall, Person } from "@mui/icons-material";
import { Get_Product } from "../Utilities/Products";

const Header = ({ handleShowPopup }) => {
  const navigation = useNavigate();

  const [click, setClick] = useState(false);
  const [button, setButton] = useState(true);
  const [showAuth, setShowAuth] = useState(true);

  const [searchOptions, setsearchOptions] = useState([]);
  const [inputValue, setinputValue] = useState("");
  const [selectedOption, setSelectedOption] = useState(null);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);
  const location = useLocation();

  const showButton = () => {
    if (window.innerWidth <= 960) {
      setButton(false);
    } else {
      setButton(true);
    }
  };

  useEffect(() => {
    showButton();
  }, []);

  useEffect(() => {
    initialData();
  }, []);

  const initialData = async () => {
    const array = [];
    array.push({
      value: "0001",
      label: "4D Wrangler",
    });
    array.push({
      value: "0002",
      label: "4D Gladiator",
    });
    array.push({
      value: "0003",
      label: "2D Wrangler",
    });
    const resp = await Get_Product();
    // if (resp.Status_Code === 200 && resp.IsSuccess === true) {
    //   resp.Data.map((product) => {
    //     array.push({
    //       value: product._id,
    //       label: product.name,
    //     });
    //   });
    // }

    setsearchOptions(array);
  };

  const user = localStorage.getItem("Web_User");

  useEffect(() => {
    if (user) {
      setShowAuth(false);
    } else {
      setShowAuth(true);
    }
  }, [user, location, handleShowPopup]);

  window.addEventListener("resize", showButton);

  const handleSelectSearch = async (e) => {
    setSelectedOption(null);
    setinputValue("");
    navigation("/product-details?q=" + e.value);
  };

  return (
    <nav className="navbar Gilroy-Medium">
      <div className="navbar-container">
        <Link to="/" className="navbar-logo" onClick={closeMobileMenu}>
          <img src={logo} alt="logo" className="logo-header" />
          {/* <i class="fab fa-typo3" /> */}
        </Link>
        <div className="menu-icon" onClick={handleClick}>
          <i className={click ? "fas fa-times" : "fas fa-bars"} />
        </div>
        <ul className={click ? "nav-menu active" : "nav-menu"}>
          <li className="nav-item">
            <Link to="/" className="nav-links" onClick={closeMobileMenu}>
              Home
            </Link>
          </li>
          <li className="nav-item">
            <Link
              to="https://my3djeep.printify.me/products"
              className="nav-links"
              target="_blank"
              onClick={closeMobileMenu}
            >
              Shop
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/about" className="nav-links" onClick={closeMobileMenu}>
              About
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/contact" className="nav-links" onClick={closeMobileMenu}>
              Contact
            </Link>
          </li>
          <li
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            className="nav-item nav-mobile-hide"
          >
            <div style={{ margin: "0rem 1rem" }}>
              {/* <input
                type="text"
                placeholder="Search"
                style={{
                  width: "200px",
                  height: "40px",
                  backgroundColor: "#FFFFFF",
                  fontSize: 14,
                  padding: "3px 10px",
                  border: "none",
                  marginRight: "-30px",
                }}
              /> */}
              <Select
                defaultValue={selectedOption}
                onChange={handleSelectSearch}
                options={searchOptions}
                x
                value={inputValue}
                onInputChange={(e) => setinputValue(e)}
                styles={{
                  control: (provided) => ({
                    ...provided,
                    width: 200,
                    textAlign: "left",
                    fontSize: 15,
                  }),
                  indicatorSeparator: () => ({ display: "none" }),
                  dropdownIndicator: () => ({ display: "none" }),
                  menu: (provided) => ({
                    ...provided,
                    fontSize: 15,
                    borderBottom: "1px solid grey",
                  }),
                }}
                placeholder={"Search..."}
              />
              {/* <Search /> */}
            </div>
          </li>
          <li className="nav-item nav-mobile-hide">
            <Link to="/cart" className="nav-links" onClick={closeMobileMenu}>
              <LocalMall />
            </Link>
          </li>
          <li className="nav-item nav-mobile-hide">
            <Link
              to="/wishlist"
              className="nav-links"
              onClick={closeMobileMenu}
            >
              <Favorite />
            </Link>
          </li>
          <li className="nav-item nav-mobile-hide">
            <Button
              className="nav-links-popup"
              onClick={(e) =>
                showAuth ? handleShowPopup() : navigation("/profile")
              }
            >
              {showAuth ? <Person /> : "Profile"}
            </Button>
          </li>
          <li className="nav-item nav-mobile-show">
            <Link
              to="/cart"
              className="nav-links"
              onClick={(e) => {
                closeMobileMenu();
              }}
            >
              Cart
            </Link>
          </li>
          <li className="nav-item nav-mobile-show">
            <Link
              to="/wishlist"
              className="nav-links"
              onClick={(e) => {
                closeMobileMenu();
              }}
            >
              Wishlist
            </Link>
          </li>
          <li className="nav-item nav-mobile-show">
            <Button
              className="nav-links-popup"
              onClick={(e) => {
                closeMobileMenu();
                showAuth ? handleShowPopup() : navigation("/profile");
              }}
            >
              {showAuth ? "Signin" : "Profile"}
            </Button>
          </li>
        </ul>
        {/* {button && <Button buttonStyle="btn--outline">SIGN UP</Button>} */}
      </div>
    </nav>
  );
};

export default Header;
