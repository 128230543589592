import { get, post } from "../Fetch";

export const login = async (body) => {
  const url = "api/signin";
  return post(url, body);
};

export const signup = async (body) => {
  const url = "api/signup";
  return post(url, body);
};

export const forgetPassword = async (body) => {
  const url = "api/forgetPassword";
  return post(url, body);
};

export const changePassword = async (body) => {
  const url = "api/changePassword";
  return post(url, body);
};
