import useGLBLoader from '../../../../useGlbLoader.js'; 
import { TwoDoorWranglerParts } from '../Constants/ModelConstants.js';

const useDoorContainer = (visibleItems) => {
  
    let simpleDoorFL = useGLBLoader(
        TwoDoorWranglerParts.Doors.SimpleDoorFL,
      visibleItems.includes("Simple Door")
    );
  
    let simpleDoorFR = useGLBLoader(
        TwoDoorWranglerParts.Doors.SimpleDoorFR,
      visibleItems.includes("Simple Door")
    );
  
  let simpleDoors = [
    simpleDoorFL,
    simpleDoorFR,
  ];

  const doorsArray = [
    { name: "Simple Door", models: simpleDoors },
  ];

  return {
    doorsArray,
  };
  };
  
  export default useDoorContainer;