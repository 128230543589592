import React, { useState, useEffect, useRef } from "react";
import { Button, Grid } from "@mui/material";
import ReactPlayer from "react-player";
import {
  PlayCircle,
  ArrowBack,
  ArrowForward,
  Facebook,
  LinkedIn,
} from "@mui/icons-material";
import logo from "../assets/svg/logo_black.svg";

import { Link } from "react-router-dom";
// import Banner from "../assets/svg/cover phone.svg";
import homeHero from "../assets/svg/homePageHero.svg";
import vision from "../assets/images/about-vision-vehicle.png";
import customizeJeep from "../assets/images/about-customize-jeep.png";
import team from "../assets/images/team.png";
import jeep2 from "../assets/images/demoProduct2.png";
import new_feature_img from "../assets/images/new_feature_img.png";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import rightArrow from "../assets/svg/rightArrow.svg";
import leftArrow from "../assets/svg/leftArrow.svg";

const About = () => {
  const [translate, setTranslate] = useState(0);
  const [translate2, setTranslate2] = useState(0);
  const [currentCard, setcurrentCard] = useState(1);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const btnpressprev = () => {
    if (translate !== 0) setTranslate(translate + 390);
    else setTranslate(-1170);
  };

  const btnpressnext = () => {
    if (translate !== -1170) setTranslate(translate - 390);
    else setTranslate(0);
  };

  const btnpressprev2 = () => {
    if (translate2 !== 0) {
      setTranslate2(translate2 + 340);
      setcurrentCard(currentCard - 1);
    } else {
      setTranslate2(-1020);
      setcurrentCard(1);
    }
  };

  const btnpressnext2 = () => {
    if (translate2 !== -1020) {
      setTranslate2(translate2 - 340);
      setcurrentCard(currentCard + 1);
    } else {
      setTranslate2(0);
      setcurrentCard(1);
    }
  };

  return (
    <div>
      <div className="shoppage-hero-container">
        <Grid container spacing={2}>
          <Grid
            item
            md={6}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
            }}
          >
            <div
              style={{
                width: "70%",
                color: "white",
              }}
            >
              <p
                style={{
                  fontSize: "44px",
                }}
                className="Akira-Expanded"
              >
                ABOUT
              </p>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                }}
              >
                <p
                  style={{
                    fontSize: "22px",
                  }}
                  className="Montserrat-Regular"
                >
                  HOME - ABOUT
                </p>
                <div className="divider-hero"></div>
              </div>
            </div>
          </Grid>
          <Grid item md={6} className="hero-section-image">
            <img src={homeHero} alt="" style={{ width: "100%" }} />
          </Grid>
        </Grid>
      </div>

      <div
        style={{
          width: "50%",
          height: 50,
          backgroundColor: "#272727",
          marginTop: -50,
          borderTopRightRadius: 50,
        }}
      ></div>

      <div className="about-vision-main-container">
        <Grid container spacing={2}>
          <Grid
            item
            md={5}
            sm={12}
            xs={12}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img src={vision} alt="" style={{ width: "100%", zIndex: 99 }} />
          </Grid>
          <Grid
            item
            md={7}
            sm={12}
            xs={12}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div className="about-vision-container">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <p className="contact-form-heading Akira-Expanded">
                  OUR VISION
                </p>
                <div className="about-driver1"></div>
              </div>
              <div className="about-vision-text">
                <p>
                  MY3DJEEP started with a passion for creativity and the Jeep
                  brand. Based out of Minneapolis, Minnesota, MY3DJEEP has grown
                  into a team of seven that spans every region of the US, Canada
                  and around the world! We are a team that loves the creative
                  process, the engineering required to get every custom MY3DJEEP
                  made just right and the commitment to providing the most
                  awesome experience for each MY3DJEEP owner. We love the unique
                  flavor every Jeep owner brings and are committed to
                  replicating that in every MY3DJEEP we develop. We look forward
                  to fueling your Jeep adventure by getting your custom rig
                  started! Click below to start the build process!
                </p>
              </div>
            </div>
          </Grid>
        </Grid>
        <div className="about-vision-background-container"></div>
      </div>

      {/* <div style={{ zIndex: 999 }}>
        <Grid container spacing={1} className="our-team">
          <Grid
            item
            sm={12}
            xs={12}
            md={9}
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              margin: "auto",
              padding: "0px 30px",
            }}
          >
            <p className="our-team-heading Akira-Expanded">MY 3D JEEP TEAM</p>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                marginTop: -20,
              }}
            >
              <button
                onClick={btnpressprev}
                className="featured-scroll-arrow-button"
              >
                <ArrowBack />
              </button>
              <button
                onClick={btnpressnext}
                className="featured-scroll-arrow-button"
              >
                <ArrowForward />
              </button>
            </div>
          </Grid>
          <Grid
            item
            sm={12}
            md={10}
            style={{
              display: "flex",
              // justifyContent: "center",
              alignItems: "center",
              overflow: "hidden",
              marginTop: -40,
              margin: "auto",
            }}
          >
            <div className="product-carousel">
              <div
                className="product-container"
                style={{
                  transition: "transform 0.5s ease",
                  transform: `translateX(${translate}px)`,
                }}
              >
                <div className="our-team-card-container">
                  <div className="our-team-card-image-container">
                    <img src={team} alt="" style={{ width: "100%" }} />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginTop: 10,
                    }}
                  >
                    <div style={{ textAlign: "left" }}>
                      <p
                        style={{ fontSize: 20, margin: 0 }}
                        className="Akira-Expanded"
                      >
                        Zach M.
                      </p>
                      <p style={{ fontSize: 14, fontWeight: 400, margin: 0 }}>
                        Director/CEO
                      </p>
                    </div>
                    <div style={{ display: "flex" }}>
                      <button
                        style={{
                          border: "none",
                          backgroundColor: "transparent",
                        }}
                      >
                        <LinkedIn style={{ color: "#FF3132" }} />
                      </button>
                      <button
                        style={{
                          border: "none",
                          backgroundColor: "transparent",
                        }}
                      >
                        <Facebook style={{ color: "#FF3132" }} />
                      </button>
                    </div>
                  </div>
                </div>
                <div className="our-team-card-container">
                  <div className="our-team-card-image-container">
                    <img src={team} alt="" style={{ width: "100%" }} />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginTop: 10,
                    }}
                  >
                    <div style={{ textAlign: "left" }}>
                      <p
                        style={{ fontSize: 20, margin: 0 }}
                        className="Akira-Expanded"
                      >
                        Lamaan S.
                      </p>
                      <p style={{ fontSize: 14, fontWeight: 400, margin: 0 }}>
                        Southeast Partner
                      </p>
                    </div>
                    <div style={{ display: "flex" }}>
                      <button
                        style={{
                          border: "none",
                          backgroundColor: "transparent",
                        }}
                      >
                        <LinkedIn style={{ color: "#FF3132" }} />
                      </button>
                      <button
                        style={{
                          border: "none",
                          backgroundColor: "transparent",
                        }}
                      >
                        <Facebook style={{ color: "#FF3132" }} />
                      </button>
                    </div>
                  </div>
                </div>
                <div className="our-team-card-container">
                  <div className="our-team-card-image-container">
                    <img src={team} alt="" style={{ width: "100%" }} />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginTop: 10,
                    }}
                  >
                    <div style={{ textAlign: "left" }}>
                      <p
                        style={{ fontSize: 20, margin: 0 }}
                        className="Akira-Expanded"
                      >
                        Matt P.
                      </p>
                      <p style={{ fontSize: 14, fontWeight: 400, margin: 0 }}>
                        Northeast Partner
                      </p>
                    </div>
                    <div style={{ display: "flex" }}>
                      <button
                        style={{
                          border: "none",
                          backgroundColor: "transparent",
                        }}
                      >
                        <LinkedIn style={{ color: "#FF3132" }} />
                      </button>
                      <button
                        style={{
                          border: "none",
                          backgroundColor: "transparent",
                        }}
                      >
                        <Facebook style={{ color: "#FF3132" }} />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </div> */}

      {/* <div
        style={{
          height: "500px",
          width: "100%",
        }}
      >
        <ReactPlayer
          className="react-player"
          url="videos/demo-video.MP4"
          width="100%"
          height="100%"
          playIcon={<PlayCircle />}
          controls={true}
        />
      </div> */}

      <div>
        <Grid container spacing={2} className="mid-banner-1">
          <Grid
            item
            md={5}
            sm={12}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                width: "70%",
                color: "black",
              }}
            >
              <p className="customize-section-sub-heading Montserrat-Regular">
                Elevate Your Drive, Customize in 3D
              </p>
              <p className="customize-section-heading Akira-Expanded">
                Customize Your Jeep Adventure!
              </p>
              <Link
                to="/build-your-own?q=0001"
                style={{ textDecoration: "none", color: "black" }}
              >
                <Button className="main-button">Customize Your Jeep</Button>
              </Link>
            </div>
          </Grid>
          <Grid
            item
            md={7}
            sm={12}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img src={new_feature_img} alt="" style={{ width: "100%" }} />
          </Grid>
        </Grid>
      </div>

      <div className="about-reviews-main-container">
        <div
          style={{
            textAlign: "center",
          }}
        >
          <p
            style={{ fontSize: "44px", lineHeight: "52px" }}
            className=" Akira-Expanded"
          >
            OUR REVIEWS
          </p>
          {/* <p style={{ width: "60%", textAlign: "center", margin: "auto" }}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas
            elementum felis at ligula ullamcorper. Vivamus vitae nisl libero.
            Pellentesque imperdiet ipsum ut sollicitudin efficitur. Nulla
            aliquet erat vel scelerisque efficitur. Etiam molestie
          </p> */}
        </div>
        <Grid container spacing={2} style={{ marginTop: 30 }}>
          <Grid
            item
            sm={12}
            xs={12}
            md={3}
            lg={3}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              margin: "auto",
              padding: "0px 30px",
            }}
          >
            <img
              src={logo}
              alt="logo"
              className="logo"
              style={{ marginLeft: 10 }}
            />
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                marginTop: 20,
                // marginLeft: -22,
              }}
            >
              <button
                onClick={btnpressprev2}
                style={{ border: "none", backgroundColor: "transparent" }}
              >
                <img src={leftArrow} alt="" style={{ width: "45px" }} />
              </button>
              <button
                onClick={btnpressnext2}
                style={{ border: "none", backgroundColor: "transparent" }}
              >
                <img src={rightArrow} alt="" style={{ width: "45px" }} />
              </button>
            </div>
          </Grid>
          <Grid
            item
            sm={12}
            xs={12}
            md={9}
            lg={9}
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              margin: "auto",
              padding: "0px 30px",
            }}
          >
            <div className="product-carousel">
              <div
                className="product-container"
                style={{
                  transition: "transform 0.5s ease",
                  transform: `translateX(${translate2}px)`,
                }}
              >
                <div
                  className={
                    currentCard === 1
                      ? "review-main-container-active"
                      : "review-main-container"
                  }
                >
                  <p
                    style={{
                      fontSize: 13,
                    }}
                  >
                    highly recommend to have your jeep done if you are a true
                    enthusiast, the attention to detail and communication and
                    process was awesome and exciting journey as i will do
                    another one when my jeep evolves and the adventure to come
                    in my future. When it arrived it was well received and
                    packaged with care!
                  </p>
                  <h4>Rollos SA</h4>
                </div>
                <div
                  className={
                    currentCard === 2
                      ? "review-main-container-active"
                      : "review-main-container"
                  }
                >
                  <p
                    style={{
                      fontSize: 13,
                    }}
                  >
                    Zach was awesome to work with! My Wrangler JKU looks
                    amazing, all the details was spot on. I highly recommend
                    my3DJeep to replicate your jeeps!
                  </p>
                  <h4>Nancy Reece</h4>
                </div>
                <div
                  className={
                    currentCard === 3
                      ? "review-main-container-active"
                      : "review-main-container"
                  }
                >
                  <p
                    style={{
                      fontSize: 13,
                    }}
                  >
                    my3DJeep did a great job on our CJ7!! I got this as a
                    Christmas gift and couldn't be happier. Not only is the jeep
                    exact, the customer service was amazing!! In the process of
                    restoration, can't wait to order our next my3DJeep. As you
                    can see from the picutes, he has enjoyed this gift
                    tremendously.
                  </p>
                  <h4>Sharon Lewis Fullmer</h4>
                </div>
                <div
                  className={
                    currentCard === 4
                      ? "review-main-container-active"
                      : "review-main-container"
                  }
                >
                  <p
                    style={{
                      fontSize: 13,
                    }}
                  >
                    I contacted Zach about making a model of my jeep. He did a
                    fantastic job recreating it considering I have a tone of
                    graphics on it. Thankyou again Zach for great work you did!
                  </p>
                  <h4>Jonathan M. McLaren</h4>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>

      <div className="mid-banner-2">
        <div>
          <p
            style={{
              fontSize: "18px",
            }}
            className="Montserrat-Regular"
          >
            DRIVE BEYOND BOUNDARIES
          </p>
        </div>
        <p
          style={{
            fontSize: "36px",
            lineHeight: "52px",
            textAlign: "center",
          }}
          className="Akira-Expanded"
        >
          CUSTOMIZE DESIGN
        </p>
        <Link
          onClick={(e) => {
            localStorage.removeItem("modelParts");
          }}
          to="/build-your-own?q=0001"
          style={{ textDecoration: "none", color: "black" }}
        >
          <Button className="main-button">Customize Your Jeep</Button>
        </Link>
      </div>
    </div>
  );
};

export default About;
