import useGLBLoader from "../../../../useGlbLoader.js";
import { FourDoorGradiatorParts } from "../Constants/ModelConstants.js";

const useWheelsContainer = (visibleItems) => {
  let aaronGordon5thWheel = useGLBLoader(
    FourDoorGradiatorParts.Wheels.AaronGordon.FifthWheel,
    visibleItems.includes("Aaron Gordon Rims")
  );

  let aaronGordonLBWheel = useGLBLoader(
    FourDoorGradiatorParts.Wheels.AaronGordon.LBWheel,
    visibleItems.includes("Aaron Gordon Rims")
  );

  let aaronGordonLFWheel = useGLBLoader(
    FourDoorGradiatorParts.Wheels.AaronGordon.LFWheel,
    visibleItems.includes("Aaron Gordon Rims")
  );

  let aaronGordonRBWheel = useGLBLoader(
    FourDoorGradiatorParts.Wheels.AaronGordon.RBWheel,
    visibleItems.includes("Aaron Gordon Rims")
  );

  let aaronGordonRFWheel = useGLBLoader(
    FourDoorGradiatorParts.Wheels.AaronGordon.RFWheel,
    visibleItems.includes("Aaron Gordon Rims")
  );

  let arkon5thWheel = useGLBLoader(
    FourDoorGradiatorParts.Wheels.Arkon.FifthWheel,
    visibleItems.includes("Arkon Rims")
  );

  let arkonLBWheel = useGLBLoader(
    FourDoorGradiatorParts.Wheels.Arkon.LBWheel,
    visibleItems.includes("Arkon Rims")
  );

  let arkonLFWheel = useGLBLoader(
    FourDoorGradiatorParts.Wheels.Arkon.LFWheel,
    visibleItems.includes("Arkon Rims")
  );

  let arkonRBWheel = useGLBLoader(
    FourDoorGradiatorParts.Wheels.Arkon.RBWheel,
    visibleItems.includes("Arkon Rims")
  );

  let arkonRFWheel = useGLBLoader(
    FourDoorGradiatorParts.Wheels.Arkon.RFWheel,
    visibleItems.includes("Arkon Rims")
  );

  let bethanySmeltzer5thWheel = useGLBLoader(
    FourDoorGradiatorParts.Wheels.BethanySmeltzer.FifthWheel,
    visibleItems.includes("Bethany Smeltzer Rims")
  );

  let bethanySmeltzerLBWheel = useGLBLoader(
    FourDoorGradiatorParts.Wheels.BethanySmeltzer.LBWheel,
    visibleItems.includes("Bethany Smeltzer Rims")
  );

  let bethanySmeltzerLFWheel = useGLBLoader(
    FourDoorGradiatorParts.Wheels.BethanySmeltzer.LFWheel,
    visibleItems.includes("Bethany Smeltzer Rims")
  );

  let bethanySmeltzerRBWheel = useGLBLoader(
    FourDoorGradiatorParts.Wheels.BethanySmeltzer.RBWheel,
    visibleItems.includes("Bethany Smeltzer Rims")
  );

  let bethanySmeltzerRFWheel = useGLBLoader(
    FourDoorGradiatorParts.Wheels.BethanySmeltzer.RFWheel,
    visibleItems.includes("Bethany Smeltzer Rims")
  );

  let billHoughton5thWheel = useGLBLoader(
    FourDoorGradiatorParts.Wheels.BillHoughton.FifthWheel,
    visibleItems.includes("Bill Houghton Rims")
  );

  let billHoughtonLBWheel = useGLBLoader(
    FourDoorGradiatorParts.Wheels.BillHoughton.LBWheel,
    visibleItems.includes("Bill Houghton Rims")
  );

  let billHoughtonLFWheel = useGLBLoader(
    FourDoorGradiatorParts.Wheels.BillHoughton.LFWheel,
    visibleItems.includes("Bill Houghton Rims")
  );

  let billHoughtonRBWheel = useGLBLoader(
    FourDoorGradiatorParts.Wheels.BillHoughton.RBWheel,
    visibleItems.includes("Bill Houghton Rims")
  );

  let billHoughtonRFWheel = useGLBLoader(
    FourDoorGradiatorParts.Wheels.BillHoughton.RFWheel,
    visibleItems.includes("Bill Houghton Rims")
  );

  let blackRhino5thWheel = useGLBLoader(
    FourDoorGradiatorParts.Wheels.BlackRhino.FifthWheel,
    visibleItems.includes("Black Rhino Rims")
  );

  let blackRhinoLBWheel = useGLBLoader(
    FourDoorGradiatorParts.Wheels.BlackRhino.LBWheel,
    visibleItems.includes("Black Rhino Rims")
  );

  let blackRhinoLFWheel = useGLBLoader(
    FourDoorGradiatorParts.Wheels.BlackRhino.LFWheel,
    visibleItems.includes("Black Rhino Rims")
  );

  let blackRhinoRBWheel = useGLBLoader(
    FourDoorGradiatorParts.Wheels.BlackRhino.RBWheel,
    visibleItems.includes("Black Rhino Rims")
  );

  let blackRhinoRFWheel = useGLBLoader(
    FourDoorGradiatorParts.Wheels.BlackRhino.RFWheel,
    visibleItems.includes("Black Rhino Rims")
  );
  let blitz5thWheel = useGLBLoader(
    FourDoorGradiatorParts.Wheels.Blitz.FifthWheel,
    visibleItems.includes("Blitz Rims")
  );

  let blitzLBWheel = useGLBLoader(
    FourDoorGradiatorParts.Wheels.Blitz.LBWheel,
    visibleItems.includes("Blitz Rims")
  );

  let blitzLFWheel = useGLBLoader(
    FourDoorGradiatorParts.Wheels.Blitz.LFWheel,
    visibleItems.includes("Blitz Rims")
  );

  let blitzRBWheel = useGLBLoader(
    FourDoorGradiatorParts.Wheels.Blitz.RBWheel,
    visibleItems.includes("Blitz Rims")
  );

  let blitzRFWheel = useGLBLoader(
    FourDoorGradiatorParts.Wheels.Blitz.RFWheel,
    visibleItems.includes("Blitz Rims")
  );

  let braydon5thWheel = useGLBLoader(
    FourDoorGradiatorParts.Wheels.Braydon.FifthWheel,
    visibleItems.includes("Braydon Rims")
  );

  let braydonLBWheel = useGLBLoader(
    FourDoorGradiatorParts.Wheels.Braydon.LBWheel,
    visibleItems.includes("Braydon Rims")
  );

  let braydonLFWheel = useGLBLoader(
    FourDoorGradiatorParts.Wheels.Braydon.LFWheel,
    visibleItems.includes("Braydon Rims")
  );

  let braydonRBWheel = useGLBLoader(
    FourDoorGradiatorParts.Wheels.Braydon.RBWheel,
    visibleItems.includes("Braydon Rims")
  );

  let braydonRFWheel = useGLBLoader(
    FourDoorGradiatorParts.Wheels.Braydon.RFWheel,
    visibleItems.includes("Braydon Rims")
  );

  let callieLemming5thWheel = useGLBLoader(
    FourDoorGradiatorParts.Wheels.CallieLemming.FifthWheel,
    visibleItems.includes("Callie Lemming Rims")
  );

  let callieLemmingLBWheel = useGLBLoader(
    FourDoorGradiatorParts.Wheels.CallieLemming.LBWheel,
    visibleItems.includes("Callie Lemming Rims")
  );

  let callieLemmingLFWheel = useGLBLoader(
    FourDoorGradiatorParts.Wheels.CallieLemming.LFWheel,
    visibleItems.includes("Callie Lemming Rims")
  );

  let callieLemmingRBWheel = useGLBLoader(
    FourDoorGradiatorParts.Wheels.CallieLemming.RBWheel,
    visibleItems.includes("Callie Lemming Rims")
  );

  let callieLemmingRFWheel = useGLBLoader(
    FourDoorGradiatorParts.Wheels.CallieLemming.RFWheel,
    visibleItems.includes("Callie Lemming Rims")
  );

  let claireGarette5thWheel = useGLBLoader(
    FourDoorGradiatorParts.Wheels.ClaireGarette.FifthWheel,
    visibleItems.includes("Claire Garette Rims")
  );

  let claireGaretteLBWheel = useGLBLoader(
    FourDoorGradiatorParts.Wheels.ClaireGarette.LBWheel,
    visibleItems.includes("Claire Garette Rims")
  );

  let claireGaretteLFWheel = useGLBLoader(
    FourDoorGradiatorParts.Wheels.ClaireGarette.LFWheel,
    visibleItems.includes("Claire Garette Rims")
  );

  let claireGaretteRBWheel = useGLBLoader(
    FourDoorGradiatorParts.Wheels.ClaireGarette.RBWheel,
    visibleItems.includes("Claire Garette Rims")
  );

  let claireGaretteRFWheel = useGLBLoader(
    FourDoorGradiatorParts.Wheels.ClaireGarette.RFWheel,
    visibleItems.includes("Claire Garette Rims")
  );

  let colterBahlau5thWheel = useGLBLoader(
    FourDoorGradiatorParts.Wheels.ColterBahlau.FifthWheel,
    visibleItems.includes("Colter Bahlau Rims")
  );

  let colterBahlauLBWheel = useGLBLoader(
    FourDoorGradiatorParts.Wheels.ColterBahlau.LBWheel,
    visibleItems.includes("Colter Bahlau Rims")
  );

  let colterBahlauLFWheel = useGLBLoader(
    FourDoorGradiatorParts.Wheels.ColterBahlau.LFWheel,
    visibleItems.includes("Colter Bahlau Rims")
  );

  let colterBahlauRBWheel = useGLBLoader(
    FourDoorGradiatorParts.Wheels.ColterBahlau.RBWheel,
    visibleItems.includes("Colter Bahlau Rims")
  );

  let colterBahlauRFWheel = useGLBLoader(
    FourDoorGradiatorParts.Wheels.ColterBahlau.RFWheel,
    visibleItems.includes("Colter Bahlau Rims")
  );

  let daniColeman5thWheel = useGLBLoader(
    FourDoorGradiatorParts.Wheels.DaniColeman.FifthWheel,
    visibleItems.includes("Dani Coleman Rims")
  );

  let daniColemanBLWheel = useGLBLoader(
    FourDoorGradiatorParts.Wheels.DaniColeman.BLWheel,
    visibleItems.includes("Dani Coleman Rims")
  );

  let daniColemanBRWheel = useGLBLoader(
    FourDoorGradiatorParts.Wheels.DaniColeman.BRWheel,
    visibleItems.includes("Dani Coleman Rims")
  );

  let daniColemanFLWheel = useGLBLoader(
    FourDoorGradiatorParts.Wheels.DaniColeman.FLWheel,
    visibleItems.includes("Dani Coleman Rims")
  );

  let daniColemanFRWheel = useGLBLoader(
    FourDoorGradiatorParts.Wheels.DaniColeman.FRWheel,
    visibleItems.includes("Dani Coleman Rims")
  );

  let davidShafer5thWheel = useGLBLoader(
    FourDoorGradiatorParts.Wheels.DavidShafer.FifthWheel,
    visibleItems.includes("David Shafer Rims")
  );

  let davidShaferBLWheel = useGLBLoader(
    FourDoorGradiatorParts.Wheels.DavidShafer.BLWheel,
    visibleItems.includes("David Shafer Rims")
  );

  let davidShaferBRWheel = useGLBLoader(
    FourDoorGradiatorParts.Wheels.DavidShafer.BRWheel,
    visibleItems.includes("David Shafer Rims")
  );

  let davidShaferFLWheel = useGLBLoader(
    FourDoorGradiatorParts.Wheels.DavidShafer.FLWheel,
    visibleItems.includes("David Shafer Rims")
  );

  let davidShaferFRWheel = useGLBLoader(
    FourDoorGradiatorParts.Wheels.DavidShafer.FRWheel,
    visibleItems.includes("David Shafer Rims")
  );

  let dustinHemauer5thWheel = useGLBLoader(
    FourDoorGradiatorParts.Wheels.DustinHemauer.FifthWheel,
    visibleItems.includes("Dustin Hemauer Rims")
  );

  let dustinHemauerFLWheel = useGLBLoader(
    FourDoorGradiatorParts.Wheels.DustinHemauer.FLWheel,
    visibleItems.includes("Dustin Hemauer Rims")
  );

  let dustinHemauerFRWheel = useGLBLoader(
    FourDoorGradiatorParts.Wheels.DustinHemauer.FRWheel,
    visibleItems.includes("Dustin Hemauer Rims")
  );

  let dustinHemauerBLWheel = useGLBLoader(
    FourDoorGradiatorParts.Wheels.DustinHemauer.BLWheel,
    visibleItems.includes("Dustin Hemauer Rims")
  );

  let dustinHemauerBRWheel = useGLBLoader(
    FourDoorGradiatorParts.Wheels.DustinHemauer.BRWheel,
    visibleItems.includes("Dustin Hemauer Rims")
  );

  let kevinKan5thWheel = useGLBLoader(
    FourDoorGradiatorParts.Wheels.KevinKan.FifthWheel,
    visibleItems.includes("Kevin Kan Rims")
  );

  let kevinKanBLWheel = useGLBLoader(
    FourDoorGradiatorParts.Wheels.KevinKan.BLWheel,
    visibleItems.includes("Kevin Kan Rims")
  );

  let kevinKanFLWheel = useGLBLoader(
    FourDoorGradiatorParts.Wheels.KevinKan.FLWheel,
    visibleItems.includes("Kevin Kan Rims")
  );

  let kevinKanBRWheel = useGLBLoader(
    FourDoorGradiatorParts.Wheels.KevinKan.BRWheel,
    visibleItems.includes("Kevin Kan Rims")
  );

  let kevinKanFRWheel = useGLBLoader(
    FourDoorGradiatorParts.Wheels.KevinKan.FRWheel,
    visibleItems.includes("Kevin Kan Rims")
  );

  let glenJones5thWheel = useGLBLoader(
    FourDoorGradiatorParts.Wheels.GlenJones.FifthWheel,
    visibleItems.includes("Glen Jones Rims")
  );

  let glenJonesLBWheel = useGLBLoader(
    FourDoorGradiatorParts.Wheels.GlenJones.BLWheel,
    visibleItems.includes("Glen Jones Rims")
  );

  let glenJonesLFWheel = useGLBLoader(
    FourDoorGradiatorParts.Wheels.GlenJones.FLWheel,
    visibleItems.includes("Glen Jones Rims")
  );

  let glenJonesRBWheel = useGLBLoader(
    FourDoorGradiatorParts.Wheels.GlenJones.BRWheel,
    visibleItems.includes("Glen Jones Rims")
  );

  let glenJonesRFWheel = useGLBLoader(
    FourDoorGradiatorParts.Wheels.GlenJones.FRWheel,
    visibleItems.includes("Glen Jones Rims")
  );

  let jaredRenfroe5thWheel = useGLBLoader(
    FourDoorGradiatorParts.Wheels.JaredRenfroe.FifthWheel,
    visibleItems.includes("Jared Renfroe Rims")
  );

  let jaredRenfroeLBWheel = useGLBLoader(
    FourDoorGradiatorParts.Wheels.JaredRenfroe.BLWheel,
    visibleItems.includes("Jared Renfroe Rims")
  );

  let jaredRenfroeLFWheel = useGLBLoader(
    FourDoorGradiatorParts.Wheels.JaredRenfroe.FLWheel,
    visibleItems.includes("Jared Renfroe Rims")
  );

  let jaredRenfroeRBWheel = useGLBLoader(
    FourDoorGradiatorParts.Wheels.JaredRenfroe.BRWheel,
    visibleItems.includes("Jared Renfroe Rims")
  );

  let jaredRenfroeRFWheel = useGLBLoader(
    FourDoorGradiatorParts.Wheels.JaredRenfroe.FRWheel,
    visibleItems.includes("Jared Renfroe Rims")
  );

  let jasonMartino5thWheel = useGLBLoader(
    FourDoorGradiatorParts.Wheels.JasonMartino.FifthWheel,
    visibleItems.includes("Jason Martino Rims")
  );

  let jasonMartinoBLWheel = useGLBLoader(
    FourDoorGradiatorParts.Wheels.JasonMartino.BLWheel,
    visibleItems.includes("Jason Martino Rims")
  );

  let jasonMartinoFLWheel = useGLBLoader(
    FourDoorGradiatorParts.Wheels.JasonMartino.FLWheel,
    visibleItems.includes("Jason Martino Rims")
  );

  let jasonMartinoBRWheel = useGLBLoader(
    FourDoorGradiatorParts.Wheels.JasonMartino.BRWheel,
    visibleItems.includes("Jason Martino Rims")
  );

  let jasonMartinoFRWheel = useGLBLoader(
    FourDoorGradiatorParts.Wheels.JasonMartino.FRWheel,
    visibleItems.includes("Jason Martino Rims")
  );

  let joyGarner5thWheel = useGLBLoader(
    FourDoorGradiatorParts.Wheels.JoyGarner.FifthWheel,
    visibleItems.includes("Joy Garner Rims")
  );

  let joyGarnerBLWheel = useGLBLoader(
    FourDoorGradiatorParts.Wheels.JoyGarner.BLWheel,
    visibleItems.includes("Joy Garner Rims")
  );

  let joyGarnerFLWheel = useGLBLoader(
    FourDoorGradiatorParts.Wheels.JoyGarner.FLWheel,
    visibleItems.includes("Joy Garner Rims")
  );

  let joyGarnerBRWheel = useGLBLoader(
    FourDoorGradiatorParts.Wheels.JoyGarner.BRWheel,
    visibleItems.includes("Joy Garner Rims")
  );

  let joyGarnerFRWheel = useGLBLoader(
    FourDoorGradiatorParts.Wheels.JoyGarner.FRWheel,
    visibleItems.includes("Joy Garner Rims")
  );

  let markJHanscom5thWheel = useGLBLoader(
    FourDoorGradiatorParts.Wheels.MarkJHanscom.FifthWheel,
    visibleItems.includes("Mark J Hanscom Rims")
  );

  let markJHanscomBLWheel = useGLBLoader(
    FourDoorGradiatorParts.Wheels.MarkJHanscom.BLWheel,
    visibleItems.includes("Mark J Hanscom Rims")
  );

  let markJHanscomFLWheel = useGLBLoader(
    FourDoorGradiatorParts.Wheels.MarkJHanscom.FLWheel,
    visibleItems.includes("Mark J Hanscom Rims")
  );

  let markJHanscomBRWheel = useGLBLoader(
    FourDoorGradiatorParts.Wheels.MarkJHanscom.BRWheel,
    visibleItems.includes("Mark J Hanscom Rims")
  );

  let markJHanscomFRWheel = useGLBLoader(
    FourDoorGradiatorParts.Wheels.MarkJHanscom.FRWheel,
    visibleItems.includes("Mark J Hanscom Rims")
  );

  let darleneMoscone5thWheel = useGLBLoader(
    FourDoorGradiatorParts.Wheels.DarleneMoscone.FifthWheel,
    visibleItems.includes("Darlene Moscone Rims")
  );

  let darleneMosconeBLWheel = useGLBLoader(
    FourDoorGradiatorParts.Wheels.DarleneMoscone.BLWheel,
    visibleItems.includes("Darlene Moscone Rims")
  );

  let darleneMosconeBRWheel = useGLBLoader(
    FourDoorGradiatorParts.Wheels.DarleneMoscone.BRWheel,
    visibleItems.includes("Darlene Moscone Rims")
  );

  let darleneMosconeFLWheel = useGLBLoader(
    FourDoorGradiatorParts.Wheels.DarleneMoscone.FLWheel,
    visibleItems.includes("Darlene Moscone Rims")
  );

  let darleneMosconeFRWheel = useGLBLoader(
    FourDoorGradiatorParts.Wheels.DarleneMoscone.FRWheel,
    visibleItems.includes("Darlene Moscone Rims")
  );

  let jonathenMclaren5thWheel = useGLBLoader(
    FourDoorGradiatorParts.Wheels.JonathenMclaren.FifthWheel,
    visibleItems.includes("Jonathen Mclaren Rims")
  );

  let jonathenMclarenLBWheel = useGLBLoader(
    FourDoorGradiatorParts.Wheels.JonathenMclaren.BLWheel,
    visibleItems.includes("Jonathen Mclaren Rims")
  );

  let jonathenMclarenLFWheel = useGLBLoader(
    FourDoorGradiatorParts.Wheels.JonathenMclaren.FLWheel,
    visibleItems.includes("Jonathen Mclaren Rims")
  );

  let jonathenMclarenRBWheel = useGLBLoader(
    FourDoorGradiatorParts.Wheels.JonathenMclaren.BRWheel,
    visibleItems.includes("Jonathen Mclaren Rims")
  );

  let jonathenMclarenRFWheel = useGLBLoader(
    FourDoorGradiatorParts.Wheels.JonathenMclaren.FRWheel,
    visibleItems.includes("Jonathen Mclaren Rims")
  );

  //   // Existing wheels

  let davidShaferRims = 
    [
      davidShafer5thWheel,
      davidShaferBLWheel,
      davidShaferBRWheel,
      davidShaferFLWheel,
      davidShaferFRWheel,
    ]

  
  let aaronGordonWheels = 
    [
      aaronGordon5thWheel,
      aaronGordonLBWheel,
      aaronGordonLFWheel,
      aaronGordonRBWheel,
      aaronGordonRFWheel,
    ]

  
  let dustinHemauerWheels = 
    [
      dustinHemauer5thWheel,
      dustinHemauerBRWheel,
      dustinHemauerBLWheel,
      dustinHemauerFRWheel,
      dustinHemauerFLWheel,
    ]

  
  let joyGarnerWheel = 
    [
      joyGarner5thWheel,
      joyGarnerFRWheel,
      joyGarnerBRWheel,
      joyGarnerFLWheel,
      joyGarnerBLWheel,
    ]

  
  let jaredRenfroeWheel = 
    [
      jaredRenfroe5thWheel,
      jaredRenfroeRFWheel,
      jaredRenfroeRBWheel,
      jaredRenfroeLFWheel,
      jaredRenfroeLBWheel,
    ]

  
  let arkonWheels = 
    [
      arkon5thWheel,
      arkonLBWheel,
      arkonLFWheel,
      arkonRBWheel,
      arkonRFWheel,
    ]

  
  let blitzWheels = 
    [
      blitz5thWheel,
      blitzRFWheel,
      blitzRBWheel,
      blitzLFWheel,
      blitzLBWheel,
    ]

  
  let kevinKanWheel = 
    [
      kevinKan5thWheel,
      kevinKanFRWheel,
      kevinKanBRWheel,
      kevinKanFLWheel,
      kevinKanBLWheel,
    ]

  
  let braydonWheels = 
    [
      braydon5thWheel,
      braydonLBWheel,
      braydonLFWheel,
      braydonRBWheel,
      braydonRFWheel,
    ]

  
  let claireGaretteWheels = 
    [
      claireGarette5thWheel,
      claireGaretteLBWheel,
      claireGaretteLFWheel,
      claireGaretteRBWheel,
      claireGaretteRFWheel,
    ]

  
  let jasonMartinoWheel = 
    [
      jasonMartino5thWheel,
      jasonMartinoFRWheel,
      jasonMartinoBRWheel,
      jasonMartinoFLWheel,
      jasonMartinoBLWheel,
    ]

  
  let markJHanscomWheel = 
    [
      markJHanscom5thWheel,
      markJHanscomFRWheel,
      markJHanscomBRWheel,
      markJHanscomFLWheel,
      markJHanscomBLWheel,
    ]

  
  let daniColemenRims = 
    [
      daniColeman5thWheel,
      daniColemanBLWheel,
      daniColemanBRWheel,
      daniColemanFLWheel,
      daniColemanFRWheel,
    ]

  
  let darleneMosconeWheel = 
    [
      darleneMoscone5thWheel,
      darleneMosconeFRWheel,
      darleneMosconeFLWheel,
      darleneMosconeBRWheel,
      darleneMosconeBLWheel,
    ]

  
  let jonathenMclarenWheels = 
    [
      jonathenMclaren5thWheel,
      jonathenMclarenLBWheel,
      jonathenMclarenLFWheel,
      jonathenMclarenRBWheel,
      jonathenMclarenRFWheel,
    ]

  
  let bethanySmeltzerWheels = 
    [
      bethanySmeltzer5thWheel,
      bethanySmeltzerLBWheel,
      bethanySmeltzerLFWheel,
      bethanySmeltzerRBWheel,
      bethanySmeltzerRFWheel,
    ]

  
  let billHoughtonWheels = 
    [
      billHoughton5thWheel,
      billHoughtonLBWheel,
      billHoughtonLFWheel,
      billHoughtonRBWheel,
      billHoughtonRFWheel,
    ]

  
  let blackRhinoWheels = 
    [
      blackRhino5thWheel,
      blackRhinoLBWheel,
      blackRhinoLFWheel,
      blackRhinoRBWheel,
      blackRhinoRFWheel,
    ]

  
  let callieLemmingWheels = 
    [
      callieLemming5thWheel,
      callieLemmingLBWheel,
      callieLemmingLFWheel,
      callieLemmingRBWheel,
      callieLemmingRFWheel,
    ]

  
  let colterBahlauWheels = 
    [
      colterBahlau5thWheel,
      colterBahlauLBWheel,
      colterBahlauLFWheel,
      colterBahlauRBWheel,
      colterBahlauRFWheel,
    ]

  
  let glenJonesWheels = [
      glenJones5thWheel,
      glenJonesLBWheel,
      glenJonesLFWheel,
      glenJonesRBWheel,
      glenJonesRFWheel,
    ]

  // Updated rimsArray with all wheels
  const rimsArray = [
    { name: "Aaron Gordon Rims", models: aaronGordonWheels },
    { name: "Arkon Rims", models: arkonWheels },
    { name: "Braydon Rims", models: braydonWheels },
    { name: "Claire Garette Rims", models: claireGaretteWheels },
    { name: "Dani Coleman Rims", models: daniColemenRims },
    { name: "David Shafer Rims", models: davidShaferRims },
    { name: "Dustin Hemauer Rims", models: dustinHemauerWheels },
    { name: "Jared Renfroe Rims", models: jaredRenfroeWheel },
    { name: "Jason Martino Rims", models: jasonMartinoWheel },
    { name: "Joy Garner Rims", models: joyGarnerWheel },
    { name: "Kevin Kan Rims", models: kevinKanWheel },
    { name: "Mark J Hanscom Rims", models: markJHanscomWheel },
    { name: "Blitz Rims", models: blitzWheels },
    { name: "Darlene Moscone Rims", models: darleneMosconeWheel },
    { name: "Jonathen Mclaren Rims", models: jonathenMclarenWheels },
    { name: "Bethany Smeltzer Rims", models: bethanySmeltzerWheels },
    { name: "Bill Houghton Rims", models: billHoughtonWheels },
    { name: "Black Rhino Rims", models: blackRhinoWheels },
    { name: "Callie Lemming Rims", models: callieLemmingWheels },
    { name: "Colter Bahlau Rims", models: colterBahlauWheels },
    { name: "Glen Jones Rims", models: glenJonesWheels },
  ];

  return {
    rimsArray,
  };
};

export default useWheelsContainer;
