import { Button } from "@mui/material";
import "react-toastify/dist/ReactToastify.css";
import { useLocation, useNavigate } from "react-router-dom";
import React, { Suspense, useEffect, useMemo, useState } from "react";

import "../assets/css/image-crousel.css";
import PopupModal from "../Components/popupModal";
import ModelLoader from "../Components/ModelLoader";

import gladiator from "../assets/images/gladiator.png";
import featureImage1 from "../assets/images/featureImage1.png";
import featureImage2 from "../assets/images/featureImage2.png";

import leftArrow from "../assets/svg/leftArrow.svg";
import rightArrow from "../assets/svg/rightArrow.svg";

import SimpleHood from "../assets/images/SimpleHood.png";
import SimpleDoorLB from "../assets/images/SimpleDoorLB.png";
import AaronGordonLBRims from "../assets/images/modelParts/Wheels/AaronGordonLBWheel.png";

import ModelRenderer from "../Components/3DModel";
import BuyBuildHero from "../Components/BuildYourOwn/Top";
import PriceDetail from "../Components/BuildYourOwn/PriceDetails";
import ModelPartsButtons from "../Components/BuildYourOwn/ModelParts";
import ProductDetail from "../Components/BuildYourOwn/ProductDetails";
import RelatedProducts from "../Components/BuildYourOwn/RelatedProducts";
import {  wranglerParts } from "../Components/Vehicle/Models/4DWRANGLER/ModelImage";
import { gradiaterParts } from "../Components/Vehicle/Models/4DGLADIATOR/Constants/partDetailsConstants";
import { TwoDoorParts } from "../Components/Vehicle/Models/2DWRANGLER/Constants/partDetailsConstant";
// import gradiaterParts  from "../Components/Vehicle/Models/4DGLADIATOR/Constants/partDetailsConstants";

const BuildYourOwn = () => {
  const location = useLocation();
  const navigation = useNavigate();
  const params = new URLSearchParams(location.search).get("q");

  const [productDetails, setProductDetails] = useState(null);
  const [TotalPartsPrice, setTotalPartsPrice] = useState(10);

  const [translate, setTranslate] = useState(1200);
  const [chassisColor, setChassisColor] = useState("#f00");

  const [selectedType, setSelectedType] = useState("ALL");
  const [partsCount, setPartsCount] = useState(13);

  const vehicleParts = useMemo(() => {
    switch (params) {
      case "0001":
        return wranglerParts;
      case "0002":
        return gradiaterParts;
      case "0003":
        return TwoDoorParts;
      default:
        return [];
    }
  }, [params]);

  const [visibleItems, setVisibleItems] = useState([
    "Aaron Gordon Rims",
    "Simple Door",
    "Simple Hood",
  ]);
  const [visibleItemsArray, setVisibleItemsArray] = useState([
    {
      name: "Aaron Gordon Rims",
      price: 10,
      image: AaronGordonLBRims,
    },
    {
      name: "Simple Door",
      price: 0,
      image: SimpleDoorLB,
    },
    {
      name: "Simple Hood",
      price: 0,
      image: SimpleHood,
    },
  ]);

  const [open, setOpen] = useState(false);
  const [modalText, setmodalText] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
    initialData();
  }, [params]);

  const initialData = async () => {
    try {
      if (params !== undefined && params !== null) {
        if (params === "0001") {
          setProductDetails({
            _id: "0001",
            name: "4D Wrangler",
            description:
              "Lorem ipsum dolor sit amet, consectetur elit. Sed malesuada magna at leo scelerisque molestie.",
            titleImageURL: featureImage1,
            price: 120,
            is3D: true,
          });
        } else if (params === "0002") {
          setProductDetails({
            _id: "0002",
            name: "4D Gladiator",
            description:
              "Lorem ipsum dolor sit amet, consectetur elit. Sed malesuada magna at leo scelerisque molestie.",
            titleImageURL: gladiator,
            price: 140,
            is3D: true,
          });
        } else if (params === "0003") {
          setProductDetails({
            _id: "0003",
            name: "2D Wrangler",
            description:
              "Lorem ipsum dolor sit amet, consectetur elit. Sed malesuada magna at leo scelerisque molestie.",
            titleImageURL: featureImage2,
            price: 100,
            is3D: false,
          });
        }
      }
      const partsArray = await localStorage.getItem(`modelParts${params}`);
      if (partsArray) {
        const arr = await JSON.parse(partsArray);
        const visibleItemArrayTemp = [];
        let price = 0;
        arr.map((part) => {
          vehicleParts.map((item) => {
            if (item.name === part) {
              visibleItemArrayTemp.push(item);
              price = price + item.price;
            }
          });
        });

        setTotalPartsPrice(price);
        setVisibleItemsArray(visibleItemArrayTemp);
        setVisibleItems(arr);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleAddToCart = async (product) => {
    let currentCart = [];
    const cart = localStorage.getItem("Cart");
    if (cart) {
      currentCart = JSON.parse(cart);
    }

    const index = currentCart.findIndex((item) => {
      return item.id === product._id;
    });
    if (index !== -1) {
      const filtered = currentCart.filter((item) => {
        return item.id === product._id;
      });
      currentCart.splice(index, 1);
      currentCart.push({
        id: filtered[0].id,
        productName: filtered[0].productName,
        productDescription: filtered[0].productDescription,
        productImage: filtered[0].productImage,
        productPrice: filtered[0].productPrice + TotalPartsPrice,
        Quantity: filtered[0].Quantity + 1,
        vehicleParts: filtered[0].vehicleParts,
      });
    } else {
      currentCart.push({
        id: product._id,
        productName: product.name,
        productDescription: product.description,
        productImage: product.titleImageURL,
        productPrice: product.price + TotalPartsPrice,
        Quantity: 1,
        vehicleParts: product.vehicleParts,
      });
    }

    localStorage.removeItem(`modelParts${params}`);
    localStorage.removeItem("Cart");
    localStorage.setItem("Cart", JSON.stringify(currentCart));
    navigation("/cart");
  };

  const handleAddToWishlist = async (product) => {
    let currentWishList = [];
    const wishList = localStorage.getItem("Wishlist");
    if (wishList) {
      currentWishList = JSON.parse(wishList);
    }

    const index = currentWishList.findIndex((item) => {
      return item.id === product._id;
    });
    if (index === -1) {
      currentWishList.push({
        id: product._id,
        productName: product.name,
        productDescription: product.description,
        productImage: product.titleImageURL,
        productPrice: product.price + TotalPartsPrice,
        Quantity: 1,
        vehicleParts: product.vehicleParts,
      });
    }

    localStorage.removeItem("modelParts");
    localStorage.removeItem("Wishlist");
    localStorage.setItem("Wishlist", JSON.stringify(currentWishList));
    navigation("/wishlist");
  };

  const btnpressprev = () => {
    if (
      // (params === "0001" && translate !== partsCount * 100 - 100) ||
      // (params === "0002" && translate !== partsCount * 100 - 100)
      translate !==
      partsCount * 100 - 100
    )
      setTranslate(translate + 200);
    else
      setTranslate(
        // params === "0001"
        //   ? -(partsCount * 100) - 100
        //   : -(partsCount * 100) - 100
        -(partsCount * 100) - 100
      );
  };

  const btnpressnext = () => {
    if (
      // (params === "0001" && translate !== -(partsCount * 100) - 100) ||
      // (params === "0002" && translate !== -(partsCount * 100) - 100)
      translate !==
      -(partsCount * 100) - 100
    )
      setTranslate(translate - 200);
    else
      setTranslate(
        // params === "0001" ? partsCount * 100 - 100 : partsCount * 100 - 100
        partsCount * 100 - 100
      );
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleAddParts = (partID) => {
    const array = visibleItems.filter((item) => item === partID);
    if (array.length > 0) {
      const newArray = visibleItems.filter((item) => item !== partID);
      localStorage.removeItem(`modelParts${params}`);
      if (newArray.length > 0) {
        localStorage.setItem(`modelParts${params}`, JSON.stringify(newArray));
      }
      setVisibleItems(newArray);

      const visibleItemArrayTemp = [];
      let price = 0;
      newArray.map((part) => {
        vehicleParts.map((item) => {
          if (item.name === part) {
            visibleItemArrayTemp.push(item);
            price = price + item.price;
          }
        });
      });
      setTotalPartsPrice(price);
      setVisibleItemsArray(visibleItemArrayTemp);
      setmodalText("Item removed successfully!");
      setOpen(true);
    } else {
      let newArray = visibleItems;

      //CHEPPYYY
      if (partID.includes("Bumper")) {
        newArray = newArray.filter((item) => !item.includes("Bumper"));
      }
      if (partID.includes("Grill")) {
        newArray = newArray.filter((item) => !item.includes("Grill"));
      }
      if (partID.includes("Hood")) {
        newArray = newArray.filter((item) => !item.includes("Hood"));
      }
      if (partID.includes("Door")) {
        newArray = newArray.filter((item) => !item.includes("Door"));
      }
      if (partID.includes("Rim")) {
        newArray = newArray.filter((item) => !item.includes("Rim"));
      }
      if (partID.includes("Side Step")) {
        newArray = newArray.filter((item) => !item.includes("Side Step"));
      }

      if (partID.includes("Fender")) {
        newArray = newArray.filter((item) => !item.includes("Fender"));
      }

      newArray.push(partID);
      localStorage.removeItem(`modelParts${params}`);
      if (newArray.length > 0) {
        localStorage.setItem(`modelParts${params}`, JSON.stringify(newArray));
      }
      setVisibleItems(newArray);

      const visibleItemArrayTemp = [];
      let price = 0;
      newArray.map((part) => {
        vehicleParts.map((item) => {
          if (item.name === part) {
            visibleItemArrayTemp.push(item);
            price = price + item.price;
          }
        });
      });
      setTotalPartsPrice(price);
      setVisibleItemsArray(visibleItemArrayTemp);
      setmodalText("Item added successfully!");
      setOpen(true);
    }
  };
  return (
    <div>
      <div className="product-detail-hero-container">
        <BuyBuildHero
          productDetails={productDetails}
          handleAddToWishlist={handleAddToWishlist}
          setChassisColor={setChassisColor}
        />

        <div className="model-contianer">
          <Suspense
            fallback={<ModelLoader src={productDetails?.titleImageURL} />}
          >
            <ModelRenderer
              chassisColor={chassisColor}
              visibleItems={visibleItems}
            />
          </Suspense>
        </div>

        <ModelPartsButtons
          selectedType={selectedType}
          setSelectedType={setSelectedType}
          setTranslate={setTranslate}
          setPartsCount={setPartsCount}
          params={params}
        />

        <div className="product-parts-container">
          <div
            style={{
              width: "10%",
              // display: "none",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <button
              onClick={btnpressprev}
              style={{ border: "none", backgroundColor: "transparent" }}
            >
              <img src={leftArrow} alt="" style={{ width: "35px" }} />
            </button>
          </div>
          <div className="product-carousel">
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                scrollBehavior: "smooth",
                transition: "transform 0.5s ease",
                transform: `translateX(${translate}px)`,
              }}
            >
              {(selectedType === "ALL" || selectedType === "BUMPER") &&
                vehicleParts.map((item) => {
                  if (item.name.includes("Bumper"))
                    return (
                      <div className="build-your-own-card">
                        <div className="build-your-own-image">
                          <img src={item.image} width={200} />
                        </div>
                        <p className="build-your-own-card-price">
                          {"$" + item.price}
                        </p>
                        <Button
                          className="main-button"
                          onClick={(e) => handleAddParts(item.name)}
                        >
                          {visibleItems.includes(item.name)
                            ? "-Remove"
                            : "+ Add"}
                        </Button>
                      </div>
                    );
                })}
              {(selectedType === "ALL" || selectedType === "GRILL") &&
                vehicleParts.map((item) => {
                  if (item.name.includes("Grill"))
                    return (
                      <div className="build-your-own-card">
                        <div className="build-your-own-image">
                          <img src={item.image} width={200} />
                        </div>
                        <p className="build-your-own-card-price">
                          {"$" + item.price}
                        </p>
                        <Button
                          className="main-button"
                          onClick={(e) => handleAddParts(item.name)}
                        >
                          {visibleItems.includes(item.name)
                            ? "-Remove"
                            : "+ Add"}
                        </Button>
                      </div>
                    );
                })}
              {(selectedType === "ALL" || selectedType === "SIDE STEPS") &&
                vehicleParts.map((item) => {
                  if (item.name.includes("Side Step"))
                    return (
                      <div className="build-your-own-card">
                        <div className="build-your-own-image">
                          <img src={item.image} width={200} />
                        </div>
                        <p className="build-your-own-card-price">
                          {"$" + item.price}
                        </p>
                        <Button
                          className="main-button"
                          onClick={(e) => handleAddParts(item.name)}
                        >
                          {visibleItems.includes(item.name)
                            ? "-Remove"
                            : "+ Add"}
                        </Button>
                      </div>
                    );
                })}
              {(selectedType === "ALL" || selectedType === "RIMS") &&
                vehicleParts.map((item) => {
                  if (item.name.includes("Rims"))
                    return (
                      <div className="build-your-own-card">
                        <div className="build-your-own-image">
                          <img src={item.image} width={200} />
                        </div>
                        <p className="build-your-own-card-price">
                          {"$" + item.price}
                        </p>
                        <Button
                          className="main-button"
                          onClick={(e) => handleAddParts(item.name)}
                        >
                          {visibleItems.includes(item.name)
                            ? "-Remove"
                            : "+ Add"}
                        </Button>
                      </div>
                    );
                })}
              {(selectedType === "ALL" || selectedType === "DOOR") &&
                vehicleParts.map((item) => {
                  if (item.name.includes("Door"))
                    return (
                      <div className="build-your-own-card">
                        <div className="build-your-own-image">
                          <img src={item.image} width={200} />
                        </div>
                        <p className="build-your-own-card-price">
                          {"$" + item.price}
                        </p>
                        <Button
                          className="main-button"
                          onClick={(e) => handleAddParts(item.name)}
                        >
                          {visibleItems.includes(item.name)
                            ? "-Remove"
                            : "+ Add"}
                        </Button>
                      </div>
                    );
                })}
              {(selectedType === "ALL" || selectedType === "HOOD") &&
                vehicleParts.map((item) => {
                  if (item.name.includes("Hood"))
                    return (
                      <div className="build-your-own-card">
                        <div className="build-your-own-image">
                          <img src={item.image} width={200} />
                        </div>
                        <p className="build-your-own-card-price">
                          {"$" + item.price}
                        </p>
                        <Button
                          className="main-button"
                          onClick={(e) => handleAddParts(item.name)}
                        >
                          {visibleItems.includes(item.name)
                            ? "-Remove"
                            : "+ Add"}
                        </Button>
                      </div>
                    );
                })}

              {selectedType === "OTHERS" &&
                vehicleParts.map((item) => {
                  if (item.name.includes("Other"))
                    return (
                      <div className="build-your-own-card">
                        <div className="build-your-own-image">
                          <img src={item.image} width={200} />
                        </div>
                        <p className="build-your-own-card-price">
                          {"$" + item.price}
                        </p>
                        <Button
                          className="main-button"
                          onClick={(e) => handleAddParts(item.name)}
                        >
                          {visibleItems.includes(item.name)
                            ? "-Remove"
                            : "+ Add"}
                        </Button>
                      </div>
                    );
                })}
            </div>
          </div>
          <div
            style={{
              width: "10%",
              // display: "none",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <button
              onClick={btnpressnext}
              style={{ border: "none", backgroundColor: "transparent" }}
            >
              <img src={rightArrow} alt="" style={{ width: "35px" }} />
            </button>
          </div>
        </div>

        <PriceDetail
          visibleItemsArray={visibleItemsArray}
          handleAddParts={handleAddParts}
        />
        <ProductDetail
          productDetails={productDetails}
          TotalPartsPrice={TotalPartsPrice}
          visibleItems={visibleItems}
          handleAddToCart={handleAddToCart}
        />
      </div>

      {/* SHOP NOW */}
      <RelatedProducts
        params={params}
        handleAddToCart={handleAddToCart}
        handleAddToWishlist={handleAddToWishlist}
      />

      <PopupModal open={open} modalText={modalText} handleClose={handleClose} />
      {/* <ToastContainer /> */}
    </div>
  );
};

export default BuildYourOwn;
