const baseURL = "/Models/newTwoDoor";

export const TwoDoorWranglerParts = {
  Grills: {
    ClaireGaretteGrill: `${baseURL}/Grill/Compressed Grill/ClaireGaretteGrill.glb`,
    FlatGrill: `${baseURL}/Grill/Compressed Grill/FlatGrill.glb`,
    GlenJonesGrill: `${baseURL}/Grill/Compressed Grill/GlenJonesGrill.glb`,
    MarkJHanscomGrill: `${baseURL}/Grill/Compressed Grill/MarkJHanscomGrill.glb`,
    MichaelKnappGrill: `${baseURL}/Grill/Compressed Grill/MichaelKnappGrill.glb`,
    RhiMcclendonGrill: `${baseURL}/Grill/Compressed Grill/RhiMcclendonGrill.glb`,
    RickyBeilchnerGrill: `${baseURL}/Grill/Compressed Grill/RickyBeilchnerGrill.glb`,
    ShawnOsburnGrill: `${baseURL}/Grill/Compressed Grill/ShawnOsburnGrill.glb`,
  },
  Bumpers: {
    CoreyJonesBumper: `${baseURL}/Bumper/Compressed Bumper/CoreyJonesBumper.glb`,
    CoveredBumper: `${baseURL}/Bumper/Compressed Bumper/CoveredBumper.glb`,
    DarleneMosconeBumper: `${baseURL}/Bumper/Compressed Bumper/DarleneMosconeBumper.glb`,
    DustinHemeurRig2Bumper: `${baseURL}/Bumper/Compressed Bumper/DustinHemeurRig2Bumper.glb`,
    DustinHemeurRig3Bumper: `${baseURL}/Bumper/Compressed Bumper/DustinHemeurRig3Bumper.glb`,
    ElizabethVanesBumper: `${baseURL}/Bumper/Compressed Bumper/ElizabethVanesBumper.glb`,
    GlenJonesBumper: `${baseURL}/Bumper/Compressed Bumper/GlenJonesBumper.glb`,
    JasonCunninghamBumper: `${baseURL}/Bumper/Compressed Bumper/JasonCunninghamBumper.glb`,
    JasonMartinoBumper: `${baseURL}/Bumper/Compressed Bumper/JasonMartinoBumper.glb`,
    JessicaVenableBumper: `${baseURL}/Bumper/Compressed Bumper/JessicaVenableBumper.glb`,
    JonathanMclarenBumper: `${baseURL}/Bumper/Compressed Bumper/JonathanMclarenBumper.glb`,
    JoyGarnerBumper: `${baseURL}/Bumper/Compressed Bumper/JoyGarnerBumper.glb`,
    KevinKanBumper: `${baseURL}/Bumper/Compressed Bumper/KevinKanBumper.glb`,
    KevinOotenBumper: `${baseURL}/Bumper/Compressed Bumper/KevinOotenBumper.glb`,
    LeslieMedranoBumper: `${baseURL}/Bumper/Compressed Bumper/LeslieMedranoBumper.glb`,
    RIckValentinBumper: `${baseURL}/Bumper/Compressed Bumper/RIckValentinBumper.glb`,
    RobertHutchingsBumper: `${baseURL}/Bumper/Compressed Bumper/RobertHutchingsBumper.glb`,
    SharonFullmerBumper: `${baseURL}/Bumper/Compressed Bumper/SharonFullmerBumper.glb`,
    TammyMcCormickBumper: `${baseURL}/Bumper/Compressed Bumper/TammyMcCormickBumper.glb`,
    TimTraynorBumper: `${baseURL}/Bumper/Compressed Bumper/TimTraynorBumper.glb`,
  },

  Hoods: {
    DarleneMosconeHood: `${baseURL}/Hood/Compressed Hood/DarleneMoscocneHood.glb`,
    JessicaVenableHood: `${baseURL}/Hood/Compressed Hood/JessicaVenableHood.glb`,
    RobertHutchingsHood: `${baseURL}/Hood/Compressed Hood/RobertHutchingsHood.glb`,
    SimpleHood: `${baseURL}/Hood/Compressed Hood/SimpleHood.glb`,
    YaseanBruceHood: `${baseURL}/Hood/Compressed Hood/YaseanBruceHood.glb`,
    rubiconHood: `${baseURL}/Hood/Compressed Hood/rebiconhood new.glb`,
  },

  SideSteps: {
    ChrisWilsonLeftSideStep: `${baseURL}/Side Step/Compressed Side Steps/ChrisWilsonLeftSideStep.glb`,
    ChrisWilsonRightSideStep: `${baseURL}/Side Step/Compressed Side Steps/ChrisWilsonRightSideStep.glb`,
    YaseanBruceLeftSideStep: `${baseURL}/Side Step/Compressed Side Steps/YaseanBruceLeftSideStep.glb`,
    YaseanBruceRightSideStep: `${baseURL}/Side Step/Compressed Side Steps/YaseanBruceRightSideStep.glb`,
  },

  Wheels: {
    AaronGordon5thWheel: `${baseURL}/Wheels/Compressed Wheels/AaronGordon5thWheel.glb`,
    AaronGordonLBWheel: `${baseURL}/Wheels/Compressed Wheels/AaronGordonLBWheel.glb`,
    AaronGordonLFWheel: `${baseURL}/Wheels/Compressed Wheels/AaronGordonLFWheel.glb`,
    AaronGordonRBWheel: `${baseURL}/Wheels/Compressed Wheels/AaronGordonRBWheel.glb`,
    AaronGordonRFWheel: `${baseURL}/Wheels/Compressed Wheels/AaronGordonRFWheel.glb`,
    Arkon5thWheel: `${baseURL}/Wheels/Compressed Wheels/Arkon5thWheel.glb`,
    ArkonLBWheel: `${baseURL}/Wheels/Compressed Wheels/ArkonLBWheel.glb`,
    ArkonLFWheel: `${baseURL}/Wheels/Compressed Wheels/ArkonLFWheel.glb`,
    ArkonRBWheel: `${baseURL}/Wheels/Compressed Wheels/ArkonRBWheel.glb`,
    ArkonRFWheel: `${baseURL}/Wheels/Compressed Wheels/ArkonRFWheel.glb`,
    Bethany5thWheel: `${baseURL}/Wheels/Compressed Wheels/Bethany5thWheel.glb`,
    BethanyLBWheel: `${baseURL}/Wheels/Compressed Wheels/BethanyLBWheel.glb`,
    BethanyLFWheel: `${baseURL}/Wheels/Compressed Wheels/BethanyLFWheel.glb`,
    BethanyRBWheel: `${baseURL}/Wheels/Compressed Wheels/BethanyRBWheel.glb`,
    BethanyRFWheel: `${baseURL}/Wheels/Compressed Wheels/BethanyRFWheel.glb`,
    BillHoughton5thWheel: `${baseURL}/Wheels/Compressed Wheels/BillHoughton5thWheel.glb`,
    BillHoughtonLBWheel: `${baseURL}/Wheels/Compressed Wheels/BillHoughtonLBWheel.glb`,
    BillHoughtonLFWheel: `${baseURL}/Wheels/Compressed Wheels/BillHoughtonLFWheel.glb`,
    BillHoughtonRBWheel: `${baseURL}/Wheels/Compressed Wheels/BillHoughtonRBWheel.glb`,
    BillHoughtonRFWheel: `${baseURL}/Wheels/Compressed Wheels/BillHoughtonRFWheel.glb`,
    BlackRhino5thWheel: `${baseURL}/Wheels/Compressed Wheels/BlackRhino5thWheel.glb`,
    BlackRhinoLBWheel: `${baseURL}/Wheels/Compressed Wheels/BlackRhinoLBWheel.glb`,
    BlackRhinoLFWheel: `${baseURL}/Wheels/Compressed Wheels/BlackRhinoLFWheel.glb`,
    BlackRhinoRBWheel: `${baseURL}/Wheels/Compressed Wheels/BlackRhinoRBWheel.glb`,
    BlackRhinoRFWheel: `${baseURL}/Wheels/Compressed Wheels/BlackRhinoRFWheel.glb`,
    Blitz5thWheel: `${baseURL}/Wheels/Compressed Wheels/Blitz5thWheel.glb`,
    BlitzLBWheel: `${baseURL}/Wheels/Compressed Wheels/BlitzLBWheel.glb`,
    BlitzLFWheel: `${baseURL}/Wheels/Compressed Wheels/BlitzLFWheel.glb`,
    BlitzRBWheel: `${baseURL}/Wheels/Compressed Wheels/BlitzRBWheel.glb`,
    BlitzRFWheel: `${baseURL}/Wheels/Compressed Wheels/BlitzRFWheel.glb`,
    Braydon5thWheel: `${baseURL}/Wheels/Compressed Wheels/Braydon5thWheel.glb`,
    BraydonLBWheel: `${baseURL}/Wheels/Compressed Wheels/BraydonLBWheel.glb`,
    BraydonLFWheel: `${baseURL}/Wheels/Compressed Wheels/BraydonLFWheel.glb`,
    BraydonRBWheel: `${baseURL}/Wheels/Compressed Wheels/BraydonRBWheel.glb`,
    BraydonRFWheel: `${baseURL}/Wheels/Compressed Wheels/BraydonRFWheel.glb`,
    CallieLemming5thWheel: `${baseURL}/Wheels/Compressed Wheels/CallieLemming5thWheel.glb`,
    CallieLemmingLBWheel: `${baseURL}/Wheels/Compressed Wheels/CallieLemmingLBWheel.glb`,
    CallieLemmingLFWheel: `${baseURL}/Wheels/Compressed Wheels/CallieLemmingLFWheel.glb`,
    CallieLemmingRBWheel: `${baseURL}/Wheels/Compressed Wheels/CallieLemmingRBWheel.glb`,
    CallieLemmingRFWheel: `${baseURL}/Wheels/Compressed Wheels/CallieLemmingRFWheel.glb`,
    ClaireGarette5thWheel: `${baseURL}/Wheels/Compressed Wheels/ClaireGarette5thWheel.glb`,
    ClaireGaretteLBWheel: `${baseURL}/Wheels/Compressed Wheels/ClaireGaretteLBWheel.glb`,
    ClaireGaretteLFWheel: `${baseURL}/Wheels/Compressed Wheels/ClaireGaretteLFWheel.glb`,
    ClaireGaretteRBWheel: `${baseURL}/Wheels/Compressed Wheels/ClaireGaretteRBWheel.glb`,
    ClaireGaretteRFWheel: `${baseURL}/Wheels/Compressed Wheels/ClaireGaretteRFWheel.glb`,
    ColterBahlau5thWheel: `${baseURL}/Wheels/Compressed Wheels/ColterBahlau5thWheel.glb`,
    ColterBahlauLBWheel: `${baseURL}/Wheels/Compressed Wheels/ColterBahlauLBWheel.glb`,
    ColterBahlauLFWheel: `${baseURL}/Wheels/Compressed Wheels/ColterBahlauLFWheel.glb`,
    ColterBahlauRBWheel: `${baseURL}/Wheels/Compressed Wheels/ColterBahlauRBWheel.glb`,
    ColterBahlauRFWheel: `${baseURL}/Wheels/Compressed Wheels/ColterBahlauRFWheel.glb`,
    CoreyJones5thWheel: `${baseURL}/Wheels/Compressed Wheels/CoreyJones5thWheel.glb`,
    CoreyJonesBLWheel: `${baseURL}/Wheels/Compressed Wheels/CoreyJonesBLWheel.glb`,
    CoreyJonesBRWheel: `${baseURL}/Wheels/Compressed Wheels/CoreyJonesBRWheel.glb`,
    CoreyJonesFLWheel: `${baseURL}/Wheels/Compressed Wheels/CoreyJonesFLWheel.glb`,
    CoreyJonesFRWheel: `${baseURL}/Wheels/Compressed Wheels/CoreyJonesFRWheel.glb`,
    DaniColeman5thWheel: `${baseURL}/Wheels/Compressed Wheels/DaniColeman5thWheel.glb`,
    DaniColemanBLWheel: `${baseURL}/Wheels/Compressed Wheels/DaniColemanBLWheel.glb`,
    DaniColemanBRWheel: `${baseURL}/Wheels/Compressed Wheels/DaniColemanBRWheel.glb`,
    DaniColemanFLWheel: `${baseURL}/Wheels/Compressed Wheels/DaniColemanFLWheel.glb`,
    DaniColemanFRWheel: `${baseURL}/Wheels/Compressed Wheels/DaniColemanFRWheel.glb`,
    DarleneMoscone5thWheel: `${baseURL}/Wheels/Compressed Wheels/DarleneMoscone5thWheel.glb`,
    DarleneMosconeBLWheel: `${baseURL}/Wheels/Compressed Wheels/DarleneMosconeBLWheel.glb`,
    DarleneMosconeBRWheel: `${baseURL}/Wheels/Compressed Wheels/DarleneMosconeBRWheel.glb`,
    DarleneMosconeFLWheel: `${baseURL}/Wheels/Compressed Wheels/DarleneMosconeFLWheel.glb`,
    DarleneMosconeFRWheel: `${baseURL}/Wheels/Compressed Wheels/DarleneMosconeFRWheel.glb`,
    DavidShafer5thWheel: `${baseURL}/Wheels/Compressed Wheels/DavidShafer5thWheel.glb`,
    DavidShaferBLWheel: `${baseURL}/Wheels/Compressed Wheels/DavidShaferBLWheel.glb`,
    DavidShaferBRWheel: `${baseURL}/Wheels/Compressed Wheels/DavidShaferBRWheel.glb`,
    DavidShaferFLWheel: `${baseURL}/Wheels/Compressed Wheels/DavidShaferFLWheel.glb`,
    DavidShaferFRWheel: `${baseURL}/Wheels/Compressed Wheels/DavidShaferFRWheel.glb`,
    DustinHemauer5thWheel: `${baseURL}/Wheels/Compressed Wheels/DustinHemauer5thWheel.glb`,
    DustinHemauerBLWheel: `${baseURL}/Wheels/Compressed Wheels/DustinHemauerBLWheel.glb`,
    DustinHemauerBRWheel: `${baseURL}/Wheels/Compressed Wheels/DustinHemauerBRWheel.glb`,
    DustinHemauerFLWheel: `${baseURL}/Wheels/Compressed Wheels/DustinHemauerFLWheel.glb`,
    DustinHemauerFRWheel: `${baseURL}/Wheels/Compressed Wheels/DustinHemauerFRWheel.glb`,
    GlenJones5thWheel: `${baseURL}/Wheels/Compressed Wheels/GlenJones5thWheel.glb`,
    GlenJonesBLWheel: `${baseURL}/Wheels/Compressed Wheels/GlenJonesBLWheel.glb`,
    GlenJonesBRWheel: `${baseURL}/Wheels/Compressed Wheels/GlenJonesBRWheel.glb`,
    GlenJonesFLWheel: `${baseURL}/Wheels/Compressed Wheels/GlenJonesFLWheel.glb`,
    GlenJonesFRWheel: `${baseURL}/Wheels/Compressed Wheels/GlenJonesFRWheel.glb`,
    JaredRenfroe5thWheel: `${baseURL}/Wheels/Compressed Wheels/JaredRenfroe5thWheel.glb`,
    JaredRenfroeBLWheel: `${baseURL}/Wheels/Compressed Wheels/JaredRenfroeBLWheel.glb`,
    JaredRenfroeBRWheel: `${baseURL}/Wheels/Compressed Wheels/JaredRenfroeBRWheel.glb`,
    JaredRenfroeFLWheel: `${baseURL}/Wheels/Compressed Wheels/JaredRenfroeFLWheel.glb`,
    JaredRenfroeFRWheel: `${baseURL}/Wheels/Compressed Wheels/JaredRenfroeFRWheel.glb`,
    JasonMartino5thWheel: `${baseURL}/Wheels/Compressed Wheels/JasonMartino5thWheel.glb`,
    JasonMartinoFLWheel: `${baseURL}/Wheels/Compressed Wheels/JasonMartinoFLWheel.glb`,
    JasonMartinoFRWheel: `${baseURL}/Wheels/Compressed Wheels/JasonMartinoFRWheel.glb`,
    JasonMartinoLBWheel: `${baseURL}/Wheels/Compressed Wheels/JasonMartinoLBWheel.glb`,
    JasonMartinoRBWheel: `${baseURL}/Wheels/Compressed Wheels/JasonMartinoRBWheel.glb`,
    JonathenMclaren5thWheel: `${baseURL}/Wheels/Compressed Wheels/JonathenMclaren5thWheel.glb`,
    JonathenMclarenFLWheel: `${baseURL}/Wheels/Compressed Wheels/JonathenMclarenFLWheel.glb`,
    JonathenMclarenFRWheel: `${baseURL}/Wheels/Compressed Wheels/JonathenMclarenFRWheel.glb`,
    JonathenMclarenLBWheel: `${baseURL}/Wheels/Compressed Wheels/JonathenMclarenLBWheel.glb`,
    JonathenMclarenRBWheel: `${baseURL}/Wheels/Compressed Wheels/JonathenMclarenRBWheel.glb`,
    JoyGarner5thWheel: `${baseURL}/Wheels/Compressed Wheels/JoyGarner5thWheel.glb`,
    JoyGarnerFLWheel: `${baseURL}/Wheels/Compressed Wheels/JoyGarnerFLWheel.glb`,
    JoyGarnerFRWheel: `${baseURL}/Wheels/Compressed Wheels/JoyGarnerFRWheel.glb`,
    JoyGarnerLBWheel: `${baseURL}/Wheels/Compressed Wheels/JoyGarnerLBWheel.glb`,
    JoyGarnerRBWheel: `${baseURL}/Wheels/Compressed Wheels/JoyGarnerRBWheel.glb`,
    KevinKan5thWheel: `${baseURL}/Wheels/Compressed Wheels/KevinKan5thWheel.glb`,
    KevinKanFLWheel: `${baseURL}/Wheels/Compressed Wheels/KevinKanFLWheel.glb`,
    KevinKanFRWheel: `${baseURL}/Wheels/Compressed Wheels/KevinKanFRWheel.glb`,
    KevinKanLBWheel: `${baseURL}/Wheels/Compressed Wheels/KevinKanLBWheel.glb`,
    KevinKanRBWheel: `${baseURL}/Wheels/Compressed Wheels/KevinKanRBWheel.glb`,
    MarkJHanscom5thWheel: `${baseURL}/Wheels/Compressed Wheels/MarkJHanscom5thWheel.glb`,
    MarkJHanscomFLWheel: `${baseURL}/Wheels/Compressed Wheels/MarkJHanscomFLWheel.glb`,
    MarkJHanscomFRWheel: `${baseURL}/Wheels/Compressed Wheels/MarkJHanscomFRWheel.glb`,
    MarkJHanscomLBWheel: `${baseURL}/Wheels/Compressed Wheels/MarkJHanscomLBWheel.glb`,
    MarkJHanscomRBWheel: `${baseURL}/Wheels/Compressed Wheels/MarkJHanscomRBWheel.glb`,
  },
  Other: {
    DarleneMoscocneSnorkel: `${baseURL}/Other/Compressed Other/DarleneMoscocneSnorkel.glb`,
    AdamWagnerFenderLB: `${baseURL}/Other/Compressed Other/AdamWagnerFenderLB.glb`,
    AdamWagnerFenderLF: `${baseURL}/Other/Compressed Other/AdamWagnerFenderLF.glb`,
    AdamWagnerFenderRB: `${baseURL}/Other/Compressed Other/AdamWagnerFenderRB.glb`,
    AdamWagnerFenderRF: `${baseURL}/Other/Compressed Other/AdamWagnerFenderRF.glb`,
    AndreaSimsFenderLB: `${baseURL}/Other/Compressed Other/AndreaSimsFenderLB.glb`,
    AndreaSimsFenderLF: `${baseURL}/Other/Compressed Other/AndreaSimsFenderLF.glb`,
    AndreaSimsFenderRB: `${baseURL}/Other/Compressed Other/AndreaSimsFenderRB.glb`,
    AndreaSimsFenderRF: `${baseURL}/Other/Compressed Other/AndreaSimsFenderRF.glb`,
    ChristinaCabraFenderLB: `${baseURL}/Other/Compressed Other/ChristinaCabraFenderLB.glb`,
    ChristinaCabraFenderLF: `${baseURL}/Other/Compressed Other/ChristinaCabraFenderLF.glb`,
    ChristinaCabraFenderRB: `${baseURL}/Other/Compressed Other/ChristinaCabraFenderRB.glb`,
    ChristinaCabraFenderRF: `${baseURL}/Other/Compressed Other/ChristinaCabraFenderRF.glb`,
    SnowwolfFenderLB: `${baseURL}/Other/Compressed Other/SnowwolfFenderLB.glb`,
    SnowwolfFenderLF: `${baseURL}/Other/Compressed Other/SnowwolfFenderLF.glb`,
    SnowwolfFenderRB: `${baseURL}/Other/Compressed Other/SnowwolfFenderRB.glb`,
    SnowwolfFenderRF: `${baseURL}/Other/Compressed Other/SnowwolfFenderRF.glb`,
    ToyotaTacomaFenderLB: `${baseURL}/Other/Compressed Other/ToyotaTacomaFenderLB.glb`,
    ToyotaTacomaFenderLF: `${baseURL}/Other/Compressed Other/ToyotaTacomaFenderLF.glb`,
    ToyotaTacomaFenderRB: `${baseURL}/Other/Compressed Other/ToyotaTacomaFenderRB.glb`,
    ToyotaTacomaFenderRF: `${baseURL}/Other/Compressed Other/ToyotaTacomaFenderRF.glb`,
  },
  Doors: {
    SimpleDoorFL: `${baseURL}/Door/Compressed Door/SImpleLeftdoor.glb`,
    SimpleDoorFR: `${baseURL}/Door/Compressed Door/SImpleRightDoor.glb`,
  },
};
