import { FourDoorWranglerParts } from '../ModelConstants.js';
import useGLBLoader from '../../../../useGlbLoader.js';

const useHoodContainer = (visibleItems) => {

  let darleneMoscocneHood = useGLBLoader(
    FourDoorWranglerParts.Hoods.DarleneMoscocneHood,
    visibleItems.includes("Darlene Moscocne Hood")
  );

  let jessicaVenableHood = useGLBLoader(
    FourDoorWranglerParts.Hoods.JessicaVenableHood,
    visibleItems.includes("Jessica Venable Hood")
  );

  let robertHutchingsHood = useGLBLoader(
    FourDoorWranglerParts.Hoods.RobertHutchingsHood,
    visibleItems.includes("Robert Hutchings Hood")
  );

  let simpleHood = useGLBLoader(
    FourDoorWranglerParts.Hoods.SimpleHood,
    visibleItems.includes("Simple Hood")
  );

  let yaseanBruceHood = useGLBLoader(
    FourDoorWranglerParts.Hoods.YaseanBruceHood,
    visibleItems.includes("Yasean Bruce Hood")
  );


  let rubiconHood = useGLBLoader(
    FourDoorWranglerParts.Hoods.rubiconHood,
    visibleItems.includes("Rubicon Hood")
  );


  const hoods = [
    { name: "Darlene Moscocne Hood", model: darleneMoscocneHood },
    { name: "Jessica Venable Hood", model: jessicaVenableHood },
    { name: "Robert Hutchings Hood", model: robertHutchingsHood },
    { name: "Simple Hood", model: simpleHood },
    { name: "Yasean Bruce Hood", model: yaseanBruceHood },
    { name: "Rubicon Hood", model: rubiconHood },
  ];

  return {
    hoods
  };
};

export default useHoodContainer;
