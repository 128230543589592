import useGLBLoader from '../../../../useGlbLoader.js';
import { TwoDoorWranglerParts } from '../Constants/ModelConstants.js';

const useOtherContainer = (visibleItems) => {
  let darleneMoscocneSnorkel = useGLBLoader(
    TwoDoorWranglerParts.Other.DarleneMoscocneSnorkel,
    visibleItems.includes("Darlene Moscocne Snorkel Other")
  );

  let adamWagnerFenderLB = useGLBLoader(
    TwoDoorWranglerParts.Other.AdamWagnerFenderLB,
    visibleItems.includes("Adam Wagner Fender Other")
  );
  
  let adamWagnerFenderLF = useGLBLoader(
    TwoDoorWranglerParts.Other.AdamWagnerFenderLF,
    visibleItems.includes("Adam Wagner Fender Other")
  );
  
  let adamWagnerFenderRB = useGLBLoader(
    TwoDoorWranglerParts.Other.AdamWagnerFenderRB,
    visibleItems.includes("Adam Wagner Fender Other")
  );
  
  let adamWagnerFenderRF = useGLBLoader(
    TwoDoorWranglerParts.Other.AdamWagnerFenderRF,
    visibleItems.includes("Adam Wagner Fender Other")
  );
  
  let andreaSimsFenderLB = useGLBLoader(
    TwoDoorWranglerParts.Other.AndreaSimsFenderLB,
    visibleItems.includes("Andrea Sims Fender Other")
  );
  
  let andreaSimsFenderLF = useGLBLoader(
    TwoDoorWranglerParts.Other.AndreaSimsFenderLF,
    visibleItems.includes("Andrea Sims Fender Other")
  );
  
  let andreaSimsFenderRB = useGLBLoader(
    TwoDoorWranglerParts.Other.AndreaSimsFenderRB,
    visibleItems.includes("Andrea Sims Fender Other")
  );
  
  let andreaSimsFenderRF = useGLBLoader(
    TwoDoorWranglerParts.Other.AndreaSimsFenderRF,
    visibleItems.includes("Andrea Sims Fender Other")
  );
  
  let christinaCabraFenderLB = useGLBLoader(
    TwoDoorWranglerParts.Other.ChristinaCabraFenderLB,
    visibleItems.includes("Christina Cabra Fender Other")
  );
  
  let christinaCabraFenderLF = useGLBLoader(
    TwoDoorWranglerParts.Other.ChristinaCabraFenderLF,
    visibleItems.includes("Christina Cabra Fender Other")
  );
  
  let christinaCabraFenderRB = useGLBLoader(
    TwoDoorWranglerParts.Other.ChristinaCabraFenderRB,
    visibleItems.includes("Christina Cabra Fender Other")
  );
  
  let christinaCabraFenderRF = useGLBLoader(
    TwoDoorWranglerParts.Other.ChristinaCabraFenderRF,
    visibleItems.includes("Christina Cabra Fender Other")
  );
  
  let snowwolfFenderLB = useGLBLoader(
    TwoDoorWranglerParts.Other.SnowwolfFenderLB,
    visibleItems.includes("Snowwolf Fender Other")
  );
  
  let snowwolfFenderLF = useGLBLoader(
    TwoDoorWranglerParts.Other.SnowwolfFenderLF,
    visibleItems.includes("Snowwolf Fender Other")
  );
  
  let snowwolfFenderRB = useGLBLoader(
    TwoDoorWranglerParts.Other.SnowwolfFenderRB,
    visibleItems.includes("Snowwolf Fender Other")
  );
  
  let snowwolfFenderRF = useGLBLoader(
    TwoDoorWranglerParts.Other.SnowwolfFenderRF,
    visibleItems.includes("Snowwolf Fender Other")
  );
  
  let toyotaTacomaFenderLB = useGLBLoader(
    TwoDoorWranglerParts.Other.ToyotaTacomaFenderLB,
    visibleItems.includes("Toyota Tacoma Fender Other")
  );
  
  let toyotaTacomaFenderLF = useGLBLoader(
    TwoDoorWranglerParts.Other.ToyotaTacomaFenderLF,
    visibleItems.includes("Toyota Tacoma Fender Other")
  );
  
  let toyotaTacomaFenderRB = useGLBLoader(
    TwoDoorWranglerParts.Other.ToyotaTacomaFenderRB,
    visibleItems.includes("Toyota Tacoma Fender Other")
  );
  
  let toyotaTacomaFenderRF = useGLBLoader(
    TwoDoorWranglerParts.Other.ToyotaTacomaFenderRF,
    visibleItems.includes("Toyota Tacoma Fender Other")
  );

  const otherParts = [
    { name: "Darlene Moscocne Snorkel Other", model: darleneMoscocneSnorkel },
    { name: "Adam Wagner Fender Other", model: adamWagnerFenderLB },
    { name: "Adam Wagner Fender Other", model: adamWagnerFenderLF },
    { name: "Adam Wagner Fender Other", model: adamWagnerFenderRB },
    { name: "Adam Wagner Fender Other", model: adamWagnerFenderRF },
    { name: "Andrea Sims Fender Other", model: andreaSimsFenderLB },
    { name: "Andrea Sims Fender Other", model: andreaSimsFenderLF },
    { name: "Andrea Sims Fender Other", model: andreaSimsFenderRB },
    { name: "Andrea Sims Fender Other", model: andreaSimsFenderRF },
    { name: "Christina Cabra Fender Other", model: christinaCabraFenderLF },
    { name: "Christina Cabra Fender Other", model: christinaCabraFenderLB },
    { name: "Christina Cabra Fender Other", model: christinaCabraFenderRB },
    { name: "Christina Cabra Fender Other", model: christinaCabraFenderRF },
    { name: "Snowwolf Fender Other", model: snowwolfFenderLB },
    { name: "Snowwolf Fender Other", model: snowwolfFenderLF },
    { name: "Snowwolf Fender Other", model: snowwolfFenderRB },
    { name: "Snowwolf Fender Other", model: snowwolfFenderRF },
    { name: "Toyota Tacoma Fender Other", model: toyotaTacomaFenderLB },
    { name: "Toyota Tacoma Fender Other", model: toyotaTacomaFenderLF },
    { name: "Toyota Tacoma Fender Other", model: toyotaTacomaFenderRB },
    { name: "Toyota Tacoma Fender Other", model: toyotaTacomaFenderRF },
  ];

  return {
    otherParts,
  };
};

export default useOtherContainer;
