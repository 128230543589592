import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <React.StrictMode>
      <PayPalScriptProvider
        options={{
          "client-id":
            "AXi5au2yA1Om1z6OIPGlEq6b3LgT8O_GDTVf0z7mpjrKbOhx0i5_a2eTsiME3JEV9qW3uqD4C_YM-1Fl", // Replace with your actual Client ID
          currency: "USD", // Optional: Set default currency
          intent: "capture",
          components: "buttons", // Optional: Specify available components (buttons, card, order, etc.)
        }}
      >
        <App />
      </PayPalScriptProvider>
    </React.StrictMode>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
