import { FourDoorWranglerParts } from "../ModelConstants.js";
import useGLBLoader from "../../../../useGlbLoader.js";

const useWheelsContainer = (visibleItems) => {
  let aaronGordon5thWheel = useGLBLoader(
    FourDoorWranglerParts.Wheels.AaronGordon5thWheel,
    visibleItems.includes("Aaron Gordon Rims")
  );

  let aaronGordonLBWheel = useGLBLoader(
    FourDoorWranglerParts.Wheels.AaronGordonLBWheel,
    visibleItems.includes("Aaron Gordon Rims")
  );

  let aaronGordonLFWheel = useGLBLoader(
    FourDoorWranglerParts.Wheels.AaronGordonLFWheel,
    visibleItems.includes("Aaron Gordon Rims")
  );

  let aaronGordonRBWheel = useGLBLoader(
    FourDoorWranglerParts.Wheels.AaronGordonRBWheel,
    visibleItems.includes("Aaron Gordon Rims")
  );

  let aaronGordonRFWheel = useGLBLoader(
    FourDoorWranglerParts.Wheels.AaronGordonRFWheel,
    visibleItems.includes("Aaron Gordon Rims")
  );

  let arkon5thWheel = useGLBLoader(
    FourDoorWranglerParts.Wheels.Arkon5thWheel,
    visibleItems.includes("Arkon Rims")
  );

  let arkonLBWheel = useGLBLoader(
    FourDoorWranglerParts.Wheels.ArkonLBWheel,
    visibleItems.includes("Arkon Rims")
  );

  let arkonLFWheel = useGLBLoader(
    FourDoorWranglerParts.Wheels.ArkonLFWheel,
    visibleItems.includes("Arkon Rims")
  );

  let arkonRBWheel = useGLBLoader(
    FourDoorWranglerParts.Wheels.ArkonRBWheel,
    visibleItems.includes("Arkon Rims")
  );

  let arkonRFWheel = useGLBLoader(
    FourDoorWranglerParts.Wheels.ArkonRFWheel,
    visibleItems.includes("Arkon Rims")
  );

  let bethanySmeltzer5thWheel = useGLBLoader(
    FourDoorWranglerParts.Wheels.BethanySmeltzer5thWheel,
    visibleItems.includes("Bethany Smeltzer Rims")
  );

  let bethanySmeltzerLBWheel = useGLBLoader(
    FourDoorWranglerParts.Wheels.BethanySmeltzerLBWheel,
    visibleItems.includes("Bethany Smeltzer Rims")
  );

  let bethanySmeltzerLFWheel = useGLBLoader(
    FourDoorWranglerParts.Wheels.BethanySmeltzerLFWheel,
    visibleItems.includes("Bethany Smeltzer Rims")
  );

  let bethanySmeltzerRBWheel = useGLBLoader(
    FourDoorWranglerParts.Wheels.BethanySmeltzerRBWheel,
    visibleItems.includes("Bethany Smeltzer Rims")
  );

  let bethanySmeltzerRFWheel = useGLBLoader(
    FourDoorWranglerParts.Wheels.BethanySmeltzerRFWheel,
    visibleItems.includes("Bethany Smeltzer Rims")
  );

  let billHoughton5thWheel = useGLBLoader(
    FourDoorWranglerParts.Wheels.BillHoughton5thWheel,
    visibleItems.includes("Bill Houghton Rims")
  );

  let billHoughtonLBWheel = useGLBLoader(
    FourDoorWranglerParts.Wheels.BillHoughtonLBWheel,
    visibleItems.includes("Bill Houghton Rims")
  );

  let billHoughtonLFWheel = useGLBLoader(
    FourDoorWranglerParts.Wheels.BillHoughtonLFWheel,
    visibleItems.includes("Bill Houghton Rims")
  );

  let billHoughtonRBWheel = useGLBLoader(
    FourDoorWranglerParts.Wheels.BillHoughtonRBWheel,
    visibleItems.includes("Bill Houghton Rims")
  );

  let billHoughtonRFWheel = useGLBLoader(
    FourDoorWranglerParts.Wheels.BillHoughtonRFWheel,
    visibleItems.includes("Bill Houghton Rims")
  );

  let blackRhino5thWheel = useGLBLoader(
    FourDoorWranglerParts.Wheels.BlackRhino5thWheel,
    visibleItems.includes("Black Rhino Rims")
  );

  let blackRhinoLBWheel = useGLBLoader(
    FourDoorWranglerParts.Wheels.BlackRhinoLBWheel,
    visibleItems.includes("Black Rhino Rims")
  );

  let blackRhinoLFWheel = useGLBLoader(
    FourDoorWranglerParts.Wheels.BlackRhinoLFWheel,
    visibleItems.includes("Black Rhino Rims")
  );

  let blackRhinoRBWheel = useGLBLoader(
    FourDoorWranglerParts.Wheels.BlackRhinoRBWheel,
    visibleItems.includes("Black Rhino Rims")
  );

  let blackRhinoRFWheel = useGLBLoader(
    FourDoorWranglerParts.Wheels.BlackRhinoRFWheel,
    visibleItems.includes("Black Rhino Rims")
  );

  let blitzL5thWheel = useGLBLoader(
    FourDoorWranglerParts.Wheels.BlitzL5thWheel,
    visibleItems.includes("Blitz Rims")
  );

  let blitzLBWheel = useGLBLoader(
    FourDoorWranglerParts.Wheels.BlitzLBWheel,
    visibleItems.includes("Blitz Rims")
  );

  let blitzLFWheel = useGLBLoader(
    FourDoorWranglerParts.Wheels.BlitzLFWheel,
    visibleItems.includes("Blitz Rims")
  );

  let blitzRBWheel = useGLBLoader(
    FourDoorWranglerParts.Wheels.BlitzRBWheel,
    visibleItems.includes("Blitz Rims")
  );

  let blitzRFWheel = useGLBLoader(
    FourDoorWranglerParts.Wheels.BlitzRFWheel,
    visibleItems.includes("Blitz Rims")
  );

  let braydon5thWheel = useGLBLoader(
    FourDoorWranglerParts.Wheels.Braydon5thWheel,
    visibleItems.includes("Braydon Rims")
  );

  let braydonLBWheel = useGLBLoader(
    FourDoorWranglerParts.Wheels.BraydonLBWheel,
    visibleItems.includes("Braydon Rims")
  );

  let braydonLFWheel = useGLBLoader(
    FourDoorWranglerParts.Wheels.BraydonLFWheel,
    visibleItems.includes("Braydon Rims")
  );

  let braydonRBWheel = useGLBLoader(
    FourDoorWranglerParts.Wheels.BraydonRBWheel,
    visibleItems.includes("Braydon Rims")
  );

  let braydonRFWheel = useGLBLoader(
    FourDoorWranglerParts.Wheels.BraydonRFWheel,
    visibleItems.includes("Braydon Rims")
  );

  let callieLemming5thWheel = useGLBLoader(
    FourDoorWranglerParts.Wheels.CallieLemming5thWheel,
    visibleItems.includes("Callie Lemming Rims")
  );

  let callieLemmingLBWheel = useGLBLoader(
    FourDoorWranglerParts.Wheels.CallieLemmingLBWheel,
    visibleItems.includes("Callie Lemming Rims")
  );

  let callieLemmingLFWheel = useGLBLoader(
    FourDoorWranglerParts.Wheels.CallieLemmingLFWheel,
    visibleItems.includes("Callie Lemming Rims")
  );

  let callieLemmingRBWheel = useGLBLoader(
    FourDoorWranglerParts.Wheels.CallieLemmingRBWheel,
    visibleItems.includes("Callie Lemming Rims")
  );

  let callieLemmingRFWheel = useGLBLoader(
    FourDoorWranglerParts.Wheels.CallieLemmingRFWheel,
    visibleItems.includes("Callie Lemming Rims")
  );

  let claireGarette5thWheel = useGLBLoader(
    FourDoorWranglerParts.Wheels.ClaireGarette5thWheel,
    visibleItems.includes("Claire Garette Rims")
  );

  let claireGaretteLBWheel = useGLBLoader(
    FourDoorWranglerParts.Wheels.ClaireGaretteLBWheel,
    visibleItems.includes("Claire Garette Rims")
  );

  let claireGaretteLFWheel = useGLBLoader(
    FourDoorWranglerParts.Wheels.ClaireGaretteLFWheel,
    visibleItems.includes("Claire Garette Rims")
  );

  let claireGaretteRBWheel = useGLBLoader(
    FourDoorWranglerParts.Wheels.ClaireGaretteRBWheel,
    visibleItems.includes("Claire Garette Rims")
  );

  let claireGaretteRFWheel = useGLBLoader(
    FourDoorWranglerParts.Wheels.ClaireGaretteRFWheel,
    visibleItems.includes("Claire Garette Rims")
  );

  let colterBahlau5thWheel = useGLBLoader(
    FourDoorWranglerParts.Wheels.ColterBahlau5thWheel,
    visibleItems.includes("Colter Bahlau Rims")
  );

  let colterBahlauLBWheel = useGLBLoader(
    FourDoorWranglerParts.Wheels.ColterBahlauLBWheel,
    visibleItems.includes("Colter Bahlau Rims")
  );

  let colterBahlauLFWheel = useGLBLoader(
    FourDoorWranglerParts.Wheels.ColterBahlauLFWheel,
    visibleItems.includes("Colter Bahlau Rims")
  );

  let colterBahlauRBWheel = useGLBLoader(
    FourDoorWranglerParts.Wheels.ColterBahlauRBWheel,
    visibleItems.includes("Colter Bahlau Rims")
  );

  let colterBahlauRFWheel = useGLBLoader(
    FourDoorWranglerParts.Wheels.ColterBahlauRFWheel,
    visibleItems.includes("Colter Bahlau Rims")
  );

  let corinneOsborne5thWheel = useGLBLoader(
    FourDoorWranglerParts.Wheels.CorinneOsborne5thWheel,
    visibleItems.includes("Corinne Osborne Rims")
  );

  let corinneOsborneLBWheel = useGLBLoader(
    FourDoorWranglerParts.Wheels.CorinneOsborneLBWheel,
    visibleItems.includes("Corinne Osborne Rims")
  );

  let corinneOsborneLFWheel = useGLBLoader(
    FourDoorWranglerParts.Wheels.CorinneOsborneLFWheel,
    visibleItems.includes("Corinne Osborne Rims")
  );

  let corinneOsborneRBWheel = useGLBLoader(
    FourDoorWranglerParts.Wheels.CorinneOsborneRBWheel,
    visibleItems.includes("Corinne Osborne Rims")
  );

  let corinneOsborneRFWheel = useGLBLoader(
    FourDoorWranglerParts.Wheels.CorinneOsborneRFWheel,
    visibleItems.includes("Corinne Osborne Rims")
  );

  let denimWilson5thWheel = useGLBLoader(
    FourDoorWranglerParts.Wheels.DenimWilson5thWheel,
    visibleItems.includes("Denim Wilson Rims")
  );

  let denimWilsonLBWheel = useGLBLoader(
    FourDoorWranglerParts.Wheels.DenimWilsonLBWheel,
    visibleItems.includes("Denim Wilson Rims")
  );

  let denimWilsonLFWheel = useGLBLoader(
    FourDoorWranglerParts.Wheels.DenimWilsonLFWheel,
    visibleItems.includes("Denim Wilson Rims")
  );

  let denimWilsonRBWheel = useGLBLoader(
    FourDoorWranglerParts.Wheels.DenimWilsonRBWheel,
    visibleItems.includes("Denim Wilson Rims")
  );

  let denimWilsonRFWheel = useGLBLoader(
    FourDoorWranglerParts.Wheels.DenimWilsonRFWheel,
    visibleItems.includes("Denim Wilson Rims")
  );

  let dominicFane5thWheel = useGLBLoader(
    FourDoorWranglerParts.Wheels.DominicFane5thWheel,
    visibleItems.includes("Dominic Fane Rims")
  );

  let dominicFaneLBWheel = useGLBLoader(
    FourDoorWranglerParts.Wheels.DominicFaneLBWheel,
    visibleItems.includes("Dominic Fane Rims")
  );

  let dominicFaneLFWheel = useGLBLoader(
    FourDoorWranglerParts.Wheels.DominicFaneLFWheel,
    visibleItems.includes("Dominic Fane Rims")
  );

  let dominicFaneRBWheel = useGLBLoader(
    FourDoorWranglerParts.Wheels.DominicFaneRBWheel,
    visibleItems.includes("Dominic Fane Rims")
  );

  let dominicFaneRFWheel = useGLBLoader(
    FourDoorWranglerParts.Wheels.DominicFaneRFWheel,
    visibleItems.includes("Dominic Fane Rims")
  );

  let drakeBrowning5thWheel = useGLBLoader(
    FourDoorWranglerParts.Wheels.DrakeBrowning5thWheel,
    visibleItems.includes("Drake Browning Rims")
  );

  let drakeBrowningLBWheel = useGLBLoader(
    FourDoorWranglerParts.Wheels.DrakeBrowningLBWheel,
    visibleItems.includes("Drake Browning Rims")
  );

  let drakeBrowningLFWheel = useGLBLoader(
    FourDoorWranglerParts.Wheels.DrakeBrowningLFWheel,
    visibleItems.includes("Drake Browning Rims")
  );

  let drakeBrowningRBWheel = useGLBLoader(
    FourDoorWranglerParts.Wheels.DrakeBrowningRBWheel,
    visibleItems.includes("Drake Browning Rims")
  );

  let drakeBrowningRFWheel = useGLBLoader(
    FourDoorWranglerParts.Wheels.DrakeBrowningRFWheel,
    visibleItems.includes("Drake Browning Rims")
  );

  let emmaLawrence5thWheel = useGLBLoader(
    FourDoorWranglerParts.Wheels.EmmaLawrence5thWheel,
    visibleItems.includes("Emma Lawrence Rims")
  );

  let emmaLawrenceLBWheel = useGLBLoader(
    FourDoorWranglerParts.Wheels.EmmaLawrenceLBWheel,
    visibleItems.includes("Emma Lawrence Rims")
  );

  let emmaLawrenceLFWheel = useGLBLoader(
    FourDoorWranglerParts.Wheels.EmmaLawrenceLFWheel,
    visibleItems.includes("Emma Lawrence Rims")
  );

  let emmaLawrenceRBWheel = useGLBLoader(
    FourDoorWranglerParts.Wheels.EmmaLawrenceRBWheel,
    visibleItems.includes("Emma Lawrence Rims")
  );

  let emmaLawrenceRFWheel = useGLBLoader(
    FourDoorWranglerParts.Wheels.EmmaLawrenceRFWheel,
    visibleItems.includes("Emma Lawrence Rims")
  );

  let evaPerryman5thWheel = useGLBLoader(
    FourDoorWranglerParts.Wheels.EvaPerryman5thWheel,
    visibleItems.includes("Eva Perryman Rims")
  );

  let evaPerrymanLBWheel = useGLBLoader(
    FourDoorWranglerParts.Wheels.EvaPerrymanLBWheel,
    visibleItems.includes("Eva Perryman Rims")
  );

  let evaPerrymanLFWheel = useGLBLoader(
    FourDoorWranglerParts.Wheels.EvaPerrymanLFWheel,
    visibleItems.includes("Eva Perryman Rims")
  );

  let evaPerrymanRBWheel = useGLBLoader(
    FourDoorWranglerParts.Wheels.EvaPerrymanRBWheel,
    visibleItems.includes("Eva Perryman Rims")
  );

  let evaPerrymanRFWheel = useGLBLoader(
    FourDoorWranglerParts.Wheels.EvaPerrymanRFWheel,
    visibleItems.includes("Eva Perryman Rims")
  );

  let jaxonHarris5thWheel = useGLBLoader(
    FourDoorWranglerParts.Wheels.JaxonHarris5thWheel,
    visibleItems.includes("Jaxon Harris Rims")
  );

  let jaxonHarrisLBWheel = useGLBLoader(
    FourDoorWranglerParts.Wheels.JaxonHarrisLBWheel,
    visibleItems.includes("Jaxon Harris Rims")
  );

  let jaxonHarrisLFWheel = useGLBLoader(
    FourDoorWranglerParts.Wheels.JaxonHarrisLFWheel,
    visibleItems.includes("Jaxon Harris Rims")
  );

  let jaxonHarrisRBWheel = useGLBLoader(
    FourDoorWranglerParts.Wheels.JaxonHarrisRBWheel,
    visibleItems.includes("Jaxon Harris Rims")
  );

  let jaxonHarrisRFWheel = useGLBLoader(
    FourDoorWranglerParts.Wheels.JaxonHarrisRFWheel,
    visibleItems.includes("Jaxon Harris Rims")
  );

  let katieNguyen5thWheel = useGLBLoader(
    FourDoorWranglerParts.Wheels.KatieNguyen5thWheel,
    visibleItems.includes("Katie Nguyen Rims")
  );

  let katieNguyenLBWheel = useGLBLoader(
    FourDoorWranglerParts.Wheels.KatieNguyenLBWheel,
    visibleItems.includes("Katie Nguyen Rims")
  );

  let katieNguyenLFWheel = useGLBLoader(
    FourDoorWranglerParts.Wheels.KatieNguyenLFWheel,
    visibleItems.includes("Katie Nguyen Rims")
  );

  let katieNguyenRBWheel = useGLBLoader(
    FourDoorWranglerParts.Wheels.KatieNguyenRBWheel,
    visibleItems.includes("Katie Nguyen Rims")
  );

  let katieNguyenRFWheel = useGLBLoader(
    FourDoorWranglerParts.Wheels.KatieNguyenRFWheel,
    visibleItems.includes("Katie Nguyen Rims")
  );

  let kevin5thWheel = useGLBLoader(
    FourDoorWranglerParts.Wheels.Kevin5thWheel,
    visibleItems.includes("Kevin Rims")
  );

  let kevinLBWheel = useGLBLoader(
    FourDoorWranglerParts.Wheels.KevinLBWheel,
    visibleItems.includes("Kevin Rims")
  );

  let kevinLFWheel = useGLBLoader(
    FourDoorWranglerParts.Wheels.KevinLFWheel,
    visibleItems.includes("Kevin Rims")
  );

  let kevinRBWheel = useGLBLoader(
    FourDoorWranglerParts.Wheels.KevinRBWheel,
    visibleItems.includes("Kevin Rims")
  );

  let kevinRFWheel = useGLBLoader(
    FourDoorWranglerParts.Wheels.KevinRFWheel,
    visibleItems.includes("Kevin Rims")
  );

  let marissaShepherd5thWheel = useGLBLoader(
    FourDoorWranglerParts.Wheels.MarissaShepherd5thWheel,
    visibleItems.includes("Marissa Shepherd Rims")
  );

  let marissaShepherdLBWheel = useGLBLoader(
    FourDoorWranglerParts.Wheels.MarissaShepherdLBWheel,
    visibleItems.includes("Marissa Shepherd Rims")
  );

  let marissaShepherdLFWheel = useGLBLoader(
    FourDoorWranglerParts.Wheels.MarissaShepherdLFWheel,
    visibleItems.includes("Marissa Shepherd Rims")
  );

  let marissaShepherdRBWheel = useGLBLoader(
    FourDoorWranglerParts.Wheels.MarissaShepherdRBWheel,
    visibleItems.includes("Marissa Shepherd Rims")
  );

  let marissaShepherdRFWheel = useGLBLoader(
    FourDoorWranglerParts.Wheels.MarissaShepherdRFWheel,
    visibleItems.includes("Marissa Shepherd Rims")
  );

  let reganFleming5thWheel = useGLBLoader(
    FourDoorWranglerParts.Wheels.ReganFleming5thWheel,
    visibleItems.includes("Regan Fleming Rims")
  );

  let reganFlemingLBWheel = useGLBLoader(
    FourDoorWranglerParts.Wheels.ReganFlemingLBWheel,
    visibleItems.includes("Regan Fleming Rims")
  );

  let reganFlemingLFWheel = useGLBLoader(
    FourDoorWranglerParts.Wheels.ReganFlemingLFWheel,
    visibleItems.includes("Regan Fleming Rims")
  );

  let reganFlemingRBWheel = useGLBLoader(
    FourDoorWranglerParts.Wheels.ReganFlemingRBWheel,
    visibleItems.includes("Regan Fleming Rims")
  );

  let reganFlemingRFWheel = useGLBLoader(
    FourDoorWranglerParts.Wheels.ReganFlemingRFWheel,
    visibleItems.includes("Regan Fleming Rims")
  );

  let thomasLopez5thWheel = useGLBLoader(
    FourDoorWranglerParts.Wheels.ThomasLopez5thWheel,
    visibleItems.includes("Thomas Lopez Rims")
  );

  let thomasLopezLBWheel = useGLBLoader(
    FourDoorWranglerParts.Wheels.ThomasLopezLBWheel,
    visibleItems.includes("Thomas Lopez Rims")
  );

  let thomasLopezLFWheel = useGLBLoader(
    FourDoorWranglerParts.Wheels.ThomasLopezLFWheel,
    visibleItems.includes("Thomas Lopez Rims")
  );

  let thomasLopezRBWheel = useGLBLoader(
    FourDoorWranglerParts.Wheels.ThomasLopezRBWheel,
    visibleItems.includes("Thomas Lopez Rims")
  );

  let thomasLopezRFWheel = useGLBLoader(
    FourDoorWranglerParts.Wheels.ThomasLopezRFWheel,
    visibleItems.includes("Thomas Lopez Rims")
  );

  let wesleyRichardson5thWheel = useGLBLoader(
    FourDoorWranglerParts.Wheels.WesleyRichardson5thWheel,
    visibleItems.includes("Wesley Richardson Rims")
  );

  let wesleyRichardsonLBWheel = useGLBLoader(
    FourDoorWranglerParts.Wheels.WesleyRichardsonLBWheel,
    visibleItems.includes("Wesley Richardson Rims")
  );

  let wesleyRichardsonLFWheel = useGLBLoader(
    FourDoorWranglerParts.Wheels.WesleyRichardsonLFWheel,
    visibleItems.includes("Wesley Richardson Rims")
  );

  let wesleyRichardsonRBWheel = useGLBLoader(
    FourDoorWranglerParts.Wheels.WesleyRichardsonRBWheel,
    visibleItems.includes("Wesley Richardson Rims")
  );

  let wesleyRichardsonRFWheel = useGLBLoader(
    FourDoorWranglerParts.Wheels.WesleyRichardsonRFWheel,
    visibleItems.includes("Wesley Richardson Rims")
  );

  let coreyJones5thWheel = useGLBLoader(
    FourDoorWranglerParts.Wheels.CoreyJones5thWheel,
    visibleItems.includes("Corey Jones Rims")
  );
  
  let coreyJonesBLWheel = useGLBLoader(
    FourDoorWranglerParts.Wheels.CoreyJonesBLWheel,
    visibleItems.includes("Corey Jones Rims")
  );
  
  let coreyJonesBRWheel = useGLBLoader(
    FourDoorWranglerParts.Wheels.CoreyJonesBRWheel,
    visibleItems.includes("Corey Jones Rims")
  );
  
  let coreyJonesFLWheel = useGLBLoader(
    FourDoorWranglerParts.Wheels.CoreyJonesFLWheel,
    visibleItems.includes("Corey Jones Rims")
  );
  
  let coreyJonesFRWheel = useGLBLoader(
    FourDoorWranglerParts.Wheels.CoreyJonesFRWheel,
    visibleItems.includes("Corey Jones Rims")
  );

  let daniColeman5thWheel = useGLBLoader(
    FourDoorWranglerParts.Wheels.DaniColeman5thWheel,
    visibleItems.includes("Dani Coleman Rims")
  );
  
  let daniColemanBLWheel = useGLBLoader(
    FourDoorWranglerParts.Wheels.DaniColemanBLWheel,
    visibleItems.includes("Dani Coleman Rims")
  );
  
  let daniColemanBRWheel = useGLBLoader(
    FourDoorWranglerParts.Wheels.DaniColemanBRWheel,
    visibleItems.includes("Dani Coleman Rims")
  );
  
  let daniColemanFLWheel = useGLBLoader(
    FourDoorWranglerParts.Wheels.DaniColemanFLWheel,
    visibleItems.includes("Dani Coleman Rims")
  );
  
  let daniColemanFRWheel = useGLBLoader(
    FourDoorWranglerParts.Wheels.DaniColemanFRWheel,
    visibleItems.includes("Dani Coleman Rims")
  );

  let darleneMoscone5thWheel = useGLBLoader(
    FourDoorWranglerParts.Wheels.DarleneMoscone5thWheel,
    visibleItems.includes("Darlene Moscone Rims")
  );
  
  let darleneMosconeBLWheel = useGLBLoader(
    FourDoorWranglerParts.Wheels.DarleneMosconeBLWheel,
    visibleItems.includes("Darlene Moscone Rims")
  );
  
  let darleneMosconeBRWheel = useGLBLoader(
    FourDoorWranglerParts.Wheels.DarleneMosconeBRWheel,
    visibleItems.includes("Darlene Moscone Rims")
  );
  
  let darleneMosconeFLWheel = useGLBLoader(
    FourDoorWranglerParts.Wheels.DarleneMosconeFLWheel,
    visibleItems.includes("Darlene Moscone Rims")
  );
  
  let darleneMosconeFRWheel = useGLBLoader(
    FourDoorWranglerParts.Wheels.DarleneMosconeFRWheel,
    visibleItems.includes("Darlene Moscone Rims")
  );
  let davidShafer5thWheel = useGLBLoader(
    FourDoorWranglerParts.Wheels.DavidShafer5thWheel,
    visibleItems.includes("David Shafer Rims")
  );
  
  let davidShaferBLWheel = useGLBLoader(
    FourDoorWranglerParts.Wheels.DavidShaferBLWheel,
    visibleItems.includes("David Shafer Rims")
  );
  
  let davidShaferBRWheel = useGLBLoader(
    FourDoorWranglerParts.Wheels.DavidShaferBRWheel,
    visibleItems.includes("David Shafer Rims")
  );
  
  let davidShaferFLWheel = useGLBLoader(
    FourDoorWranglerParts.Wheels.DavidShaferFLWheel,
    visibleItems.includes("David Shafer Rims")
  );
  
  let davidShaferFRWheel = useGLBLoader(
    FourDoorWranglerParts.Wheels.DavidShaferFRWheel,
    visibleItems.includes("David Shafer Rims")
  );
  let dustinHemauer5thWheel = useGLBLoader(
    FourDoorWranglerParts.Wheels.DustinHemauer5thWheel,
    visibleItems.includes("Dustin Hemauer Rims")
  );
  
  let dustinHemauerFLWheel = useGLBLoader(
    FourDoorWranglerParts.Wheels.DustinHemauerFLWheel,
    visibleItems.includes("Dustin Hemauer Rims")
  );
  
  let dustinHemauerFRWheel = useGLBLoader(
    FourDoorWranglerParts.Wheels.DustinHemauerFRWheel,
    visibleItems.includes("Dustin Hemauer Rims")
  );
  
  let dustinHemauerBLWheel = useGLBLoader(
    FourDoorWranglerParts.Wheels.DustinHemauerBLWheel,
    visibleItems.includes("Dustin Hemauer Rims")
  );
  
  let dustinHemauerBRWheel = useGLBLoader(
    FourDoorWranglerParts.Wheels.DustinHemauerBRWheel,
    visibleItems.includes("Dustin Hemauer Rims")
  );
  
  let glenJones5thWheel = useGLBLoader(
    FourDoorWranglerParts.Wheels.GlenJones5thWheel,
    visibleItems.includes("Glen Jones Rims")
  );
  
  let glenJonesBLWheel = useGLBLoader(
    FourDoorWranglerParts.Wheels.GlenJonesBLWheel,
    visibleItems.includes("Glen Jones Rims")
  );
  
  let glenJonesBRWheel = useGLBLoader(
    FourDoorWranglerParts.Wheels.GlenJonesBRWheel,
    visibleItems.includes("Glen Jones Rims")
  );
  
  let glenJonesFLWheel = useGLBLoader(
    FourDoorWranglerParts.Wheels.GlenJonesFLWheel,
    visibleItems.includes("Glen Jones Rims")
  );
  
  let glenJonesFRWheel = useGLBLoader(
    FourDoorWranglerParts.Wheels.GlenJonesFRWheel,
    visibleItems.includes("Glen Jones Rims")
  );

  let jaredRenfroe5thWheel = useGLBLoader(
    FourDoorWranglerParts.Wheels.JaredRenfroe5thWheel,
    visibleItems.includes("Jared Renfroe Rims")
  );
  
  let jaredRenfroeBLWheel = useGLBLoader(
    FourDoorWranglerParts.Wheels.JaredRenfroeBLWheel,
    visibleItems.includes("Jared Renfroe Rims")
  );
  
  let jaredRenfroeBRWheel = useGLBLoader(
    FourDoorWranglerParts.Wheels.JaredRenfroeBRWheel,
    visibleItems.includes("Jared Renfroe Rims")
  );
  
  let jaredRenfroeFLWheel = useGLBLoader(
    FourDoorWranglerParts.Wheels.JaredRenfroeFLWheel,
    visibleItems.includes("Jared Renfroe Rims")
  );
  
  let jaredRenfroeFRWheel = useGLBLoader(
    FourDoorWranglerParts.Wheels.JaredRenfroeFRWheel,
    visibleItems.includes("Jared Renfroe Rims")
  );

  let jasonMartino5thWheel = useGLBLoader(
    FourDoorWranglerParts.Wheels.JasonMartino5thWheel,
    visibleItems.includes("Jason Martino Rims")
  );
  
  let jasonMartinoBLWheel = useGLBLoader(
    FourDoorWranglerParts.Wheels.JasonMartinoBLWheel,
    visibleItems.includes("Jason Martino Rims")
  );
  
  let jasonMartinoBRWheel = useGLBLoader(
    FourDoorWranglerParts.Wheels.JasonMartinoBRWheel,
    visibleItems.includes("Jason Martino Rims")
  );
  
  let jasonMartinoFLWheel = useGLBLoader(
    FourDoorWranglerParts.Wheels.JasonMartinoFLWheel,
    visibleItems.includes("Jason Martino Rims")
  );
  
  let jasonMartinoFRWheel = useGLBLoader(
    FourDoorWranglerParts.Wheels.JasonMartinoFRWheel,
    visibleItems.includes("Jason Martino Rims")
  );

  let jonathenMclaren5thWheel = useGLBLoader(
    FourDoorWranglerParts.Wheels.JonathenMclaren5thWheel,
    visibleItems.includes("Jonathen Mclaren Rims")
  );
  
  let jonathenMclarenBLWheel = useGLBLoader(
    FourDoorWranglerParts.Wheels.JonathenMclarenBLWheel,
    visibleItems.includes("Jonathen Mclaren Rims")
  );
  
  let jonathenMclarenBRWheel = useGLBLoader(
    FourDoorWranglerParts.Wheels.JonathenMclarenBRWheel,
    visibleItems.includes("Jonathen Mclaren Rims")
  );
  
  let jonathenMclarenFLWheel = useGLBLoader(
    FourDoorWranglerParts.Wheels.JonathenMclarenFLWheel,
    visibleItems.includes("Jonathen Mclaren Rims")
  );
  
  let jonathenMclarenFRWheel = useGLBLoader(
    FourDoorWranglerParts.Wheels.JonathenMclarenFRWheel,
    visibleItems.includes("Jonathen Mclaren Rims")
  );
  
  let joyGarner5thWheel = useGLBLoader(
    FourDoorWranglerParts.Wheels.JoyGarner5thWheel,
    visibleItems.includes("Joy Garner Rims")
  );
  
  let joyGarnerBLWheel = useGLBLoader(
    FourDoorWranglerParts.Wheels.JoyGarnerBLWheel,
    visibleItems.includes("Joy Garner Rims")
  );
  
  let joyGarnerBRWheel = useGLBLoader(
    FourDoorWranglerParts.Wheels.JoyGarnerBRWheel,
    visibleItems.includes("Joy Garner Rims")
  );
  
  let joyGarnerFLWheel = useGLBLoader(
    FourDoorWranglerParts.Wheels.JoyGarnerFLWheel,
    visibleItems.includes("Joy Garner Rims")
  );
  
  let joyGarnerFRWheel = useGLBLoader(
    FourDoorWranglerParts.Wheels.JoyGarnerFRWheel,
    visibleItems.includes("Joy Garner Rims")
  );

  let kevinKan5thWheel = useGLBLoader(
    FourDoorWranglerParts.Wheels.KevinKan5thWheel,
    visibleItems.includes("Kevin Kan Rims")
  );
  
  let kevinKanBLWheel = useGLBLoader(
    FourDoorWranglerParts.Wheels.KevinKanBLWheel,
    visibleItems.includes("Kevin Kan Rims")
  );
  
  let kevinKanBRWheel = useGLBLoader(
    FourDoorWranglerParts.Wheels.KevinKanBRWheel,
    visibleItems.includes("Kevin Kan Rims")
  );
  
  let kevinKanFLWheel = useGLBLoader(
    FourDoorWranglerParts.Wheels.KevinKanFLWheel,
    visibleItems.includes("Kevin Kan Rims")
  );
  
  let kevinKanFRWheel = useGLBLoader(
    FourDoorWranglerParts.Wheels.KevinKanFRWheel,
    visibleItems.includes("Kevin Kan Rims")
  );

  let markJHanscom5thWheel = useGLBLoader(
    FourDoorWranglerParts.Wheels.MarkJHanscom5thWheel,
    visibleItems.includes("Mark J Hanscom Rims")
  );
  
  let markJHanscomBLWheel = useGLBLoader(
    FourDoorWranglerParts.Wheels.MarkJHanscomBLWheel,
    visibleItems.includes("Mark J Hanscom Rims")
  );
  
  let markJHanscomBRWheel = useGLBLoader(
    FourDoorWranglerParts.Wheels.MarkJHanscomBRWheel,
    visibleItems.includes("Mark J Hanscom Rims")
  );
  
  let markJHanscomFLWheel = useGLBLoader(
    FourDoorWranglerParts.Wheels.MarkJHanscomFLWheel,
    visibleItems.includes("Mark J Hanscom Rims")
  );
  
  let markJHanscomFRWheel = useGLBLoader(
    FourDoorWranglerParts.Wheels.MarkJHanscomFRWheel,
    visibleItems.includes("Mark J Hanscom Rims")
  );
  
  let markJHanscomWheels = [
    markJHanscom5thWheel,
    markJHanscomBLWheel,
    markJHanscomBRWheel,
    markJHanscomFLWheel,
    markJHanscomFRWheel,
  ];
  
  
  let kevinKanWheels = [
    kevinKan5thWheel,
    kevinKanBLWheel,
    kevinKanBRWheel,
    kevinKanFLWheel,
    kevinKanFRWheel,
  ];
  
  
  let joyGarnerWheels = [
    joyGarner5thWheel,
    joyGarnerBLWheel,
    joyGarnerBRWheel,
    joyGarnerFLWheel,
    joyGarnerFRWheel,
  ];
  
  let jonathenMclarenWheels = [
    jonathenMclaren5thWheel,
    jonathenMclarenBLWheel,
    jonathenMclarenBRWheel,
    jonathenMclarenFLWheel,
    jonathenMclarenFRWheel,
  ];
  
  
  let jasonMartinoWheels = [
    jasonMartino5thWheel,
    jasonMartinoBLWheel,
    jasonMartinoBRWheel,
    jasonMartinoFLWheel,
    jasonMartinoFRWheel,
  ];
  
  let jaredRenfroeWheels = [
    jaredRenfroe5thWheel,
    jaredRenfroeBLWheel,
    jaredRenfroeBRWheel,
    jaredRenfroeFLWheel,
    jaredRenfroeFRWheel,
  ];
  
  
  let glenJonesWheels = [
    glenJones5thWheel,
    glenJonesBLWheel,
    glenJonesBRWheel,
    glenJonesFLWheel,
    glenJonesFRWheel,
  ];

  let dustinHemauerWheels = [
    dustinHemauer5thWheel,
    dustinHemauerFLWheel,
    dustinHemauerFRWheel,
    dustinHemauerBLWheel,
    dustinHemauerBRWheel,
  ];
  
  
  let davidShaferWheels = [
    davidShafer5thWheel,
    davidShaferBLWheel,
    davidShaferBRWheel,
    davidShaferFLWheel,
    davidShaferFRWheel,
  ];
  
  
  let darleneMosconeWheels = [
    darleneMoscone5thWheel,
    darleneMosconeBLWheel,
    darleneMosconeBRWheel,
    darleneMosconeFLWheel,
    darleneMosconeFRWheel,
  ];
  
  
  let daniColemanWheels = [
    daniColeman5thWheel,
    daniColemanBLWheel,
    daniColemanBRWheel,
    daniColemanFLWheel,
    daniColemanFRWheel,
  ];

  let coreyJonesWheels = [
    coreyJones5thWheel,
    coreyJonesBLWheel,
    coreyJonesBRWheel,
    coreyJonesFLWheel,
    coreyJonesFRWheel,
  ];
  
  
  let aaronGordonWheels = [
    aaronGordon5thWheel,
    aaronGordonLBWheel,
    aaronGordonLFWheel,
    aaronGordonRBWheel,
    aaronGordonRFWheel,
  ];

  let arkonWheels = [
    arkon5thWheel,
    arkonLBWheel,
    arkonLFWheel,
    arkonRBWheel,
    arkonRFWheel,
  ];

  let bethanySmeltzerWheels = [
    bethanySmeltzer5thWheel,
    bethanySmeltzerLBWheel,
    bethanySmeltzerLFWheel,
    bethanySmeltzerRBWheel,
    bethanySmeltzerRFWheel,
  ];

  let billHoughtonWheels = [
    billHoughton5thWheel,
    billHoughtonLBWheel,
    billHoughtonLFWheel,
    billHoughtonRBWheel,
    billHoughtonRFWheel,
  ];

  let blackRhinoWheels = [
    blackRhino5thWheel,
    blackRhinoLBWheel,
    blackRhinoLFWheel,
    blackRhinoRBWheel,
    blackRhinoRFWheel,
  ];

  let blitzWheels = [
    blitzL5thWheel,
    blitzLBWheel,
    blitzLFWheel,
    blitzRBWheel,
    blitzRFWheel,
  ];

  let braydonWheels = [
    braydon5thWheel,
    braydonLBWheel,
    braydonLFWheel,
    braydonRBWheel,
    braydonRFWheel,
  ];

  let callieLemmingWheels = [
    callieLemming5thWheel,
    callieLemmingLBWheel,
    callieLemmingLFWheel,
    callieLemmingRBWheel,
    callieLemmingRFWheel,
  ];

  let claireGaretteWheels = [
    claireGarette5thWheel,
    claireGaretteLBWheel,
    claireGaretteLFWheel,
    claireGaretteRBWheel,
    claireGaretteRFWheel,
  ];

  let wesleyRichardsonWheels = [
    wesleyRichardson5thWheel,
    wesleyRichardsonLBWheel,
    wesleyRichardsonLFWheel,
    wesleyRichardsonRBWheel,
    wesleyRichardsonRFWheel,
  ];

  let thomasLopezWheels = [
    thomasLopez5thWheel,
    thomasLopezLBWheel,
    thomasLopezLFWheel,
    thomasLopezRBWheel,
    thomasLopezRFWheel,
  ];

  let reganFlemingWheels = [
    reganFleming5thWheel,
    reganFlemingLBWheel,
    reganFlemingLFWheel,
    reganFlemingRBWheel,
    reganFlemingRFWheel,
  ];

  let marissaShepherdWheels = [
    marissaShepherd5thWheel,
    marissaShepherdLBWheel,
    marissaShepherdLFWheel,
    marissaShepherdRBWheel,
    marissaShepherdRFWheel,
  ];

  let kevinWheels = [
    kevin5thWheel,
    kevinLBWheel,
    kevinLFWheel,
    kevinRBWheel,
    kevinRFWheel,
  ];

  let jaxonHarrisWheels = [
    jaxonHarris5thWheel,
    jaxonHarrisLBWheel,
    jaxonHarrisLFWheel,
    jaxonHarrisRBWheel,
    jaxonHarrisRFWheel,
  ];

  let evaPerrymanWheels = [
    evaPerryman5thWheel,
    evaPerrymanLBWheel,
    evaPerrymanLFWheel,
    evaPerrymanRBWheel,
    evaPerrymanRFWheel,
  ];

  let emmaLawrenceWheels = [
    emmaLawrence5thWheel,
    emmaLawrenceLBWheel,
    emmaLawrenceLFWheel,
    emmaLawrenceRBWheel,
    emmaLawrenceRFWheel,
  ];

  let drakeBrowningWheels = [
    drakeBrowning5thWheel,
    drakeBrowningLBWheel,
    drakeBrowningLFWheel,
    drakeBrowningRBWheel,
    drakeBrowningRFWheel,
  ];

  let dominicFaneWheels = [
    dominicFane5thWheel,
    dominicFaneLBWheel,
    dominicFaneLFWheel,
    dominicFaneRBWheel,
    dominicFaneRFWheel,
  ];

  let denimWilsonWheels = [
    denimWilson5thWheel,
    denimWilsonLBWheel,
    denimWilsonLFWheel,
    denimWilsonRBWheel,
    denimWilsonRFWheel,
  ];

  let corinneOsborneWheels = [
    corinneOsborne5thWheel,
    corinneOsborneLBWheel,
    corinneOsborneLFWheel,
    corinneOsborneRBWheel,
    corinneOsborneRFWheel,
  ];

  let colterBahlauWheels = [
    colterBahlau5thWheel,
    colterBahlauLBWheel,
    colterBahlauLFWheel,
    colterBahlauRBWheel,
    colterBahlauRFWheel,
  ];

  const rimsArray = [
    { name: "Aaron Gordon Rims", models: aaronGordonWheels },
    { name: "Arkon Rims", models: arkonWheels },
    { name: "Bethany Smeltzer Rims", models: bethanySmeltzerWheels },
    { name: "Bill Houghton Rims", models: billHoughtonWheels },
    { name: "Black Rhino Rims", models: blackRhinoWheels },
    { name: "Blitz Rims", models: blitzWheels },
    { name: "Braydon Rims", models: braydonWheels },
    { name: "Callie Lemming Rims", models: callieLemmingWheels },
    { name: "Claire Garette Rims", models: claireGaretteWheels },
    { name: "Wesley Richardson Rims", models: wesleyRichardsonWheels },
    { name: "Thomas Lopez Rims", models: thomasLopezWheels },
    { name: "Regan Fleming Rims", models: reganFlemingWheels },
    { name: "Marissa Shepherd Rims", models: marissaShepherdWheels },
    { name: "Kevin Rims", models: kevinWheels },
    { name: "Jaxon Harris Rims", models: jaxonHarrisWheels },
    { name: "Eva Perryman Rims", models: evaPerrymanWheels },
    { name: "Emma Lawrence Rims", models: emmaLawrenceWheels },
    { name: "Drake Browning Rims", models: drakeBrowningWheels },
    { name: "Dominic Fane Rims", models: dominicFaneWheels },
    { name: "Denim Wilson Rims", models: denimWilsonWheels },
    { name: "Corinne Osborne Rims", models: corinneOsborneWheels },
    { name: "Colter Bahlau Rims", models: colterBahlauWheels },
    { name: "Corey Jones Rims", models: coreyJonesWheels },
    { name: "Mark J Hanscom Rims", models: markJHanscomWheels },
    { name: "Kevin Kan Rims", models: kevinKanWheels },
    { name: "Joy Garner Rims", models: joyGarnerWheels },
    { name: "Jonathen Mclaren Rims", models: jonathenMclarenWheels },
    { name: "Jason Martino Rims", models: jasonMartinoWheels },
    { name: "Jared Renfroe Rims", models: jaredRenfroeWheels },
    { name: "Glen Jones Rims", models: glenJonesWheels },
    { name: "Dustin Hemauer Rims", models: dustinHemauerWheels },
    { name: "David Shafer Rims", models: davidShaferWheels },
    { name: "Darlene Moscone Rims", models: darleneMosconeWheels },
    { name: "Dani Coleman Rims", models: daniColemanWheels },
  ];
  
  return {
    rimsArray
  };
};

export default useWheelsContainer;

