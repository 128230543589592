import { Canvas } from "@react-three/fiber";
import { BakeShadows, OrbitControls, Stage } from "@react-three/drei";
import Vehicle from "./VehicleHome";

function ModelRenderer({
  grillIndex,
  grillColor,
  bumperColor,
  bumperIndex,
  visibleItems,
}) {
  return (
    <Canvas shadows camera={{ position: [-120, 50, 150], fov: 40 }}>
      <Stage environment="city" intensity={0.7}>
        <Vehicle
          position={[0, 0, 0]}
          grillIndex={grillIndex}
          grillColor={grillColor}
          bumperColor={bumperColor}
          bumperIndex={bumperIndex}
          visibleItems={visibleItems}
        />
      </Stage>
      <BakeShadows />
      <OrbitControls makeDefault />
    </Canvas>
  );
}

export default ModelRenderer;
