import React from "react";
import { Button } from "@mui/material";
import { Delete } from "@mui/icons-material";

const PriceDetail = ({ visibleItemsArray, handleAddParts }) => {
  return (
    <div className="price-detail-container">
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          paddingRight: 10,
        }}
      >
        <p className="price-detail-heading Akira-Expanded">PRICE DETAIL</p>
        <div className="product-detail-header-divider"></div>
      </div>

      <table className="cart-table-container">
        <thead className="cart-table-head">
          <tr>
            <th className="cart-table-column cart-table-main-title Akira-Expanded">
              ITEMS
            </th>
            <th className="cart-table-column cart-table-main-title Akira-Expanded">
              PRICING
            </th>
          </tr>
        </thead>
        <tbody>
          {visibleItemsArray.map((item) => (
            <tr key={item.name}>
              <td className="cart-table-body-column">
                <img className="cart-image" src={item.image} alt={item.name} />
              </td>
              <td className="cart-table-body-column cart-table-description">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <p className="cart-table-price">{"$" + item.price}</p>
                  <Button
                    onClick={() => handleAddParts(item.name)}
                    className="main-button cart-table-button"
                  >
                    <Delete />
                  </Button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default PriceDetail;
