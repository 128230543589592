import React, { useState, useEffect, useRef } from "react";
import { Button, Divider, Grid } from "@mui/material";
import { LocalMall, Star, Favorite } from "@mui/icons-material";
// import Slider from "@mui/material/Slider";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Get_Product_By_Id } from "../Utilities/Products";
import "../App.css";

import featureImage1 from "../assets/images/featureImage1.png";
import featureImage2 from "../assets/images/featureImage2.png";
import featureImage3 from "../assets/images/featureImage3.png";
import gladiator from "../assets/images/gladiator.png";

const ProductDetails = () => {
  const location = useLocation();
  const navigation = useNavigate();

  // const [products, setProducts] = useState([
  //   { name: "DONEC COMMANDO", imageIndex: 1 },
  //   { name: "DONEC COMMANDO", imageIndex: 2 },
  //   { name: "DONEC COMMANDO", imageIndex: 3 },
  // ]);

  const [productDetails, setProductDetails] = useState(null);
  // const [productPartsDetails, setProductPartsDetails] = useState([]);

  const params = new URLSearchParams(location.search).get("q");

  useEffect(() => {
    window.scrollTo(0, 0);
    setProductDetails(null);
    initialData();
  }, [params]);

  const initialData = async () => {
    try {
      setProductDetails(null);
      if (params !== undefined && params !== null) {
        if (params === "0001") {
          setProductDetails({
            _id: "0001",
            name: "4D Wrangler",
            description:
              "Your custom Jeep will be custom designed, 3D Printed, assembled and shipped in a 4-5 week time frame. You will be receiving regular updates via the email provided at check out. We look forward to turning your Jeep into a MY3DJEEP!",
            titleImageURL: featureImage1,
            price: 120,
            is3D: true,
          });
        } else if (params === "0002") {
          setProductDetails({
            _id: "0002",
            name: "4D Gladiator",
            description:
              "Your custom Jeep will be custom designed, 3D Printed, assembled and shipped in a 4-5 week time frame. You will be receiving regular updates via the email provided at check out. We look forward to turning your Jeep into a MY3DJEEP!",
            titleImageURL: gladiator,
            price: 140,
            is3D: true,
          });
        } else if (params === "0003") {
          setProductDetails({
            _id: "0003",
            name: "2D Wrangler",
            description:
              "Your custom Jeep will be custom designed, 3D Printed, assembled and shipped in a 4-5 week time frame. You will be receiving regular updates via the email provided at check out. We look forward to turning your Jeep into a MY3DJEEP!",
            titleImageURL: featureImage2,
            price: 100,
            is3D: true,
          });
        } else {
          const resp = await Get_Product_By_Id(params);
          if (resp.Status_Code === 200 && resp.IsSuccess === true) {
            setProductDetails(resp.Data);
          }
        }
        // const response = await Get_Product_By_Id(params);
        // if (response.Status_Code === 200 && response.IsSuccess === true) {
        //   setProductDetails(response.Data);
        //   const parts = [];
        //   response.Data.productParts.map((item, index) => {
        //     let body = {
        //       New_ProductPart_Id: index + 1,
        //       _id: item._id,
        //       ProductPart_Title: item.title,
        //       ProductPart_Image: {
        //         previewUrl: item.titleImageURL,
        //         fileType: "image/",
        //       },
        //       ProductPart_Model: item.ModelURL,
        //     };
        //     parts.push(body);
        //   });
        //   setProductPartsDetails(parts);
        // }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleAddToCart = async (product) => {
    let currentCart = [];
    const cart = localStorage.getItem("Cart");
    if (cart) {
      currentCart = JSON.parse(cart);
    }

    const index = currentCart.findIndex((item) => {
      return item.id === product._id;
    });
    if (index !== -1) {
      const filtered = currentCart.filter((item) => {
        return item.id === product._id;
      });
      currentCart.splice(index, 1);
      currentCart.push({
        id: filtered[0].id,
        productName: filtered[0].productName,
        productDescription: filtered[0].productDescription,
        productImage: filtered[0].productImage,
        productPrice: filtered[0].productPrice,
        Quantity: filtered[0].Quantity + 1,
        vehicleParts: filtered[0].vehicleParts,
      });
    } else {
      currentCart.push({
        id: product._id,
        productName: product.name,
        productDescription: product.description,
        productImage: product.titleImageURL,
        productPrice: product.price,
        Quantity: 1,
        vehicleParts: [],
      });
    }

    localStorage.removeItem("Cart");
    localStorage.setItem("Cart", JSON.stringify(currentCart));
    navigation("/cart");
  };

  const handleAddToWishlist = async (product) => {
    let currentWishList = [];
    const wishList = localStorage.getItem("Wishlist");
    if (wishList) {
      currentWishList = JSON.parse(wishList);
    }

    const index = currentWishList.findIndex((item) => {
      return item.id === product._id;
    });
    if (index === -1) {
      currentWishList.push({
        id: product._id,
        productName: product.name,
        productDescription: product.description,
        productImage: product.titleImageURL,
        productPrice: product.price,
        Quantity: 1,
        vehicleParts: [],
      });
    }

    localStorage.removeItem("Wishlist");
    localStorage.setItem("Wishlist", JSON.stringify(currentWishList));
    navigation("/wishlist");
  };

  return (
    <div>
      <div className="product-detail-hero-container">
        <div className="product-detail-hero">
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              color: "#F5C61F",
            }}
          >
            <Star />
            <Star />
            <Star />
            <Star />
            <Star />
            <p style={{ margin: "0px 10px" }}>Happy Review</p>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              color: "white",
            }}
          >
            <p className="product-detail-title  Akira-Expanded">
              {productDetails !== null ? productDetails.name.toUpperCase() : ""}
            </p>
            <div className="product-detail-shop-button">
              <button
                className="main-button"
                onClick={(e) =>
                  handleAddToWishlist({
                    _id: params,
                    name: productDetails !== null ? productDetails.name : "",
                    description:
                      productDetails !== null ? productDetails.description : "",
                    titleImageURL:
                      productDetails !== null
                        ? productDetails.titleImageURL
                        : "",
                    price: productDetails !== null ? productDetails.price : 0,
                  })
                }
                style={{
                  margin: "0px 3px",
                  color: "white",
                  height: 50,
                  width: 150,
                }}
              >
                Add To WishList
              </button>

              {/* <Button
                style={{ width: 150, height: 50, borderRadius: 0 }}
                className="main-button"
              >
                EXTERIOR
              </Button> */}
            </div>
          </div>
        </div>

        <div className="shop-detail-image-container">
          <img
            src={productDetails !== null ? productDetails.titleImageURL : ""}
            alt=""
            style={{ width: "70%", margin: "40px 0px" }}
          />
        </div>
        <div className="shop-detail-container">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              paddingRight: 20,
            }}
          >
            <p className="product-detail-heading  Akira-Expanded">
              PRODUCT DETAIL
            </p>
            <div className="product-detail-header-divider"></div>
          </div>

          <div style={{ textAlign: "left", padding: "0px 20px" }}>
            <div className="about-vision-text">
              <p style={{ margin: "10px 10px" }}>
                {productDetails !== null ? productDetails.description : ""}
              </p>
            </div>
            {/*<div className="about-vision-text" style={{ marginTop: 30 }}>
              <div className="product-detail-bullet"></div>
              <p style={{ margin: "-10px 10px" }}>
                Vestibulum tellus orci, aliquam a elit at, rhoncus dictum
                libero. Mauris cursus erat nec odio gravida finibus. Nullmauris
                id lacus vestibulum consequat consequat at augue.
              </p>
            </div>
            <div className="about-vision-text" style={{ marginTop: 30 }}>
              <div className="product-detail-bullet"></div>
              <p style={{ margin: "-10px 10px" }}>
                Vestibulum tellus orci, aliquam a elit at, rhoncus dictum
                libero. Mauris cursus erat nec odio gravida finibus. Nullmauris
                id lacus vestibulum consequat consequat at augue.
              </p>
            </div>
            <div className="about-vision-text" style={{ marginTop: 30 }}>
              <div className="product-detail-bullet"></div>
              <p style={{ margin: "-10px 10px" }}>
                Vestibulum tellus orci, aliquam a elit at, rhoncus dictum
                libero. Mauris cursus erat nec odio gravida finibus. Nullmauris
                id lacus vestibulum consequat consequat at augue.
              </p>
          </div>*/}

            <div
              style={{
                display: "flex",
                alignItems: "center",
                margin: "20px 0px",
              }}
            >
              <div className="product-detail-shop-button">
                <div style={{ margin: "0px 10px" }}>
                  <p className="product-price-text" style={{ color: "#000" }}>
                    Vehicle Price
                  </p>
                  <p className="product-price-number">
                    {productDetails !== null
                      ? "$ " + productDetails.price.toLocaleString()
                      : "$ 0"}
                  </p>
                </div>
                <Button
                  style={{
                    width: 230,
                    height: 50,
                    borderRadius: 0,
                    margin: "2px 5px",
                  }}
                  className="main-button"
                  onClick={(e) =>
                    handleAddToCart({
                      _id: productDetails !== null ? productDetails._id : "",
                      name: productDetails !== null ? productDetails.name : "",
                      description:
                        productDetails !== null
                          ? productDetails.description
                          : "",
                      titleImageURL: productDetails.titleImageURL,
                      price: productDetails !== null ? productDetails.price : 0,
                    })
                  }
                >
                  Add To Cart
                  <LocalMall
                    style={{
                      margin: "0px 10px",
                      color: "#ffffff",
                      fontSize: "28px",
                    }}
                  />
                </Button>
                {productDetails !== null && productDetails.is3D && (
                  <Link
                    onClick={(e) => {
                      localStorage.removeItem("modelParts");
                    }}
                    to={
                      productDetails !== null
                        ? "/build-your-own?q=" + productDetails._id
                        : "/build-your-own?q="
                    }
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    <Button
                      style={{
                        width: 230,
                        height: 50,
                        borderRadius: 0,
                        margin: "2px 5px",
                      }}
                      className="main-button"
                    >
                      Build Buy Your Own
                    </Button>
                  </Link>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* SHOP NOW */}
      <div
        style={{
          textAlign: "center",
          marginTop: "100px",
          marginBottom: "100px",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div className="divider-hero"></div>
          <p
            style={{
              fontSize: "36px",
              lineHeight: "52px",
              marginLeft: 20,
            }}
            className=" Akira-Expanded"
          >
            RELATED PRODUCTS
          </p>
          <div className="divider-hero"></div>
        </div>

        <Grid
          container
          spacing={2}
          style={{
            width: "90%",
            margin: "auto",
            marginTop: "50px",
          }}
        >
          {/* {products.map((item) => {
            return (
              <Grid item md={4}>
                <div className="shop-card-container">
                  <div className="shop-card-image-container">
                    <img
                      src={
                        item.imageIndex == 1
                          ? jeep1
                          : item.imageIndex == 2
                          ? jeep2
                          : jeep3
                      }
                      alt=""
                      style={{ width: "80%" }}
                    />
                  </div>
                  <div className="shop-card-details">
                    <Link
                      to="/product-details"
                      style={{ textDecoration: "none", color: "black" }}
                    >
                      <p className="shop-card-details-heading  Akira-Expanded">
                        {item.name}
                      </p>
                    </Link>
                    <p className="shop-card-details-description">
                      Lorem ipsum dolor sit amet, consectetur elit. Sed
                      malesuada magna at leo scelerisque molestie.
                    </p>
                    <p className="shop-card-details-price">$ 100</p>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Button className="main-button">
                        Build Buy Your Own
                      </Button>
                      <LocalMall
                        style={{
                          marginLeft: "10px",
                          color: "#FF3132",
                          fontSize: "36px",
                        }}
                      />
                    </div>
                  </div>
                </div>
              </Grid>
            );
          })} */}
          {params !== "0001" && (
            <Grid item md={4}>
              <div className="shop-card-container">
                <div className="shop-card-image-container">
                  <img src={featureImage1} alt="" style={{ width: "80%" }} />
                </div>
                <div className="shop-card-details">
                  <Link
                    to={"/product-details?q=0001"}
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    <p className="shop-card-details-heading">{"4D Wrangler"}</p>
                  </Link>
                  <p className="shop-card-details-description">
                    {
                      "Your custom Jeep will be custom designed, 3D Printed, assembled and shipped in a 4-5 week time frame."
                    }
                  </p>
                  <p className="shop-card-details-price">{"$ 120"}</p>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Link
                      onClick={(e) => {
                        localStorage.removeItem("modelParts");
                      }}
                      to="/build-your-own?q=0001"
                      style={{ textDecoration: "none", color: "black" }}
                    >
                      <Button className="main-button">
                        Build Buy Your Own
                      </Button>
                    </Link>
                    <button
                      style={{ marginLeft: "10px", border: "none" }}
                      onClick={(e) =>
                        handleAddToCart({
                          _id: "0001",
                          name: "4D Wrangler",
                          description:
                            "Your custom Jeep will be custom designed, 3D Printed, assembled and shipped in a 4-5 week time frame.",
                          titleImageURL: featureImage1,
                          price: 100,
                        })
                      }
                    >
                      <LocalMall
                        style={{
                          color: "#FF3132",
                          fontSize: "36px",
                        }}
                      />
                    </button>
                    <button
                      style={{ marginLeft: "5px", border: "none" }}
                      onClick={(e) =>
                        handleAddToWishlist({
                          _id: "0001",
                          name: "4D Wrangler",
                          description:
                            "Your custom Jeep will be custom designed, 3D Printed, assembled and shipped in a 4-5 week time frame.",
                          titleImageURL: featureImage1,
                          price: 100,
                        })
                      }
                    >
                      <Favorite
                        style={{
                          color: "#FF3132",
                          fontSize: "36px",
                        }}
                      />
                    </button>
                  </div>
                </div>
              </div>
            </Grid>
          )}

          {params !== "0003" && (
            <Grid item md={4}>
              <div className="shop-card-container">
                <div className="shop-card-image-container">
                  <img src={featureImage2} alt="" style={{ width: "80%" }} />
                </div>
                <div className="shop-card-details">
                  <Link
                    to={"/product-details?q=0003"}
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    <p className="shop-card-details-heading">{"2D Wrangler"}</p>
                  </Link>
                  <p className="shop-card-details-description">
                    {
                      "Your custom Jeep will be custom designed, 3D Printed, assembled and shipped in a 4-5 week time frame."
                    }
                  </p>
                  <p className="shop-card-details-price">{"$ 120"}</p>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Link
                      onClick={(e) => {
                        localStorage.removeItem("modelParts");
                      }}
                      to="/build-your-own?q=0003"
                      style={{ textDecoration: "none", color: "black" }}
                    >
                      <Button className="main-button">
                        Build Buy Your Own
                      </Button>
                    </Link>
                    <button
                      style={{ marginLeft: "10px", border: "none" }}
                      onClick={(e) =>
                        handleAddToCart({
                          _id: "0003",
                          name: "2D Wrangler",
                          description:
                            "Your custom Jeep will be custom designed, 3D Printed, assembled and shipped in a 4-5 week time frame.",
                          titleImageURL: featureImage1,
                          price: 100,
                        })
                      }
                    >
                      <LocalMall
                        style={{
                          color: "#FF3132",
                          fontSize: "36px",
                        }}
                      />
                    </button>
                    <button
                      style={{ marginLeft: "5px", border: "none" }}
                      onClick={(e) =>
                        handleAddToWishlist({
                          _id: "0003",
                          name: "2D Wrangler",
                          description:
                            "Your custom Jeep will be custom designed, 3D Printed, assembled and shipped in a 4-5 week time frame.",
                          titleImageURL: featureImage1,
                          price: 100,
                        })
                      }
                    >
                      <Favorite
                        style={{
                          color: "#FF3132",
                          fontSize: "36px",
                        }}
                      />
                    </button>
                  </div>
                </div>
              </div>
            </Grid>
          )}

          {params !== "0002" && (
            <Grid item md={4}>
              <div className="shop-card-container">
                <div className="shop-card-image-container">
                  <img src={gladiator} alt="" style={{ width: "80%" }} />
                </div>
                <div className="shop-card-details">
                  <Link
                    onClick={(e) => {
                      localStorage.removeItem("modelParts");
                    }}
                    to={"/product-details?q=0002"}
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    <p className="shop-card-details-heading">
                      {"4D Gladiator"}
                    </p>
                  </Link>
                  <p className="shop-card-details-description">
                    {
                      "Your custom Jeep will be custom designed, 3D Printed, assembled and shipped in a 4-5 week time frame."
                    }
                  </p>
                  <p className="shop-card-details-price">{"$ 140"}</p>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Link
                      to="/build-your-own?q=0002"
                      style={{ textDecoration: "none", color: "black" }}
                    >
                      <Button className="main-button">
                        Build Buy Your Own
                      </Button>
                    </Link>
                    <button
                      style={{ marginLeft: "10px", border: "none" }}
                      onClick={(e) =>
                        handleAddToCart({
                          _id: "0002",
                          name: "4D Gladiator",
                          description:
                            "Your custom Jeep will be custom designed, 3D Printed, assembled and shipped in a 4-5 week time frame.",
                          titleImageURL: featureImage1,
                          price: 120,
                        })
                      }
                    >
                      <LocalMall
                        style={{
                          color: "#FF3132",
                          fontSize: "36px",
                        }}
                      />
                    </button>
                    <button
                      style={{ marginLeft: "5px", border: "none" }}
                      onClick={(e) =>
                        handleAddToWishlist({
                          _id: "0002",
                          name: "4D Gladiator",
                          description:
                            "Your custom Jeep will be custom designed, 3D Printed, assembled and shipped in a 4-5 week time frame.",
                          titleImageURL: featureImage1,
                          price: 120,
                        })
                      }
                    >
                      <Favorite
                        style={{
                          color: "#FF3132",
                          fontSize: "36px",
                        }}
                      />
                    </button>
                  </div>
                </div>
              </div>
            </Grid>
          )}
        </Grid>
      </div>

      <div className="mid-banner-2">
        <div>
          <p
            style={{
              fontSize: "18px",
            }}
            className="Montserrat-Regular"
          >
            DRIVE BEYOND BOUNDARIES
          </p>
        </div>
        <p
          style={{
            fontSize: "36px",
            lineHeight: "52px",
            textAlign: "center",
          }}
          className="Akira-Expanded"
        >
          CUSTOMIZE DESIGN
        </p>
        <Link
          to="/build-your-own?q=0001"
          style={{ textDecoration: "none", color: "black" }}
        >
          <Button className="main-button">Customize Your Jeep</Button>
        </Link>
      </div>
    </div>
  );
};

export default ProductDetails;
