import React, { useState, useEffect, useRef } from "react";
import { Button, Divider, Grid } from "@mui/material";
import {
  LocalMall,
  Favorite,
  ArrowBack,
  ArrowForward,
  Search,
  ArrowForwardIosTwoTone,
} from "@mui/icons-material";
import Slider from "@mui/material/Slider";
import { Link, useNavigate } from "react-router-dom";
import { Get_Product } from "../Utilities/Products";
import { getPrintifyProdcuts } from "../Utilities/Printify";

// import Banner from "../assets/svg/cover phone.svg";
import homeHero from "../assets/svg/homePageHero.svg";
import midBanner1 from "../assets/svg//mid-banner-1.svg";

import featureImage1 from "../assets/images/featureImage1.png";
import featureImage2 from "../assets/images/featureImage2.png";
import featureImage3 from "../assets/images/featureImage3.png";
import gladiator from "../assets/images/gladiator.png";

const Shop = () => {
  const navigation = useNavigate();

  const [value, setValue] = React.useState([30, 200]);
  const [page, setPage] = React.useState(0);
  const [maxPage, setmaxPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(999);
  const [JeepList, setJeepList] = React.useState([]);
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = React.useState([]);
  const [PrintifyProducts, setPrintifyProducts] = React.useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setProducts([]);
    initialData();
  }, []);

  const getPrintifyProducts = async () => {
    let array = products;
    const resp = await getPrintifyProdcuts();
    if (resp.Status_Code === 200 && resp.IsSuccess === true) {
      setPrintifyProducts(resp.Data.data);
      resp.Data.data.map((product) => {
        array.push({
          _id: product.id,
          productName: product.title,
          description: "",
          price: "",
          titleImageURL: product.images[0] && product.images[0].src,
          is3D: false,
        });
      });
      setFilteredProducts(array);
      setProducts(array);
    }
  };

  const initialData = async () => {
    const array = [];
    array.push({
      _id: "0001",
      productName: "4D Wrangler",
      description:
        "Your custom Jeep will be custom designed, 3D Printed, assembled and shipped in a 4-5 week time frame. You will be receiving regular updates via the email provided at check out. We look forward to turning your Jeep into a MY3DJEEP!",
      price: 120,
      titleImageURL: featureImage1,
      is3D: true,
    });
    array.push({
      _id: "0002",
      productName: "4D Gladiator",
      description:
        "Your custom Jeep will be custom designed, 3D Printed, assembled and shipped in a 4-5 week time frame. You will be receiving regular updates via the email provided at check out. We look forward to turning your Jeep into a MY3DJEEP!",
      price: 140,
      titleImageURL: gladiator,
      is3D: true,
    });
    array.push({
      _id: "0003",
      productName: "2D Wrangler",
      description:
        "Your custom Jeep will be custom designed, 3D Printed, assembled and shipped in a 4-5 week time frame. You will be receiving regular updates via the email provided at check out. We look forward to turning your Jeep into a MY3DJEEP!",
      price: 100,
      titleImageURL: featureImage1,
      is3D: true,
    });
    const resp = await Get_Product();
    if (resp.Status_Code === 200 && resp.IsSuccess === true) {
      resp.Data.map((product) => {
        array.push({
          _id: product._id,
          productName: product.name,
          description: product.description,
          price: product.price,
          titleImageURL: product.titleImageURL,
          is3D: product.Is3D,
        });
      });
    }

    // const resp2 = await getPrintifyProdcuts();
    // if (resp2.Status_Code === 200 && resp2.IsSuccess === true) {
    //   setPrintifyProducts(resp2.Data.data);
    //   resp2.Data.data.map((product) => {
    //     array.push({
    //       _id: product.id,
    //       productName: product.title,
    //       description: "",
    //       price: "",
    //       titleImageURL: product.images[0] && product.images[0].src,
    //       is3D: false,
    //     });
    //   });
    // }

    setmaxPage(array.length);
    setFilteredProducts(array);
    setProducts(array);
    setJeepList(array);
  };

  function valuetext(value) {
    return `${value}°C`;
  }

  const getFilteredProducts = async () => {
    const filtered = await JeepList.filter((item) => {
      return item.price >= value[0] && item.price <= value[1];
    });
    setFilteredProducts(filtered);
  };

  useEffect(() => {
    getFilteredProducts();
  }, [value, JeepList]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleAddToCart = async (product) => {
    let currentCart = [];
    const cart = localStorage.getItem("Cart");
    if (cart) {
      currentCart = JSON.parse(cart);
    }

    const index = currentCart.findIndex((item) => {
      return item.id === product._id;
    });
    if (index !== -1) {
      const filtered = currentCart.filter((item) => {
        return item.id === product._id;
      });
      currentCart.splice(index, 1);
      currentCart.push({
        id: filtered[0].id,
        productName: filtered[0].productName,
        productDescription: filtered[0].productDescription,
        productImage: filtered[0].productImage,
        productPrice: filtered[0].productPrice,
        Quantity: filtered[0].Quantity + 1,
        vehicleParts: filtered[0].vehicleParts,
      });
    } else {
      currentCart.push({
        id: product._id,
        productName: product.productName,
        productDescription: product.description,
        productImage: product.titleImageURL,
        productPrice: product.price,
        Quantity: 1,
        vehicleParts: [],
      });
    }

    localStorage.removeItem("Cart");
    localStorage.setItem("Cart", JSON.stringify(currentCart));
    navigation("/cart");
  };

  const handleAddToWishlist = async (product) => {
    let currentWishList = [];
    const wishList = localStorage.getItem("Wishlist");
    if (wishList) {
      currentWishList = JSON.parse(wishList);
    }

    const index = currentWishList.findIndex((item) => {
      return item.id === product._id;
    });

    if (index === -1) {
      currentWishList.push({
        id: product._id,
        productName: product.productName,
        productDescription: product.description,
        productImage: product.titleImageURL,
        productPrice: product.price,
        Quantity: 1,
        vehicleParts: [],
      });
    }
    localStorage.removeItem("Wishlist");
    localStorage.setItem("Wishlist", JSON.stringify(currentWishList));
    navigation("/wishlist");
  };

  return (
    <div>
      <div className="shoppage-hero-container">
        <Grid container spacing={2}>
          <Grid
            item
            md={6}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
            }}
          >
            <div
              style={{
                width: "70%",
                color: "white",
              }}
            >
              <p
                style={{
                  fontSize: "44px",
                }}
                className="Akira-Expanded"
              >
                SHOP NOW
              </p>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                }}
              >
                <p
                  style={{
                    fontSize: "22px",
                  }}
                  className="Montserrat-Regular"
                >
                  HOME - SHOP
                </p>
                <div className="divider-hero"></div>
              </div>
            </div>
          </Grid>
          <Grid item md={6} className="hero-section-image">
            <img src={homeHero} alt="" style={{ width: "90%" }} />
          </Grid>
        </Grid>
      </div>

      <div
        style={{
          width: "50%",
          height: 50,
          backgroundColor: "white",
          marginTop: -50,
          borderTopRightRadius: 50,
        }}
      ></div>

      <div className="shop-filter-container">
        <Grid
          container
          spacing={2}
          style={{
            margin: "20px 0px",
          }}
        >
          <Grid md={6} xs={12} item>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                paddingRight: 20,
              }}
            >
              <p className="shop-filter-heading Akira-Expanded">PRODUCTS</p>
              <div className="filter-divider1"></div>
            </div>
            <Grid container spacing={2} style={{ height: 230 }}>
              <Grid md={4} item>
                <Link
                  to={"/product-details?q=0001"}
                  className="shop-filter-options"
                >
                  4D Wrangler
                </Link>
              </Grid>
              <Grid md={4} item>
                <Link
                  to={"/product-details?q=0002"}
                  className="shop-filter-options"
                >
                  4D Gladiator
                </Link>
              </Grid>
              {/* <Grid md={4} item>
                <Button
                  style={{ width: "80%", borderRadius: 0 }}
                  className="main-button"
                >
                  4D Wrangler
                </Button>
              </Grid> */}
              {/* <Grid md={4} item>
                <Button
                  style={{ width: "80%", borderRadius: 0 }}
                  className="main-button"
                >
                  4D Gladiator
                </Button>
              </Grid> */}
            </Grid>
          </Grid>
          <Grid md={6} xs={12} item>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                height: "90%",
              }}
            >
              <div
                style={{
                  height: "60%",
                  paddingRight: "20px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    marginBottom: 20,
                  }}
                >
                  <p className="shop-filter-heading Akira-Expanded">
                    PRICE RANGE
                  </p>
                  <div className="filter-divider2"></div>
                </div>
                <Slider
                  getAriaLabel={() => "Temperature range"}
                  value={value}
                  style={{ color: "#ff3132" }}
                  onChange={handleChange}
                  valueLabelDisplay="on"
                  getAriaValueText={valuetext}
                  max={300}
                  min={10}
                />
              </div>
              <div
                style={{
                  height: "40%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    paddingRight: 20,
                  }}
                >
                  <p className="shop-filter-heading Akira-Expanded">TAGS</p>
                  <div className="filter-divider3"></div>
                </div>
                <Grid
                  container
                  spacing={2}
                  style={{
                    margin: "0px 0px",
                    width: "100%",
                  }}
                >
                  <Grid xs={4}>
                    <Button
                      style={{ width: "80%", borderRadius: 0 }}
                      className="main-button"
                    >
                      NEW
                    </Button>
                  </Grid>
                  <Grid xs={4}>
                    <Button
                      style={{ width: "80%", borderRadius: 0 }}
                      className="main-button"
                    >
                      TOP
                    </Button>
                  </Grid>
                  <Grid xs={4}>
                    <Button
                      style={{ width: "80%", borderRadius: 0 }}
                      className="main-button"
                    >
                      RECENT
                    </Button>
                  </Grid>
                </Grid>
              </div>
            </div>
          </Grid>
        </Grid>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
        >
          <input
            type="text"
            placeholder="Search Any Product..."
            style={{
              width: "90%",
              height: "40px",
              color: "#fff",
              backgroundColor: "#292929",
              fontSize: 14,
              padding: "3px 20px",
              border: "none",
              marginRight: "-30px",
            }}
          />
          <Search style={{ color: "white" }} />
        </div>
      </div>

      {/* SHOP NOW */}
      <div
        style={{
          textAlign: "center",
          marginTop: "100px",
          marginBottom: "100px",
        }}
      >
        <p
          style={{ fontSize: "36px", lineHeight: "52px" }}
          className="Akira-Expanded"
        >
          SHOP NOW
        </p>
        <p style={{ width: "40%", textAlign: "center", margin: "auto" }}>
          Lorem ipsum dolor sit amet, consectetur elit. Sed malesuada magna at
          leo scelerisque molestie. Vivamus sed sodales odio, in faucibus
          sapien.
        </p>

        <Grid
          container
          spacing={2}
          style={{
            width: "90%",
            margin: "auto",
            marginTop: "50px",
          }}
        >
          {filteredProducts
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((item) => {
              if (item.price === "") {
                return (
                  <Grid item md={4}>
                    <div className="shop-card-container">
                      <div className="shop-card-image-container">
                        <img
                          src={item.titleImageURL}
                          alt=""
                          style={{ width: 240 }}
                        />
                      </div>
                      <div className="shop-card-details">
                        <p className="shop-card-details-heading">
                          {item.productName}
                        </p>
                        <p className="shop-card-details-description">
                          {item.description.slice(0, 50)}
                        </p>
                        <p className="shop-card-details-price">
                          {"$" + item.price !== undefined &&
                            item.price !== null &&
                            item.price.toLocaleString()}
                        </p>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <button
                            style={{ marginLeft: "10px", border: "none" }}
                            onClick={(e) => handleAddToCart(item)}
                          >
                            <LocalMall
                              style={{
                                marginLeft: "10px",
                                color: "#FF3132",
                                fontSize: "36px",
                              }}
                            />
                          </button>
                          <button
                            style={{ marginLeft: "5px", border: "none" }}
                            onClick={(e) => handleAddToWishlist(item)}
                          >
                            <Favorite
                              style={{
                                marginLeft: "10px",
                                color: "#FF3132",
                                fontSize: "36px",
                              }}
                            />
                          </button>
                        </div>
                      </div>
                    </div>
                  </Grid>
                );
              } else {
                return (
                  <Grid item md={4}>
                    <div className="shop-card-container">
                      <div className="shop-card-image-container">
                        <img
                          src={item.titleImageURL}
                          alt=""
                          style={{ width: 240 }}
                        />
                      </div>
                      <div className="shop-card-details">
                        <Link
                          to={"/product-details?q=" + item._id}
                          style={{ textDecoration: "none", color: "black" }}
                        >
                          <p className="shop-card-details-heading">
                            {item.productName}
                          </p>
                        </Link>
                        <p className="shop-card-details-description">
                          {item.description.slice(0, 100)}
                        </p>
                        <p className="shop-card-details-price">
                          {"$" + item.price !== undefined &&
                            item.price !== null &&
                            item.price.toLocaleString()}
                        </p>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          {item.is3D && (
                            <Link
                              to={"/build-your-own?q=" + item._id}
                              style={{ textDecoration: "none", color: "black" }}
                            >
                              <Button className="main-button">
                                Build Buy Your Own
                              </Button>
                            </Link>
                          )}

                          <button
                            style={{ marginLeft: "10px", border: "none" }}
                            onClick={(e) => handleAddToCart(item)}
                          >
                            <LocalMall
                              style={{
                                marginLeft: "10px",
                                color: "#FF3132",
                                fontSize: "36px",
                              }}
                            />
                          </button>
                          <button
                            style={{ marginLeft: "5px", border: "none" }}
                            onClick={(e) => handleAddToWishlist(item)}
                          >
                            <Favorite
                              style={{
                                marginLeft: "10px",
                                color: "#FF3132",
                                fontSize: "36px",
                              }}
                            />
                          </button>
                        </div>
                      </div>
                    </div>
                  </Grid>
                );
              }
            })}
          {/* <Grid item md={4}>
            <div className="shop-card-container">
              <div className="shop-card-image-container">
                <img src={jeep1} alt="" style={{ width: "80%" }} />
              </div>
              <div className="shop-card-details">
                <Link
                  to={"/product-details?q=0001"}
                  style={{ textDecoration: "none", color: "black" }}
                >
                  <p className="shop-card-details-heading">{"4D Wrangler"}</p>
                </Link>
                <p className="shop-card-details-description">
                  {
                    "Lorem ipsum dolor sit amet, consectetur elit. Sed malesuada magna at leo scelerisque molestie."
                  }
                </p>
                <p className="shop-card-details-price">{"$ 100"}</p>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Link
                    to="/build-your-own?q=0001"
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    <Button className="main-button">Build Buy Your Own</Button>
                  </Link>
                  <button
                    style={{ marginLeft: "10px", border: "none" }}
                    onClick={(e) =>
                      handleAddToCart({
                        _id: "0001",
                        name: "4D Wrangler",
                        description:
                          "Lorem ipsum dolor sit amet, consectetur elit. Sed malesuada magna at leo scelerisque molestie.",
                        titleImageURL: "",
                        price: 100,
                      })
                    }
                  >
                    <LocalMall
                      style={{
                        color: "#FF3132",
                        fontSize: "36px",
                      }}
                    />
                  </button>
                  <button
                    style={{ marginLeft: "5px", border: "none" }}
                    onClick={(e) =>
                      handleAddToWishlist({
                        _id: "0001",
                        name: "4D Wrangler",
                        description:
                          "Lorem ipsum dolor sit amet, consectetur elit. Sed malesuada magna at leo scelerisque molestie.",
                        titleImageURL: "",
                        price: 100,
                      })
                    }
                  >
                    <Favorite
                      style={{
                        color: "#FF3132",
                        fontSize: "36px",
                      }}
                    />
                  </button>
                </div>
              </div>
            </div>
          </Grid>
          <Grid item md={4}>
            <div className="shop-card-container">
              <div className="shop-card-image-container">
                <img src={jeep1} alt="" style={{ width: "80%" }} />
              </div>
              <div className="shop-card-details">
                <Link
                  to={"/product-details?q=0002"}
                  style={{ textDecoration: "none", color: "black" }}
                >
                  <p className="shop-card-details-heading">{"4D Gladiator"}</p>
                </Link>
                <p className="shop-card-details-description">
                  {
                    "Lorem ipsum dolor sit amet, consectetur elit. Sed malesuada magna at leo scelerisque molestie."
                  }
                </p>
                <p className="shop-card-details-price">{"$ 120"}</p>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Link
                    to="/build-your-own?q=0002"
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    <Button className="main-button">Build Buy Your Own</Button>
                  </Link>
                  <button
                    style={{ marginLeft: "10px", border: "none" }}
                    onClick={(e) =>
                      handleAddToCart({
                        _id: "0002",
                        name: "4D Gladiator",
                        description:
                          "Lorem ipsum dolor sit amet, consectetur elit. Sed malesuada magna at leo scelerisque molestie.",
                        titleImageURL: "",
                        price: 120,
                      })
                    }
                  >
                    <LocalMall
                      style={{
                        color: "#FF3132",
                        fontSize: "36px",
                      }}
                    />
                  </button>
                  <button
                    style={{ marginLeft: "5px", border: "none" }}
                    onClick={(e) =>
                      handleAddToWishlist({
                        _id: "0002",
                        name: "4D Gladiator",
                        description:
                          "Lorem ipsum dolor sit amet, consectetur elit. Sed malesuada magna at leo scelerisque molestie.",
                        titleImageURL: "",
                        price: 120,
                      })
                    }
                  >
                    <Favorite
                      style={{
                        color: "#FF3132",
                        fontSize: "36px",
                      }}
                    />
                  </button>
                </div>
              </div>
            </div>
          </Grid>
          {PrintifyProducts.map((product) => {
            return (
              <Grid item md={4}>
                <div className="shop-card-container">
                  <div className="shop-card-image-container">
                    <img
                      src={product.images[0] && product.images[0].src}
                      alt=""
                      style={{ height: 240 }}
                    />
                  </div>
                  <div className="shop-card-details">
                    <Link
                      to={"/product-details?q=0001"}
                      style={{ textDecoration: "none", color: "black" }}
                    >
                      <p className="shop-card-details-heading">
                        {product.title}
                      </p>
                    </Link>
                     <p className="shop-card-details-description">
                      {product.description.substring(0, 200)}
                    </p>
                    <p className="shop-card-details-price">{"$ "}</p>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Link
                        to="/build-your-own?q=0001"
                        style={{ textDecoration: "none", color: "black" }}
                      >
                        <Button className="main-button">
                          Build Buy Your Own
                        </Button>
                      </Link>
                      <button
                        style={{ marginLeft: "10px", border: "none" }}
                        onClick={(e) =>
                          handleAddToCart({
                            _id: "0001",
                            name: "4D Wrangler",
                            description:
                              "Lorem ipsum dolor sit amet, consectetur elit. Sed malesuada magna at leo scelerisque molestie.",
                            titleImageURL: "",
                            price: 100,
                          })
                        }
                      >
                        <LocalMall
                          style={{
                            color: "#FF3132",
                            fontSize: "36px",
                          }}
                        />
                      </button>
                      <button
                        style={{ marginLeft: "5px", border: "none" }}
                        onClick={(e) =>
                          handleAddToWishlist({
                            _id: "0001",
                            name: "4D Wrangler",
                            description:
                              "Lorem ipsum dolor sit amet, consectetur elit. Sed malesuada magna at leo scelerisque molestie.",
                            titleImageURL: "",
                            price: 100,
                          })
                        }
                      >
                        <Favorite
                          style={{
                            color: "#FF3132",
                            fontSize: "36px",
                          }}
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </Grid>
            );
          })} */}
        </Grid>

        <div style={{ marginTop: 40 }}>
          <Button
            className="main-button"
            disabled={page == 0}
            onClick={(e) => setPage(page - 1)}
          >
            <ArrowBack />
          </Button>
          {/* {filteredProducts.map((item, index) => {
            let pageL = 0;
            if (index % 9 == 0) {
              return (
                <Button
                  style={{
                    backgroundColor: pageL == 0 ? "#ff3132" : "#f0f0f0",
                    color: pageL == 0 ? "white" : "black",
                    margin: "0px 5px",
                    borderRadius: 0,
                  }}
                  onClick={(e) => setPage(pageL)}
                >
                  01
                </Button>
              );
            }
          })} */}
          <Button
            style={{
              backgroundColor: page == 0 ? "#ff3132" : "#f0f0f0",
              color: page == 0 ? "white" : "black",
              margin: "0px 5px",
              borderRadius: 0,
            }}
            onClick={(e) => setPage(0)}
          >
            01
          </Button>
          <Button
            className="main-button"
            disabled={page == 0}
            onClick={(e) => setPage(page + 1)}
          >
            <ArrowForward />
          </Button>
        </div>
      </div>

      <div>
        <Grid container spacing={2} className="mid-banner-1">
          <Grid
            item
            md={7}
            sm={12}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img src={midBanner1} alt="" style={{ width: "90%" }} />
          </Grid>
          <Grid
            item
            md={5}
            sm={12}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                width: "70%",
                color: "black",
              }}
            >
              <p className="customize-section-sub-heading Montserrat-Regular">
                Elevate Your Drive, Customize in 3D
              </p>
              <p className="customize-section-heading Akira-Expanded">
                3D Customize Your Jeep Adventure!
              </p>
              <Link
                to="/build-your-own"
                style={{ textDecoration: "none", color: "black" }}
              >
                <Button className="main-button">Customize Your Jeep</Button>
              </Link>
            </div>
          </Grid>
        </Grid>
      </div>

      <div className="mid-banner-2">
        <div>
          <p
            style={{
              fontSize: "18px",
            }}
            className="Montserrat-Regular"
          >
            DRIVE BEYOND BOUNDARIES
          </p>
        </div>
        <p
          style={{
            fontSize: "36px",
            lineHeight: "52px",
            textAlign: "center",
          }}
          className="Akira-Expanded"
        >
          CUSTOMIZE DESIGN
        </p>
        <Link
          onClick={(e) => {
            localStorage.removeItem("modelParts");
          }}
          to="/build-your-own?q=0001"
          style={{ textDecoration: "none", color: "black" }}
        >
          <Button className="main-button">Customize Your Jeep</Button>
        </Link>
      </div>
    </div>
  );
};

export default Shop;
