import axios from "axios";
import { BASE_URL } from "./API";

const axiosApi = axios.create({
  baseURL: BASE_URL,
});

axiosApi.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject(error)
);

//------------------------------
export async function get(url, config = {}) {
  //   return await axiosApi
  //     .get(BASE_URL + url, { ...config })
  //     .then((response) => {
  //       if (response.status >= 200 || response.status <= 299)
  //         return response.data;
  //       throw response.data;
  //     })
  //     .catch((err) => {
  //       return err;
  //     });
  try {
    return await fetch(BASE_URL + url)
      .then((response) => {
        return response.json();
      })
      .then((resp) => {
        if (resp.Status_Code == 200 && resp.IsSuccess == true) {
          return resp;
        }
      })
      .catch((err) => {
        return err;
      });
  } catch (error) {
    return error;
  }
}

export async function post(url, data, config = {}) {
  //   return await axiosApi
  //     .post(BASE_URL + url, { ...data }, { ...config })
  //     .then((response) => {
  //       if (response.status >= 200 || response.status <= 299)
  //         return response.data;
  //       throw response.data;
  //     })
  //     .catch((err) => {
  //       return err;
  //     });
  try {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    };
    return await fetch(BASE_URL + url, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        return data;
      })
      .catch((err) => {
        return err;
      });
  } catch (error) {
    return error;
  }
}
