import { Canvas } from "@react-three/fiber";
import { useLocation } from "react-router-dom";
import { BakeShadows, OrbitControls, Stage } from "@react-three/drei";

import TwoDoorWrangler from "./Vehicle/Models/2DWRANGLER";
import FourDoorWrangler from "./Vehicle/Models/4DWRANGLER";
import FourDoorGladiator from "./Vehicle/Models/4DGLADIATOR";

function ModelRenderer({ chassisColor, visibleItems }) {
  const location = useLocation();
  const params = new URLSearchParams(location.search).get("q");
  return (
    <Canvas shadows camera={{ position: [-120, 50, 150], fov: 40 }}>
      <Stage environment="city" intensity={0.7}>
        {params === "0001" && (
          <FourDoorWrangler
            position={[0, 0, 0]}
            chassisColor={chassisColor}
            visibleItems={visibleItems}
          />
        )}
        {params === "0002" && (
          <FourDoorGladiator
            position={[0, 0, 0]}
            chassisColor={chassisColor}
            visibleItems={visibleItems}
          />
        )}
        {params === "0003" && (
          <TwoDoorWrangler
            position={[0, 0, 0]}
            chassisColor={chassisColor}
            visibleItems={visibleItems}
          />
        )}
      </Stage>
      <BakeShadows />
      <OrbitControls makeDefault />
    </Canvas>
  );
}

export default ModelRenderer;
