import useGLBLoader from '../../../../useGlbLoader.js';
import { FourDoorWranglerParts } from '../ModelConstants.js';

const useSideStepContainer = (visibleItems) => {
  let chrisHillSideStepLeft = useGLBLoader(
    FourDoorWranglerParts.SideSteps.ChrisHillSideStepLeft,
    visibleItems.includes("Chris Hill Side Step")
  );

  let chrisHillSideStepRight = useGLBLoader(
    FourDoorWranglerParts.SideSteps.ChrisHillSideStepRight,
    visibleItems.includes("Chris Hill Side Step")
  );

  let karenLyndonLeftSideStep = useGLBLoader(
    FourDoorWranglerParts.SideSteps.KarenLyndonLeftSideStep,
    visibleItems.includes("Karen Lyndon Side Step")
  );

  let karenLyndonRightSideStep = useGLBLoader(
    FourDoorWranglerParts.SideSteps.KarenLyndonRightSideStep,
    visibleItems.includes("Karen Lyndon Side Step")
  );

  let kevinSideStepLeft = useGLBLoader(
    FourDoorWranglerParts.SideSteps.KevinSideStepLeft,
    visibleItems.includes("Kevin Side Step")
  );

  let kevinSideStepRight = useGLBLoader(
    FourDoorWranglerParts.SideSteps.KevinSideStepRight,
    visibleItems.includes("Kevin Side Step")
  );

  let reganSideStepLeft = useGLBLoader(
    FourDoorWranglerParts.SideSteps.ReganSideStepLeft,
    visibleItems.includes("Regan Side Step")
  );

  let reganSideStepRight = useGLBLoader(
    FourDoorWranglerParts.SideSteps.ReganSideStepRight,
    visibleItems.includes("Regan Side Step")
  );

  let chrisHillSideSteps = [
    chrisHillSideStepLeft,
    chrisHillSideStepRight,
  ];
  
  let karenLyndonSideSteps = [
    karenLyndonLeftSideStep,
    karenLyndonRightSideStep,
  ];
  
  let kevinSideSteps = [
    kevinSideStepLeft,
    kevinSideStepRight,
  ];
  
  let reganSideSteps = [
    reganSideStepLeft,
    reganSideStepRight,
  ];

  const sideStepsArray = [
    { name: "Chris Hill Side Step", models: chrisHillSideSteps },
    { name: "Karen Lyndon Side Step", models: karenLyndonSideSteps },
    { name: "Kevin Side Step", models: kevinSideSteps },
    { name: "Regan Side Step", models: reganSideSteps },
  ];
  return {
    sideStepsArray
  };
};

export default useSideStepContainer;
