const baseURL = "/Models/NewFourDoorGradiator";

export const FourDoorGradiatorParts = {

  Grills: {
    ClaireGaretteGrill: `${baseURL}/Grill/Compressed Grill/ClaireGaretteGrill.glb`,
    FlatGrill: `${baseURL}/Grill/Compressed Grill/FlatGrill.glb`,
    GlenJonesGrill: `${baseURL}/Grill/Compressed Grill/GlenJonesGrill.glb`,
    MarkJHanscomGrill: `${baseURL}/Grill/Compressed Grill/MarkJHanscomGrill.glb`,
    MichaelKnappGrill: `${baseURL}/Grill/Compressed Grill/MichaelKnappGrill.glb`,
    RhiMcclendonGrill: `${baseURL}/Grill/Compressed Grill/RhiMcclendonGrill.glb`,
    RickyBeilchnerGrill: `${baseURL}/Grill/Compressed Grill/RickyBeilchnerGrill.glb`,
    ShawnOsburnGrill: `${baseURL}/Grill/Compressed Grill/ShawnOsburnGrill.glb`,
  },

  Hoods: {
    rubiconHood: `${baseURL}/Hood/Compressed Hood/rebiconhood new.glb`,
    DarleneMoscocneHood: `${baseURL}/Hood/Compressed Hood/DarleneMoscocneHood.glb`,
    JessicaVenableHood: `${baseURL}/Hood/Compressed Hood/JessicaVenableHood.glb`,
    RobertHutchingsHood: `${baseURL}/Hood/Compressed Hood/RobertHutchingsHood.glb`,
    SimpleHood: `${baseURL}/Hood/Compressed Hood/SimpleHood.glb`,
    YaseanBruceHood: `${baseURL}/Hood/Compressed Hood/YaseanBruceHood.glb`,
  },

  Doors: {
    FourDoorWranglerStructure: `${baseURL}/Door/Compressed Door/4doorWranglerStructure.glb`,
    RandyLyonDoorLB: `${baseURL}/Door/Compressed Door/RandyLyonDoorLB.glb`,
    RandyLyonDoorLF: `${baseURL}/Door/Compressed Door/RandyLyonDoorLF.glb`,
    RandyLyonDoorRB: `${baseURL}/Door/Compressed Door/RandyLyonDoorRB.glb`,
    RandyLyonDoorRF: `${baseURL}/Door/Compressed Door/RandyLyonDoorRF.glb`,
    SimpleDoorBL: `${baseURL}/Door/Compressed Door/SimpleDoorBL.glb`,
    SimpleDoorBR: `${baseURL}/Door/Compressed Door/SimpleDoorBR.glb`,
    SimpleDoorFL: `${baseURL}/Door/Compressed Door/SimpleDoorFL.glb`,
    SimpleDoorFR: `${baseURL}/Door/Compressed Door/SimpleDoorFR.glb`,
    SteveSmithDoorLB: `${baseURL}/Door/Compressed Door/SteveSmithDoorLB.glb`,
    SteveSmithDoorLF: `${baseURL}/Door/Compressed Door/SteveSmithDoorLF.glb`,
    SteveSmithDoorRB: `${baseURL}/Door/Compressed Door/SteveSmithDoorRB.glb`,
    SteveSmithDoorRF: `${baseURL}/Door/Compressed Door/SteveSmithDoorRF.glb`,
    TubularDoorLB: `${baseURL}/Door/Compressed Door/TubularDoorLB.glb`,
    TubularDoorLF: `${baseURL}/Door/Compressed Door/TubularDoorLF.glb`,
    TubularDoorRB: `${baseURL}/Door/Compressed Door/TubularDoorRB.glb`,
    TubularDoorRF: `${baseURL}/Door/Compressed Door/TubularDoorRF.glb`,
  },

  Bumpers: {
    CoreyJonesBumper: `${baseURL}/Bumper/Compressed Bumper/CoreyJonesBumper.glb`,
    CoveredBumper: `${baseURL}/Bumper/Compressed Bumper/CoveredBumper.glb`,
    DarleneMosconeBumper: `${baseURL}/Bumper/Compressed Bumper/DarleneMosconeBumper.glb`,
    DustinHemeurRig2Bumper: `${baseURL}/Bumper/Compressed Bumper/DustinHemeurRig2Bumper.glb`,
    DustinHemeurRig3Bumper: `${baseURL}/Bumper/Compressed Bumper/DustinHemeurRig3Bumper.glb`,
    ElizabethVanesBumper: `${baseURL}/Bumper/Compressed Bumper/ElizabethVanesBumper.glb`,
    GlenJonesBumper: `${baseURL}/Bumper/Compressed Bumper/GlenJonesBumper.glb`,
    JasonCunninghamBumper: `${baseURL}/Bumper/Compressed Bumper/JasonCunninghamBumper.glb`,
    JasonMartinoBumper: `${baseURL}/Bumper/Compressed Bumper/JasonMartinoBumper.glb`,
    JessicaVenableBumper: `${baseURL}/Bumper/Compressed Bumper/JessicaVenableBumper.glb`,
    JonathanMclarenBumper: `${baseURL}/Bumper/Compressed Bumper/JonathanMclarenBumper.glb`,
    JoyGarnerBumper: `${baseURL}/Bumper/Compressed Bumper/JoyGarnerBumper.glb`,
    KevinKanBumper: `${baseURL}/Bumper/Compressed Bumper/KevinKanBumper.glb`,
    KevinOotenBumper: `${baseURL}/Bumper/Compressed Bumper/KevinOotenBumper.glb`,
    LeslieMedranoBumper: `${baseURL}/Bumper/Compressed Bumper/LeslieMedranoBumper.glb`,
    RIckValentinBumper: `${baseURL}/Bumper/Compressed Bumper/RIckValentinBumper.glb`,
    RobertHutchingsBumper: `${baseURL}/Bumper/Compressed Bumper/RobertHutchingsBumper.glb`,
    SharonFullmerBumper: `${baseURL}/Bumper/Compressed Bumper/SharonFullmerBumper.glb`,
    TammyMcCormickBumper: `${baseURL}/Bumper/Compressed Bumper/TammyMcCormickBumper.glb`,
    TimTraynorBumper: `${baseURL}/Bumper/Compressed Bumper/TimTraynorBumper.glb`,
  },
  SideSteps: {
    ChrisHillSideStepLeft: `${baseURL}/Side Step/Compressed Side Steps/ChrisHillSideStepLeft.glb`,
    ChrisHillSideStepRight: `${baseURL}/Side Step/Compressed Side Steps/ChrisHillSideStepRight.glb`,
    KarenLyndonLeftSideStep: `${baseURL}/Side Step/Compressed Side Steps/KarenLyndonLeftSideStep.glb`,
    KarenLyndonRightSideStep: `${baseURL}/Side Step/Compressed Side Steps/KarenLyndonRightSideStep.glb`,
    KevinSideStepLeft: `${baseURL}/Side Step/Compressed Side Steps/KevinSideStepLeft.glb`,
    KevinSideStepRight: `${baseURL}/Side Step/Compressed Side Steps/KevinSideStepRight.glb`,
    ReganSideStepLeft: `${baseURL}/Side Step/Compressed Side Steps/ReganSideStepLeft.glb`,
    ReganSideStepRight: `${baseURL}/Side Step/Compressed Side Steps/ReganSideStepRight.glb`,
  },

  Other: {
    DarleneMoscocneSnorkel: `${baseURL}/Other/Compressed Other/DarleneMoscocneSnorkel.glb`,
    JamesSimpsonBackRoofRack: `${baseURL}/Other/Compressed Other/JamesSimpsonBackRoofRack.glb`,
    JamesSimpsonFrontRoofRack: `${baseURL}/Other/Compressed Other/JamesSimpsonFrontRoofRack.glb`,
    Top:`${baseURL}/preparts/BriCarterTop.glb`,
    AdamWagnerFenderLB: `${baseURL}/Other/Compressed Other/AdamWagnerFenderLB.glb`,
    AdamWagnerFenderLF: `${baseURL}/Other/Compressed Other/AdamWagnerFenderLF.glb`,
    AdamWagnerFenderRB: `${baseURL}/Other/Compressed Other/AdamWagnerFenderRB.glb`,
    AdamWagnerFenderRF: `${baseURL}/Other/Compressed Other/AdamWagnerFenderRF.glb`,
    AndreaSimsFenderLB: `${baseURL}/Other/Compressed Other/AndreaSimsFenderLB.glb`,
    AndreaSimsFenderLF: `${baseURL}/Other/Compressed Other/AndreaSimsFenderLF.glb`,
    AndreaSimsFenderRB: `${baseURL}/Other/Compressed Other/AndreaSimsFenderRB.glb`,
    AndreaSimsFenderRF: `${baseURL}/Other/Compressed Other/AndreaSimsFenderRF.glb`,
    ChristinaCabraFenderLB: `${baseURL}/Other/Compressed Other/ChristinaCabraFenderLB.glb`,
    ChristinaCabraFenderLF: `${baseURL}/Other/Compressed Other/ChristinaCabraFenderLF.glb`,
    ChristinaCabraFenderRB: `${baseURL}/Other/Compressed Other/ChristinaCabraFenderRB.glb`,
    ChristinaCabraFenderRF: `${baseURL}/Other/Compressed Other/ChristinaCabraFenderRF.glb`,
    SnowwolfFenderLB: `${baseURL}/Other/Compressed Other/SnowwolfFenderLB.glb`,
    SnowwolfFenderLF: `${baseURL}/Other/Compressed Other/SnowwolfFenderLF.glb`,
    SnowwolfFenderRB: `${baseURL}/Other/Compressed Other/SnowwolfFenderRB.glb`,
    SnowwolfFenderRF: `${baseURL}/Other/Compressed Other/SnowwolfFenderRF.glb`,
    ToyotaTacomaFenderLB: `${baseURL}/Other/Compressed Other/ToyotaTacomaFenderLB.glb`,
    ToyotaTacomaFenderLF: `${baseURL}/Other/Compressed Other/ToyotaTacomaFenderLF.glb`,
    ToyotaTacomaFenderRB: `${baseURL}/Other/Compressed Other/ToyotaTacomaFenderRB.glb`,
    ToyotaTacomaFenderRF: `${baseURL}/Other/Compressed Other/ToyotaTacomaFenderRF.glb`,
  },
  Wheels: {
    AaronGordon: {
      FifthWheel: `${baseURL}/Wheels/Compressed Wheel/AaronGordon5thWheel.glb`,
      LBWheel: `${baseURL}/Wheels/Compressed Wheel/AaronGordonLBWheel.glb`,
      LFWheel: `${baseURL}/Wheels/Compressed Wheel/AaronGordonLFWheel.glb`,
      RBWheel: `${baseURL}/Wheels/Compressed Wheel/AaronGordonRBWheel.glb`,
      RFWheel: `${baseURL}/Wheels/Compressed Wheel/AaronGordonRFWheel.glb`,
    },
    Arkon: {
      FifthWheel: `${baseURL}/Wheels/Compressed Wheel/Arkon5thWheel.glb`,
      LBWheel: `${baseURL}/Wheels/Compressed Wheel/ArkonLBWheel.glb`,
      LFWheel: `${baseURL}/Wheels/Compressed Wheel/ArkonLFWheel.glb`,
      RBWheel: `${baseURL}/Wheels/Compressed Wheel/ArkonRBWheel.glb`,
      RFWheel: `${baseURL}/Wheels/Compressed Wheel/ArkonRFWheel.glb`,
    },
    BethanySmeltzer: {
      FifthWheel: `${baseURL}/Wheels/Compressed Wheel/BethanySmeltzer5thWheel.glb`,
      LBWheel: `${baseURL}/Wheels/Compressed Wheel/BethanySmeltzerLBWheel.glb`,
      LFWheel: `${baseURL}/Wheels/Compressed Wheel/BethanySmeltzerLFWheel.glb`,
      RBWheel: `${baseURL}/Wheels/Compressed Wheel/BethanySmeltzerRBWheel.glb`,
      RFWheel: `${baseURL}/Wheels/Compressed Wheel/BethanySmeltzerRFWheel.glb`,
    },
    BillHoughton: {
      FifthWheel: `${baseURL}/Wheels/Compressed Wheel/BillHoughton5thWheel.glb`,
      LBWheel: `${baseURL}/Wheels/Compressed Wheel/BillHoughtonLBWheel.glb`,
      LFWheel: `${baseURL}/Wheels/Compressed Wheel/BillHoughtonLFWheel.glb`,
      RBWheel: `${baseURL}/Wheels/Compressed Wheel/BillHoughtonRBWheel.glb`,
      RFWheel: `${baseURL}/Wheels/Compressed Wheel/BillHoughtonRFWheel.glb`,
    },
    BlackRhino: {
      FifthWheel: `${baseURL}/Wheels/Compressed Wheel/BlackRhino5thWheel.glb`,
      LBWheel: `${baseURL}/Wheels/Compressed Wheel/BlackRhinoLBWheel.glb`,
      LFWheel: `${baseURL}/Wheels/Compressed Wheel/BlackRhinoLFWheel.glb`,
      RBWheel: `${baseURL}/Wheels/Compressed Wheel/BlackRhinoRBWheel.glb`,
      RFWheel: `${baseURL}/Wheels/Compressed Wheel/BlackRhinoRFWheel.glb`,
    },
    Blitz: {
      FifthWheel: `${baseURL}/Wheels/Compressed Wheel/Blitz5thWheel.glb`,
      LBWheel: `${baseURL}/Wheels/Compressed Wheel/BlitzLBWheel.glb`,
      LFWheel: `${baseURL}/Wheels/Compressed Wheel/BlitzLFWheel.glb`,
      RBWheel: `${baseURL}/Wheels/Compressed Wheel/BlitzRBWheel.glb`,
      RFWheel: `${baseURL}/Wheels/Compressed Wheel/BlitzRFWheel.glb`,
    },
    Braydon: {
      FifthWheel: `${baseURL}/Wheels/Compressed Wheel/Braydon5thWheel.glb`,
      LBWheel: `${baseURL}/Wheels/Compressed Wheel/BraydonLBWheel.glb`,
      LFWheel: `${baseURL}/Wheels/Compressed Wheel/BraydonLFWheel.glb`,
      RBWheel: `${baseURL}/Wheels/Compressed Wheel/BraydonRBWheel.glb`,
      RFWheel: `${baseURL}/Wheels/Compressed Wheel/BraydonRFWheel.glb`,
    },
    CallieLemming: {
      FifthWheel: `${baseURL}/Wheels/Compressed Wheel/CallieLemming5thWheel.glb`,
      LBWheel: `${baseURL}/Wheels/Compressed Wheel/CallieLemmingLBWheel.glb`,
      LFWheel: `${baseURL}/Wheels/Compressed Wheel/CallieLemmingLFWheel.glb`,
      RBWheel: `${baseURL}/Wheels/Compressed Wheel/CallieLemmingRBWheel.glb`,
      RFWheel: `${baseURL}/Wheels/Compressed Wheel/CallieLemmingRFWheel.glb`,
    },
    ClaireGarette: {
      FifthWheel: `${baseURL}/Wheels/Compressed Wheel/ClaireGarette5thWheel.glb`,
      LBWheel: `${baseURL}/Wheels/Compressed Wheel/ClaireGaretteLBWheel.glb`,
      LFWheel: `${baseURL}/Wheels/Compressed Wheel/ClaireGaretteLFWheel.glb`,
      RBWheel: `${baseURL}/Wheels/Compressed Wheel/ClaireGaretteRBWheel.glb`,
      RFWheel: `${baseURL}/Wheels/Compressed Wheel/ClaireGaretteRFWheel.glb`,
    },
    ColterBahlau: {
      FifthWheel: `${baseURL}/Wheels/Compressed Wheel/ColterBahlau5thWheel.glb`,
      LBWheel: `${baseURL}/Wheels/Compressed Wheel/ColterBahlauLBWheel.glb`,
      LFWheel: `${baseURL}/Wheels/Compressed Wheel/ColterBahlauLFWheel.glb`,
      RBWheel: `${baseURL}/Wheels/Compressed Wheel/ColterBahlauRBWheel.glb`,
      RFWheel: `${baseURL}/Wheels/Compressed Wheel/ColterBahlauRFWheel.glb`,
    },
    CoreyJones: {
      FifthWheel: `${baseURL}/Wheels/Compressed Wheel/CoreyJones5thWheel.glb`,
      BLWheel: `${baseURL}/Wheels/Compressed Wheel/CoreyJonesBLWheel.glb`,
      BRWheel: `${baseURL}/Wheels/Compressed Wheel/CoreyJonesBRWheel.glb`,
      FLWheel: `${baseURL}/Wheels/Compressed Wheel/CoreyJonesFLWheel.glb`,
      FRWheel: `${baseURL}/Wheels/Compressed Wheel/CoreyJonesFRWheel.glb`,
    },
    DaniColeman: {
      FifthWheel: `${baseURL}/Wheels/Compressed Wheel/DaniColeman5thWheel.glb`,
      BLWheel: `${baseURL}/Wheels/Compressed Wheel/DaniColemanBLWheel.glb`,
      BRWheel: `${baseURL}/Wheels/Compressed Wheel/DaniColemanBRWheel.glb`,
      FLWheel: `${baseURL}/Wheels/Compressed Wheel/DaniColemanFLWheel.glb`,
      FRWheel: `${baseURL}/Wheels/Compressed Wheel/DaniColemanFRWheel.glb`,
    },
    DarleneMoscone: {
      FifthWheel: `${baseURL}/Wheels/Compressed Wheel/DarleneMoscone5thWheel.glb`,
      BLWheel: `${baseURL}/Wheels/Compressed Wheel/DarleneMosconeBLWheel.glb`,
      BRWheel: `${baseURL}/Wheels/Compressed Wheel/DarleneMosconeBRWheel.glb`,
      FLWheel: `${baseURL}/Wheels/Compressed Wheel/DarleneMosconeFLWheel.glb`,
      FRWheel: `${baseURL}/Wheels/Compressed Wheel/DarleneMosconeFRWheel.glb`,
    },
    DavidShafer: {
      FifthWheel: `${baseURL}/Wheels/Compressed Wheel/DavidShafer5thWheel.glb`,
      BLWheel: `${baseURL}/Wheels/Compressed Wheel/DavidShaferBLWheel.glb`,
      BRWheel: `${baseURL}/Wheels/Compressed Wheel/DavidShaferBRWheel.glb`,
      FLWheel: `${baseURL}/Wheels/Compressed Wheel/DavidShaferFLWheel.glb`,
      FRWheel: `${baseURL}/Wheels/Compressed Wheel/DavidShaferFRWheel.glb`,
    },
    DustinHemauer: {
      FifthWheel: `${baseURL}/Wheels/Compressed Wheel/DustinHemauer5thWheel.glb`,
      FLWheel: `${baseURL}/Wheels/Compressed Wheel/DustinHemauerFLWheel.glb`,
      FRWheel: `${baseURL}/Wheels/Compressed Wheel/DustinHemauerFRWheel.glb`,
      BLWheel: `${baseURL}/Wheels/Compressed Wheel/DustinHemauerBLWheel.glb`,
      BRWheel: `${baseURL}/Wheels/Compressed Wheel/DustinHemauerBRWheel.glb`,
    },
    GlenJones: {
      FifthWheel: `${baseURL}/Wheels/Compressed Wheel/GlenJones5thWheel.glb`,
      BLWheel: `${baseURL}/Wheels/Compressed Wheel/GlenJonesBLWheel.glb`,
      BRWheel: `${baseURL}/Wheels/Compressed Wheel/GlenJonesBRWheel.glb`,
      FLWheel: `${baseURL}/Wheels/Compressed Wheel/GlenJonesFLWheel.glb`,
      FRWheel: `${baseURL}/Wheels/Compressed Wheel/GlenJonesFRWheel.glb`,
    },
    JaredRenfroe: {
      FifthWheel: `${baseURL}/Wheels/Compressed Wheel/JaredRenfroe5thWheel.glb`,
      BLWheel: `${baseURL}/Wheels/Compressed Wheel/JaredRenfroeBLWheel.glb`,
      BRWheel: `${baseURL}/Wheels/Compressed Wheel/JaredRenfroeBRWheel.glb`,
      FLWheel: `${baseURL}/Wheels/Compressed Wheel/JaredRenfroeFLWheel.glb`,
      FRWheel: `${baseURL}/Wheels/Compressed Wheel/JaredRenfroeFRWheel.glb`,
    },
    JasonMartino: {
      FifthWheel: `${baseURL}/Wheels/Compressed Wheel/JasonMartino5thWheel.glb`,
      BLWheel: `${baseURL}/Wheels/Compressed Wheel/JasonMartinoBLWheel.glb`,
      BRWheel: `${baseURL}/Wheels/Compressed Wheel/JasonMartinoBRWheel.glb`,
      FLWheel: `${baseURL}/Wheels/Compressed Wheel/JasonMartinoFLWheel.glb`,
      FRWheel: `${baseURL}/Wheels/Compressed Wheel/JasonMartinoFRWheel.glb`,
    },
    JonathenMclaren: {
      FifthWheel: `${baseURL}/Wheels/Compressed Wheel/JonathenMclaren5thWheel.glb`,
      BLWheel: `${baseURL}/Wheels/Compressed Wheel/JonathenMclarenBLWheel.glb`,
      BRWheel: `${baseURL}/Wheels/Compressed Wheel/JonathenMclarenBRWheel.glb`,
      FLWheel: `${baseURL}/Wheels/Compressed Wheel/JonathenMclarenFLWheel.glb`,
      FRWheel: `${baseURL}/Wheels/Compressed Wheel/JonathenMclarenFRWheel.glb`,
    },
    JoyGarner: {
      FifthWheel: `${baseURL}/Wheels/Compressed Wheel/JoyGarner5thWheel.glb`,
      BLWheel: `${baseURL}/Wheels/Compressed Wheel/JoyGarnerBLWheel.glb`,
      BRWheel: `${baseURL}/Wheels/Compressed Wheel/JoyGarnerBRWheel.glb`,
      FLWheel: `${baseURL}/Wheels/Compressed Wheel/JoyGarnerFLWheel.glb`,
      FRWheel: `${baseURL}/Wheels/Compressed Wheel/JoyGarnerFRWheel.glb`,
    },
    KevinKan: {
      FifthWheel: `${baseURL}/Wheels/Compressed Wheel/KevinKan5thWheel.glb`,
      BLWheel: `${baseURL}/Wheels/Compressed Wheel/KevinKanBLWheel.glb`,
      BRWheel: `${baseURL}/Wheels/Compressed Wheel/KevinKanBRWheel.glb`,
      FLWheel: `${baseURL}/Wheels/Compressed Wheel/KevinKanFLWheel.glb`,
      FRWheel: `${baseURL}/Wheels/Compressed Wheel/KevinKanFRWheel.glb`,
    },
    MarkJHanscom: {
      FifthWheel: `${baseURL}/Wheels/Compressed Wheel/MarkJHanscom5thWheel.glb`,
      BLWheel: `${baseURL}/Wheels/Compressed Wheel/MarkJHanscomBLWheel.glb`,
      BRWheel: `${baseURL}/Wheels/Compressed Wheel/MarkJHanscomBRWheel.glb`,
      FLWheel: `${baseURL}/Wheels/Compressed Wheel/MarkJHanscomFLWheel.glb`,
      FRWheel: `${baseURL}/Wheels/Compressed Wheel/MarkJHanscomFRWheel.glb`,
    },
  },
};
