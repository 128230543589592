import React from "react";
import { useGLTF } from "@react-three/drei";

import useDoorContainer from "./Container/useDoorContainer.js";
import useHoodContainer from "./Container/useHoodContainer.js";
import useOtherContainer from "./Container/useOtherContainer.js";
import useGrillContainer from "./Container/useGrillContainer.js";
import useWheelsContainer from "./Container/useWheelContainer.js";
import useBumperContainer from "./Container/useBumperContainer.js";
import useSideStepContainer from "./Container/useSideStepContainer.js";

export default function TwoDoorWrangler({
  chassisColor,
  visibleItems,
  ...props
}) {
  let { hoods } = useHoodContainer(visibleItems);
  let { grills } = useGrillContainer(visibleItems);
  let { bumpers } = useBumperContainer(visibleItems);
  let { doorsArray } = useDoorContainer(visibleItems);
  let { rimsArray } = useWheelsContainer(visibleItems);
  let { otherParts } = useOtherContainer(visibleItems);
  let { sideStepsArray } = useSideStepContainer(visibleItems);

  // const Top = useGLTF("Models/NewFourDoorGradiator/PreParts/BriCarterTop.glb");
  const Model = useGLTF(
    "/Models/newTwoDoor/PreParts/2dwranglerstructure.glb"
  );

  return (
    <group {...props} dispose={null}>
       <mesh

        castShadow
        receiveShadow
        geometry={Model?.nodes.productpart.geometry}
        material={Model.materials.productpart}
        material-envMapIntensity={0.8}
        material-color={chassisColor}
      />
      {bumpers.map((bumper) => {
        if (visibleItems.includes(bumper.name) && bumper?.model?.model) {
          const { model } = bumper.model;
          return (
            <mesh
              key={bumper.name}
              castShadow
              receiveShadow
              geometry={model.scene.children[0].geometry}
              material={model.scene.children[0].material}
              material-envMapIntensity={0.8}
              material-color={"#202020"}
            />
          );
        } else {
          return null;
        }
      })}
      {grills.map((grill) => {
        if (visibleItems.includes(grill.name) && grill?.model?.model) {
          const { model } = grill.model;
          return (
            <mesh
              key={grill.name}
              castShadow
              receiveShadow
              geometry={model.scene.children[0].geometry}
              material={model.scene.children[0].material}
              material-envMapIntensity={0.8}
              material-color={"#202020"}
            />
          );
        } else {
          return null;
        }
      })}
      {hoods.map((hood) => {
        if (visibleItems.includes(hood.name) && hood?.model?.model) {
          const { model } = hood.model;
          return (
            <mesh
              key={hood.name}
              castShadow
              receiveShadow
              geometry={model.scene.children[0].geometry}
              material={model.scene.children[0].material}
              material-envMapIntensity={0.8}
              material-color={chassisColor}
            />
          );
        } else {
          return null;
        }
      })}
      {doorsArray.map((doorGroup) => {
        if (visibleItems.includes(doorGroup.name)) {
          return doorGroup.models.map((eachDoor, index) => {
            if (eachDoor?.model) {
              return (
                <mesh
                  key={`${doorGroup.name}-${index}`}
                  castShadow
                  receiveShadow
                  geometry={eachDoor.model.scene.children[0].geometry}
                  material={eachDoor.model.scene.children[0].material}
                  material-envMapIntensity={0.8}
                  material-color={chassisColor}
                />
              );
            } else {
              return null;
            }
          });
        } else {
          return null;
        }
      })}

      {rimsArray.map((rimGroup) => {
        if (visibleItems.includes(rimGroup.name)) {
          return rimGroup.models.map((eachRim, index) => {
            const children = eachRim?.model?.scene?.children;
            if (children && children.length > 0) {
              // Get the last child from the children array
              const lastChild = children[children.length - 1];
              return (
                <mesh
                  key={`${rimGroup.name}-${index}`}
                  castShadow
                  receiveShadow
                  geometry={lastChild.geometry}
                  material={lastChild.material}
                  material-envMapIntensity={0.8}
                  material-color={"#202020"}
                />
              );
            } else {
              return null;
            }
          });
        } else {
          return null;
        }
      })}

      {sideStepsArray.map((stepGroup) => {
        if (visibleItems.includes(stepGroup.name)) {
          return stepGroup.models.map((eachStep, index) => {
            if (eachStep?.model) {
              return (
                <mesh
                  key={`${stepGroup.name}-${index}`}
                  castShadow
                  receiveShadow
                  geometry={eachStep.model.scene.children[0].geometry}
                  material={eachStep.model.scene.children[0].material}
                  material-envMapIntensity={0.8}
                  material-color={"#202020"}
                />
              );
            } else {
              return null;
            }
          });
        } else {
          return null;
        }
      })}
      {otherParts.map((otherPart, index) => {
        if (visibleItems.includes(otherPart.name) && otherPart?.model?.model) {
          const { model } = otherPart.model;
          let color = "#202020";
          if(otherPart.name.includes("Fender")){
            color = chassisColor;
          }
          return (
            <mesh
              key={index}
              castShadow
              receiveShadow
              geometry={model.scene.children[0].geometry}
              material={model.scene.children[0].material}
              material-envMapIntensity={0.8}
              material-color={color}
            />
          );
        } else {
          return null;
        }
      })}
    </group>
  );
}
