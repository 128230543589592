import useGLBLoader from '../../../../useGlbLoader.js';
import { FourDoorWranglerParts } from '../ModelConstants.js';

const useDoorContainer = (visibleItems) => {
    let randyLyonDoorLB = useGLBLoader(
      FourDoorWranglerParts.Doors.RandyLyonDoorLB,
      visibleItems.includes("Randy Lyon Door")
    );
  
    let randyLyonDoorLF = useGLBLoader(
      FourDoorWranglerParts.Doors.RandyLyonDoorLF,
      visibleItems.includes("Randy Lyon Door")
    );
  
    let randyLyonDoorRB = useGLBLoader(
      FourDoorWranglerParts.Doors.RandyLyonDoorRB,
      visibleItems.includes("Randy Lyon Door")
    );
  
    let randyLyonDoorRF = useGLBLoader(
      FourDoorWranglerParts.Doors.RandyLyonDoorRF,
      visibleItems.includes("Randy Lyon Door")
    );
  
    let simpleDoorBL = useGLBLoader(
      FourDoorWranglerParts.Doors.SimpleDoorBL,
      visibleItems.includes("Simple Door")
    );
  
    let simpleDoorBR = useGLBLoader(
      FourDoorWranglerParts.Doors.SimpleDoorBR,
      visibleItems.includes("Simple Door")
    );
  
    let simpleDoorFL = useGLBLoader(
      FourDoorWranglerParts.Doors.SimpleDoorFL,
      visibleItems.includes("Simple Door")
    );
  
    let simpleDoorFR = useGLBLoader(
      FourDoorWranglerParts.Doors.SimpleDoorFR,
      visibleItems.includes("Simple Door")
    );
  
    let steveSmithDoorLB = useGLBLoader(
      FourDoorWranglerParts.Doors.SteveSmithDoorLB,
      visibleItems.includes("Steve Smith Door")
    );
  
    let steveSmithDoorLF = useGLBLoader(
      FourDoorWranglerParts.Doors.SteveSmithDoorLF,
      visibleItems.includes("Steve Smith Door")
    );
  
    let steveSmithDoorRB = useGLBLoader(
      FourDoorWranglerParts.Doors.SteveSmithDoorRB,
      visibleItems.includes("Steve Smith Door")
    );
  
    let steveSmithDoorRF = useGLBLoader(
      FourDoorWranglerParts.Doors.SteveSmithDoorRF,
      visibleItems.includes("Steve Smith Door")
    );
  
    let tubularDoorLB = useGLBLoader(
      FourDoorWranglerParts.Doors.TubularDoorLB,
      visibleItems.includes("Tubular Door")
    );
  
    let tubularDoorLF = useGLBLoader(
      FourDoorWranglerParts.Doors.TubularDoorLF,
      visibleItems.includes("Tubular Door")
    );
  
    let tubularDoorRB = useGLBLoader(
      FourDoorWranglerParts.Doors.TubularDoorRB,
      visibleItems.includes("Tubular Door")
    );
  
    let tubularDoorRF = useGLBLoader(
      FourDoorWranglerParts.Doors.TubularDoorRF,
      visibleItems.includes("Tubular Door")
    );
  
    // Group related doors into arrays
  let randyLyonDoors = [
    randyLyonDoorLB,
    randyLyonDoorLF,
    randyLyonDoorRB,
    randyLyonDoorRF,
  ];

  let simpleDoors = [
    simpleDoorBL,
    simpleDoorBR,
    simpleDoorFL,
    simpleDoorFR,
  ];

  let steveSmithDoors = [
    steveSmithDoorLB,
    steveSmithDoorLF,
    steveSmithDoorRB,
    steveSmithDoorRF,
  ];

  let tubularDoors = [
    tubularDoorLB,
    tubularDoorLF,
    tubularDoorRB,
    tubularDoorRF,
  ];

  // Create an array of all doors and structures
  const doorsArray = [
    // { name: "Four Door Wrangler Structure", model: fourDoorWranglerStructure },
    { name: "Randy Lyon Door", models: randyLyonDoors },
    { name: "Simple Door", models: simpleDoors },
    { name: "Steve Smith Door", models: steveSmithDoors },
    { name: "Tubular Door", models: tubularDoors },
  ];

  return {
    doorsArray,
  };
  };
  
  export default useDoorContainer;