
import useGLBLoader from '../../../../useGlbLoader';
import { TwoDoorWranglerParts } from '../Constants/ModelConstants';

const useWheelsContainer = (visibleItems) => {

  let aaronGordon5thWheel = useGLBLoader(
    TwoDoorWranglerParts.Wheels.AaronGordon5thWheel,
    visibleItems.includes("Aaron Gordon Rims")
  );
  let aaronGordonLBWheel = useGLBLoader(
    TwoDoorWranglerParts.Wheels.AaronGordonLBWheel,
    visibleItems.includes("Aaron Gordon Rims")
  );
  let aaronGordonLFWheel = useGLBLoader(
    TwoDoorWranglerParts.Wheels.AaronGordonLFWheel,
    visibleItems.includes("Aaron Gordon Rims")
  );
  let aaronGordonRBWheel = useGLBLoader(
    TwoDoorWranglerParts.Wheels.AaronGordonRBWheel,
    visibleItems.includes("Aaron Gordon Rims")
  );
  let aaronGordonRFWheel = useGLBLoader(
    TwoDoorWranglerParts.Wheels.AaronGordonRFWheel,
    visibleItems.includes("Aaron Gordon Rims")
  );

  // Load Arkon wheels
  let arkon5thWheel = useGLBLoader(
    TwoDoorWranglerParts.Wheels.Arkon5thWheel,
    visibleItems.includes("Arkon Rims")
  );
  let arkonLBWheel = useGLBLoader(
    TwoDoorWranglerParts.Wheels.ArkonLBWheel,
    visibleItems.includes("Arkon Rims")
  );
  let arkonLFWheel = useGLBLoader(
    TwoDoorWranglerParts.Wheels.ArkonLFWheel,
    visibleItems.includes("Arkon Rims")
  );
  let arkonRBWheel = useGLBLoader(
    TwoDoorWranglerParts.Wheels.ArkonRBWheel,
    visibleItems.includes("Arkon Rims")
  );
  let arkonRFWheel = useGLBLoader(
    TwoDoorWranglerParts.Wheels.ArkonRFWheel,
    visibleItems.includes("Arkon Rims")
  );

  // Load Bethany Smeltzer wheels
  let bethany5thWheel = useGLBLoader(
    TwoDoorWranglerParts.Wheels.Bethany5thWheel,
    visibleItems.includes("Bethany Smeltzer Rims")
  );
  let bethanyLBWheel = useGLBLoader(
    TwoDoorWranglerParts.Wheels.BethanyLBWheel,
    visibleItems.includes("Bethany Smeltzer Rims")
  );
  let bethanyLFWheel = useGLBLoader(
    TwoDoorWranglerParts.Wheels.BethanyLFWheel,
    visibleItems.includes("Bethany Smeltzer Rims")
  );
  let bethanyRBWheel = useGLBLoader(
    TwoDoorWranglerParts.Wheels.BethanyRBWheel,
    visibleItems.includes("Bethany Smeltzer Rims")
  );
  let bethanyRFWheel = useGLBLoader(
    TwoDoorWranglerParts.Wheels.BethanyRFWheel,
    visibleItems.includes("Bethany Smeltzer Rims")
  );

  let bethanyWheel = [
    bethanyRFWheel,
    bethanyRBWheel,
    bethanyLFWheel,
    bethanyLBWheel,
    bethany5thWheel

  ]
  // Load Bill Houghton wheels
  let billHoughton5thWheel = useGLBLoader(
    TwoDoorWranglerParts.Wheels.BillHoughton5thWheel,
    visibleItems.includes("Bill Houghton Rims")
  );
  let billHoughtonLBWheel = useGLBLoader(
    TwoDoorWranglerParts.Wheels.BillHoughtonLBWheel,
    visibleItems.includes("Bill Houghton Rims")
  );
  let billHoughtonLFWheel = useGLBLoader(
    TwoDoorWranglerParts.Wheels.BillHoughtonLFWheel,
    visibleItems.includes("Bill Houghton Rims")
  );
  let billHoughtonRBWheel = useGLBLoader(
    TwoDoorWranglerParts.Wheels.BillHoughtonRBWheel,
    visibleItems.includes("Bill Houghton Rims")
  );
  let billHoughtonRFWheel = useGLBLoader(
    TwoDoorWranglerParts.Wheels.BillHoughtonRFWheel,
    visibleItems.includes("Bill Houghton Rims")
  );

  // Load Black Rhino wheels
  let blackRhino5thWheel = useGLBLoader(
    TwoDoorWranglerParts.Wheels.BlackRhino5thWheel,
    visibleItems.includes("Black Rhino Rims")
  );
  let blackRhinoLBWheel = useGLBLoader(
    TwoDoorWranglerParts.Wheels.BlackRhinoLBWheel,
    visibleItems.includes("Black Rhino Rims")
  );
  let blackRhinoLFWheel = useGLBLoader(
    TwoDoorWranglerParts.Wheels.BlackRhinoLFWheel,
    visibleItems.includes("Black Rhino Rims")
  );
  let blackRhinoRBWheel = useGLBLoader(
    TwoDoorWranglerParts.Wheels.BlackRhinoRBWheel,
    visibleItems.includes("Black Rhino Rims")
  );
  let blackRhinoRFWheel = useGLBLoader(
    TwoDoorWranglerParts.Wheels.BlackRhinoRFWheel,
    visibleItems.includes("Black Rhino Rims")
  );

  // Load Blitz wheels
  let blitz5thWheel = useGLBLoader(
    TwoDoorWranglerParts.Wheels.Blitz5thWheel,
    visibleItems.includes("Blitz Rims")
  );
  let blitzLBWheel = useGLBLoader(
    TwoDoorWranglerParts.Wheels.BlitzLBWheel,
    visibleItems.includes("Blitz Rims")
  );
  let blitzLFWheel = useGLBLoader(
    TwoDoorWranglerParts.Wheels.BlitzLFWheel,
    visibleItems.includes("Blitz Rims")
  );
  let blitzRBWheel = useGLBLoader(
    TwoDoorWranglerParts.Wheels.BlitzRBWheel,
    visibleItems.includes("Blitz Rims")
  );
  let blitzRFWheel = useGLBLoader(
    TwoDoorWranglerParts.Wheels.BlitzRFWheel,
    visibleItems.includes("Blitz Rims")
  );

  // Load Braydon wheels
  let braydon5thWheel = useGLBLoader(
    TwoDoorWranglerParts.Wheels.Braydon5thWheel,
    visibleItems.includes("Braydon Rims")
  );
  let braydonLBWheel = useGLBLoader(
    TwoDoorWranglerParts.Wheels.BraydonLBWheel,
    visibleItems.includes("Braydon Rims")
  );
  let braydonLFWheel = useGLBLoader(
    TwoDoorWranglerParts.Wheels.BraydonLFWheel,
    visibleItems.includes("Braydon Rims")
  );
  let braydonRBWheel = useGLBLoader(
    TwoDoorWranglerParts.Wheels.BraydonRBWheel,
    visibleItems.includes("Braydon Rims")
  );
  let braydonRFWheel = useGLBLoader(
    TwoDoorWranglerParts.Wheels.BraydonRFWheel,
    visibleItems.includes("Braydon Rims")
  );

  // Load Callie Lemming wheels
  let callieLemming5thWheel = useGLBLoader(
    TwoDoorWranglerParts.Wheels.CallieLemming5thWheel,
    visibleItems.includes("Callie Lemming Rims")
  );
  let callieLemmingLBWheel = useGLBLoader(
    TwoDoorWranglerParts.Wheels.CallieLemmingLBWheel,
    visibleItems.includes("Callie Lemming Rims")
  );
  let callieLemmingLFWheel = useGLBLoader(
    TwoDoorWranglerParts.Wheels.CallieLemmingLFWheel,
    visibleItems.includes("Callie Lemming Rims")
  );
  let callieLemmingRBWheel = useGLBLoader(
    TwoDoorWranglerParts.Wheels.CallieLemmingRBWheel,
    visibleItems.includes("Callie Lemming Rims")
  );
  let callieLemmingRFWheel = useGLBLoader(
    TwoDoorWranglerParts.Wheels.CallieLemmingRFWheel,
    visibleItems.includes("Callie Lemming Rims")
  );

  // Load Claire Garette wheels
  let claireGarette5thWheel = useGLBLoader(
    TwoDoorWranglerParts.Wheels.ClaireGarette5thWheel,
    visibleItems.includes("Claire Garette Rims")
  );
  let claireGaretteLBWheel = useGLBLoader(
    TwoDoorWranglerParts.Wheels.ClaireGaretteLBWheel,
    visibleItems.includes("Claire Garette Rims")
  );
  let claireGaretteLFWheel = useGLBLoader(
    TwoDoorWranglerParts.Wheels.ClaireGaretteLFWheel,
    visibleItems.includes("Claire Garette Rims")
  );
  let claireGaretteRBWheel = useGLBLoader(
    TwoDoorWranglerParts.Wheels.ClaireGaretteRBWheel,
    visibleItems.includes("Claire Garette Rims")
  );
  let claireGaretteRFWheel = useGLBLoader(
    TwoDoorWranglerParts.Wheels.ClaireGaretteRFWheel,
    visibleItems.includes("Claire Garette Rims")
  );

  // Load Colter Bahlau wheels
  let colterBahlau5thWheel = useGLBLoader(
    TwoDoorWranglerParts.Wheels.ColterBahlau5thWheel,
    visibleItems.includes("Colter Bahlau Rims")
  );
  let colterBahlauLBWheel = useGLBLoader(
    TwoDoorWranglerParts.Wheels.ColterBahlauLBWheel,
    visibleItems.includes("Colter Bahlau Rims")
  );
  let colterBahlauLFWheel = useGLBLoader(
    TwoDoorWranglerParts.Wheels.ColterBahlauLFWheel,
    visibleItems.includes("Colter Bahlau Rims")
  );
  let colterBahlauRBWheel = useGLBLoader(
    TwoDoorWranglerParts.Wheels.ColterBahlauRBWheel,
    visibleItems.includes("Colter Bahlau Rims")
  );
  let colterBahlauRFWheel = useGLBLoader(
    TwoDoorWranglerParts.Wheels.ColterBahlauRFWheel,
    visibleItems.includes("Colter Bahlau Rims")
  );

  // Load Corey Jones wheels
  // let coreyJones5thWheel = useGLBLoader(
  //   TwoDoorWranglerParts.Wheels.CoreyJones5thWheel,
  //   visibleItems.includes("Corey Jones Rims")
  // );
  // let coreyJonesLBWheel = useGLBLoader(
  //   TwoDoorWranglerParts.Wheels.CoreyJonesLBWheel,
  //   visibleItems.includes("Corey Jones Rims")
  // );
  // let coreyJonesLFWheel = useGLBLoader(
  //   TwoDoorWranglerParts.Wheels.CoreyJonesLFWheel,
  //   visibleItems.includes("Corey Jones Rims")
  // );
  // let coreyJonesRBWheel = useGLBLoader(
  //   TwoDoorWranglerParts.Wheels.CoreyJonesRBWheel,
  //   visibleItems.includes("Corey Jones Rims")
  // );
  // let coreyJonesRFWheel = useGLBLoader(
  //   TwoDoorWranglerParts.Wheels.CoreyJonesRFWheel,
  //   visibleItems.includes("Corey Jones Rims")
  // );

  let daniColeman5thWheel = useGLBLoader(
    TwoDoorWranglerParts.Wheels.DaniColeman5thWheel,
    visibleItems.includes("Dani Coleman Rims")
  );
  let daniColemanBLWheel = useGLBLoader(
    TwoDoorWranglerParts.Wheels.DaniColemanBLWheel,
    visibleItems.includes("Dani Coleman Rims")
  );
  let daniColemanBRWheel = useGLBLoader(
    TwoDoorWranglerParts.Wheels.DaniColemanBRWheel,
    visibleItems.includes("Dani Coleman Rims")
  );
  let daniColemanFLWheel = useGLBLoader(
    TwoDoorWranglerParts.Wheels.DaniColemanFLWheel,
    visibleItems.includes("Dani Coleman Rims")
  );
  let daniColemanFRWheel = useGLBLoader(
    TwoDoorWranglerParts.Wheels.DaniColemanFRWheel,
    visibleItems.includes("Dani Coleman Rims")
  );

  // Load Darlene Moscone wheels
  let darleneMoscone5thWheel = useGLBLoader(
    TwoDoorWranglerParts.Wheels.DarleneMoscone5thWheel,
    visibleItems.includes("Darlene Moscone Rims")
  );
  let darleneMosconeBLWheel = useGLBLoader(
    TwoDoorWranglerParts.Wheels.DarleneMosconeBLWheel,
    visibleItems.includes("Darlene Moscone Rims")
  );
  let darleneMosconeBRWheel = useGLBLoader(
    TwoDoorWranglerParts.Wheels.DarleneMosconeBRWheel,
    visibleItems.includes("Darlene Moscone Rims")
  );
  let darleneMosconeFLWheel = useGLBLoader(
    TwoDoorWranglerParts.Wheels.DarleneMosconeFLWheel,
    visibleItems.includes("Darlene Moscone Rims")
  );
  let darleneMosconeFRWheel = useGLBLoader(
    TwoDoorWranglerParts.Wheels.DarleneMosconeFRWheel,
    visibleItems.includes("Darlene Moscone Rims")
  );

  // Load David Shafer wheels
  let davidShafer5thWheel = useGLBLoader(
    TwoDoorWranglerParts.Wheels.DavidShafer5thWheel,
    visibleItems.includes("David Shafer Rims")
  );
  let davidShaferBLWheel = useGLBLoader(
    TwoDoorWranglerParts.Wheels.DavidShaferBLWheel,
    visibleItems.includes("David Shafer Rims")
  );
  let davidShaferBRWheel = useGLBLoader(
    TwoDoorWranglerParts.Wheels.DavidShaferBRWheel,
    visibleItems.includes("David Shafer Rims")
  );
  let davidShaferFLWheel = useGLBLoader(
    TwoDoorWranglerParts.Wheels.DavidShaferFLWheel,
    visibleItems.includes("David Shafer Rims")
  );
  let davidShaferFRWheel = useGLBLoader(
    TwoDoorWranglerParts.Wheels.DavidShaferFRWheel,
    visibleItems.includes("David Shafer Rims")
  );

  // Load Dustin Hemauer wheels
  let dustinHemauer5thWheel = useGLBLoader(
    TwoDoorWranglerParts.Wheels.DustinHemauer5thWheel,
    visibleItems.includes("Dustin Hemauer Rims")
  );
  let dustinHemauerBLWheel = useGLBLoader(
    TwoDoorWranglerParts.Wheels.DustinHemauerBLWheel,
    visibleItems.includes("Dustin Hemauer Rims")
  );
  let dustinHemauerBRWheel = useGLBLoader(
    TwoDoorWranglerParts.Wheels.DustinHemauerBRWheel,
    visibleItems.includes("Dustin Hemauer Rims")
  );
  let dustinHemauerFLWheel = useGLBLoader(
    TwoDoorWranglerParts.Wheels.DustinHemauerFLWheel,
    visibleItems.includes("Dustin Hemauer Rims")
  );
  let dustinHemauerFRWheel = useGLBLoader(
    TwoDoorWranglerParts.Wheels.DustinHemauerFRWheel,
    visibleItems.includes("Dustin Hemauer Rims")
  );

  // Load Glen Jones wheels
  let glenJones5thWheel = useGLBLoader(
    TwoDoorWranglerParts.Wheels.GlenJones5thWheel,
    visibleItems.includes("Glen Jones Rims")
  );
  let glenJonesBLWheel = useGLBLoader(
    TwoDoorWranglerParts.Wheels.GlenJonesBLWheel,
    visibleItems.includes("Glen Jones Rims")
  );
  let glenJonesBRWheel = useGLBLoader(
    TwoDoorWranglerParts.Wheels.GlenJonesBRWheel,
    visibleItems.includes("Glen Jones Rims")
  );
  let glenJonesFLWheel = useGLBLoader(
    TwoDoorWranglerParts.Wheels.GlenJonesFLWheel,
    visibleItems.includes("Glen Jones Rims")
  );
  let glenJonesFRWheel = useGLBLoader(
    TwoDoorWranglerParts.Wheels.GlenJonesFRWheel,
    visibleItems.includes("Glen Jones Rims")
  );

  // Load Jared Renfroe wheels
  let jaredRenfroe5thWheel = useGLBLoader(
    TwoDoorWranglerParts.Wheels.JaredRenfroe5thWheel,
    visibleItems.includes("Jared Renfroe Rims")
  );
  let jaredRenfroeBLWheel = useGLBLoader(
    TwoDoorWranglerParts.Wheels.JaredRenfroeBLWheel,
    visibleItems.includes("Jared Renfroe Rims")
  );
  let jaredRenfroeBRWheel = useGLBLoader(
    TwoDoorWranglerParts.Wheels.JaredRenfroeBRWheel,
    visibleItems.includes("Jared Renfroe Rims")
  );
  let jaredRenfroeFLWheel = useGLBLoader(
    TwoDoorWranglerParts.Wheels.JaredRenfroeFLWheel,
    visibleItems.includes("Jared Renfroe Rims")
  );
  let jaredRenfroeFRWheel = useGLBLoader(
    TwoDoorWranglerParts.Wheels.JaredRenfroeFRWheel,
    visibleItems.includes("Jared Renfroe Rims")
  );

  // Load Jason Martino wheels
  let jasonMartino5thWheel = useGLBLoader(
    TwoDoorWranglerParts.Wheels.JasonMartino5thWheel,
    visibleItems.includes("Jason Martino Rims")
  );
  let jasonMartinoFLWheel = useGLBLoader(
    TwoDoorWranglerParts.Wheels.JasonMartinoFLWheel,
    visibleItems.includes("Jason Martino Rims")
  );
  let jasonMartinoFRWheel = useGLBLoader(
    TwoDoorWranglerParts.Wheels.JasonMartinoFRWheel,
    visibleItems.includes("Jason Martino Rims")
  );
  let jasonMartinoLBWheel = useGLBLoader(
    TwoDoorWranglerParts.Wheels.JasonMartinoLBWheel,
    visibleItems.includes("Jason Martino Rims")
  );
  let jasonMartinoRBWheel = useGLBLoader(
    TwoDoorWranglerParts.Wheels.JasonMartinoRBWheel,
    visibleItems.includes("Jason Martino Rims")
  );

  // Load Jonathen Mclaren wheels
  let jonathenMclaren5thWheel = useGLBLoader(
    TwoDoorWranglerParts.Wheels.JonathenMclaren5thWheel,
    visibleItems.includes("Jonathen Mclaren Rims")
  );
  let jonathenMclarenFLWheel = useGLBLoader(
    TwoDoorWranglerParts.Wheels.JonathenMclarenFLWheel,
    visibleItems.includes("Jonathen Mclaren Rims")
  );
  let jonathenMclarenFRWheel = useGLBLoader(
    TwoDoorWranglerParts.Wheels.JonathenMclarenFRWheel,
    visibleItems.includes("Jonathen Mclaren Rims")
  );
  let jonathenMclarenLBWheel = useGLBLoader(
    TwoDoorWranglerParts.Wheels.JonathenMclarenLBWheel,
    visibleItems.includes("Jonathen Mclaren Rims")
  );
  let jonathenMclarenRBWheel = useGLBLoader(
    TwoDoorWranglerParts.Wheels.JonathenMclarenRBWheel,
    visibleItems.includes("Jonathen Mclaren Rims")
  );

  // Load Joy Garner wheels
  let joyGarner5thWheel = useGLBLoader(
    TwoDoorWranglerParts.Wheels.JoyGarner5thWheel,
    visibleItems.includes("Joy Garner Rims")
  );
  let joyGarnerFLWheel = useGLBLoader(
    TwoDoorWranglerParts.Wheels.JoyGarnerFLWheel,
    visibleItems.includes("Joy Garner Rims")
  );
  let joyGarnerFRWheel = useGLBLoader(
    TwoDoorWranglerParts.Wheels.JoyGarnerFRWheel,
    visibleItems.includes("Joy Garner Rims")
  );
  let joyGarnerLBWheel = useGLBLoader(
    TwoDoorWranglerParts.Wheels.JoyGarnerLBWheel,
    visibleItems.includes("Joy Garner Rims")
  );
  let joyGarnerRBWheel = useGLBLoader(
    TwoDoorWranglerParts.Wheels.JoyGarnerRBWheel,
    visibleItems.includes("Joy Garner Rims")
  );

  // Load Kevin Kan wheels
  let kevinKan5thWheel = useGLBLoader(
    TwoDoorWranglerParts.Wheels.KevinKan5thWheel,
    visibleItems.includes("Kevin Kan Rims")
  );
  let kevinKanFLWheel = useGLBLoader(
    TwoDoorWranglerParts.Wheels.KevinKanFLWheel,
    visibleItems.includes("Kevin Kan Rims")
  );
  let kevinKanFRWheel = useGLBLoader(
    TwoDoorWranglerParts.Wheels.KevinKanFRWheel,
    visibleItems.includes("Kevin Kan Rims")
  );
  let kevinKanLBWheel = useGLBLoader(
    TwoDoorWranglerParts.Wheels.KevinKanLBWheel,
    visibleItems.includes("Kevin Kan Rims")
  );
  let kevinKanRBWheel = useGLBLoader(
    TwoDoorWranglerParts.Wheels.KevinKanRBWheel,
    visibleItems.includes("Kevin Kan Rims")
  );

  // Load Mark J Hanscom wheels
  let markJHanscom5thWheel = useGLBLoader(
    TwoDoorWranglerParts.Wheels.MarkJHanscom5thWheel,
    visibleItems.includes("Mark J Hanscom Rims")
  );
  let markJHanscomFLWheel = useGLBLoader(
    TwoDoorWranglerParts.Wheels.MarkJHanscomFLWheel,
    visibleItems.includes("Mark J Hanscom Rims")
  );
  let markJHanscomFRWheel = useGLBLoader(
    TwoDoorWranglerParts.Wheels.MarkJHanscomFRWheel,
    visibleItems.includes("Mark J Hanscom Rims")
  );
  let markJHanscomLBWheel = useGLBLoader(
    TwoDoorWranglerParts.Wheels.MarkJHanscomLBWheel,
    visibleItems.includes("Mark J Hanscom Rims")
  );
  let markJHanscomRBWheel = useGLBLoader(
    TwoDoorWranglerParts.Wheels.MarkJHanscomRBWheel,
    visibleItems.includes("Mark J Hanscom Rims")
  );

  let aaronGordonWheel = [
    aaronGordon5thWheel,
    aaronGordonLBWheel,
    aaronGordonLFWheel,
    aaronGordonRBWheel,
    aaronGordonRFWheel,
  ];
  
  let arkonWheel = [
    arkon5thWheel,
    arkonLBWheel,
    arkonLFWheel,
    arkonRBWheel,
    arkonRFWheel,
  ];
  
  // let bethanyWheel = [
  //   bethany5thWheel,
  //   bethanyLBWheel,
  //   bethanyLFWheel,
  //   bethanyRBWheel,
  //   bethanyRFWheel,
  // ];
  
  let billHoughtonWheel = [
    billHoughton5thWheel,
    billHoughtonLBWheel,
    billHoughtonLFWheel,
    billHoughtonRBWheel,
    billHoughtonRFWheel,
  ];
  
  let blackRhinoWheel = [
    blackRhino5thWheel,
    blackRhinoLBWheel,
    blackRhinoLFWheel,
    blackRhinoRBWheel,
    blackRhinoRFWheel,
  ];
  
  let blitzWheel = [
    blitz5thWheel,
    blitzLBWheel,
    blitzLFWheel,
    blitzRBWheel,
    blitzRFWheel,
  ];
  
  let braydonWheel = [
    braydon5thWheel,
    braydonLBWheel,
    braydonLFWheel,
    braydonRBWheel,
    braydonRFWheel,
  ];
  
  let callieLemmingWheel = [
    callieLemming5thWheel,
    callieLemmingLBWheel,
    callieLemmingLFWheel,
    callieLemmingRBWheel,
    callieLemmingRFWheel,
  ];
  
  let claireGaretteWheel = [
    claireGarette5thWheel,
    claireGaretteLBWheel,
    claireGaretteLFWheel,
    claireGaretteRBWheel,
    claireGaretteRFWheel,
  ];
  
  let colterBahlauWheel = [
    colterBahlau5thWheel,
    colterBahlauLBWheel,
    colterBahlauLFWheel,
    colterBahlauRBWheel,
    colterBahlauRFWheel,
  ];
  
  // let coreyJonesWheel = [
  //   coreyJones5thWheel,
  //   coreyJonesLBWheel,
  //   coreyJonesLFWheel,
  //   coreyJonesRBWheel,
  //   coreyJonesRFWheel,
  // ];
  
  let daniColemanWheel = [
    daniColeman5thWheel,
    daniColemanBLWheel,
    daniColemanBRWheel,
    daniColemanFLWheel,
    daniColemanFRWheel,
  ];
  
  let darleneMosconeWheel = [
    darleneMoscone5thWheel,
    darleneMosconeBLWheel,
    darleneMosconeBRWheel,
    darleneMosconeFLWheel,
    darleneMosconeFRWheel,
  ];
  
  let davidShaferWheel = [
    davidShafer5thWheel,
    davidShaferBLWheel,
    davidShaferBRWheel,
    davidShaferFLWheel,
    davidShaferFRWheel,
  ];
  
  let dustinHemauerWheel = [
    dustinHemauer5thWheel,
    dustinHemauerBLWheel,
    dustinHemauerBRWheel,
    dustinHemauerFLWheel,
    dustinHemauerFRWheel,
  ];
  
  let glenJonesWheel = [
    glenJones5thWheel,
    glenJonesBLWheel,
    glenJonesBRWheel,
    glenJonesFLWheel,
    glenJonesFRWheel,
  ];
  
  let jaredRenfroeWheel = [
    jaredRenfroe5thWheel,
    jaredRenfroeBLWheel,
    jaredRenfroeBRWheel,
    jaredRenfroeFLWheel,
    jaredRenfroeFRWheel,
  ];
  
  let jasonMartinoWheel = [
    jasonMartino5thWheel,
    jasonMartinoFLWheel,
    jasonMartinoFRWheel,
    jasonMartinoLBWheel,
    jasonMartinoRBWheel,
  ];
  
  let jonathenMclarenWheel = [
    jonathenMclaren5thWheel,
    jonathenMclarenFLWheel,
    jonathenMclarenFRWheel,
    jonathenMclarenLBWheel,
    jonathenMclarenRBWheel,
  ];
  
  let joyGarnerWheel = [
    joyGarner5thWheel,
    joyGarnerFLWheel,
    joyGarnerFRWheel,
    joyGarnerLBWheel,
    joyGarnerRBWheel,
  ];
  
  let kevinKanWheel = [
    kevinKan5thWheel,
    kevinKanFLWheel,
    kevinKanFRWheel,
    kevinKanLBWheel,
    kevinKanRBWheel,
  ];
  
  let markJHanscomWheel = [
    markJHanscom5thWheel,
    markJHanscomFLWheel,
    markJHanscomFRWheel,
    markJHanscomLBWheel,
    markJHanscomRBWheel,
  ];
  
  const rimsArray = [
    { name: "Aaron Gordon Rims", models: aaronGordonWheel },
    { name: "Arkon Rims", models: arkonWheel },
    { name: "Bethany Smeltzer Rims", models: bethanyWheel },
    { name: "Bill Houghton Rims", models: billHoughtonWheel },
    { name: "Black Rhino Rims", models: blackRhinoWheel },
    { name: "Blitz Rims", models: blitzWheel },
    { name: "Braydon Rims", models: braydonWheel },
    { name: "Callie Lemming Rims", models: callieLemmingWheel },
    { name: "Claire Garette Rims", models: claireGaretteWheel },
    { name: "Colter Bahlau Rims", models: colterBahlauWheel },
    // { name: "Corey Jones Rims", models: coreyJonesWheel },
    { name: "Dani Coleman Rims", models: daniColemanWheel },
    { name: "Darlene Moscone Rims", models: darleneMosconeWheel },
    { name: "David Shafer Rims", models: davidShaferWheel },
    { name: "Dustin Hemauer Rims", models: dustinHemauerWheel },
    { name: "Glen Jones Rims", models: glenJonesWheel },
    { name: "Jared Renfroe Rims", models: jaredRenfroeWheel },
    { name: "Jason Martino Rims", models: jasonMartinoWheel },
    { name: "Jonathen Mclaren Rims", models: jonathenMclarenWheel },
    { name: "Joy Garner Rims", models: joyGarnerWheel },
    { name: "Kevin Kan Rims", models: kevinKanWheel },
    { name: "Mark J Hanscom Rims", models: markJHanscomWheel },
  ];
  
  return {
    rimsArray
  };
  
};

export default useWheelsContainer;


