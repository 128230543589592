import React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
// import PayPal from "./Paypal";
import Paypal_credit from "./Paypal_credit";

import {
  PayPalScriptProvider,
  PayPalButtons,
  usePayPalScriptReducer,
} from "@paypal/react-paypal-js";
import { useEffect, useRef } from "react";
// This value is from the props in the UI
const buttonStyle = { layout: "vertical" };

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 350,
  maxHeight: 600,
  overflowY: "scroll",
  bgcolor: "#f0f0f0",
  border: "2px solid #000",
  boxShadow: 24,
  p: 2,
};

export default function PaymentModal({
  open,
  handleClose,
  handlePaymentSubmit,
}) {
  // Custom component to wrap the PayPalButtons and show loading spinner
  // const ButtonWrapper = ({ showSpinner }) => {
  //   const [{ isPending }] = usePayPalScriptReducer();

  const initialOptions = {
    "client-id":
      "Ae6iiZNEpJWegRK_d9X1djt3IFJEzJHZoOMVPE6I7woGEA-RmxQTIXgOLTdnaYZBUQl52jT1lJKU2J7k", //3DJeep Client-ID
    // "Ace96-IGf9sAfi9yosC6FTEaiUNrbcg7VsFyklshpf-WegZKxBqmd_daHXR3fzF61Oq6WY_0MUAOOncL", //Winzco Sandbox Client-ID
    currency: "USD",
    intent: "capture",
  };

  return (
    <Modal
      keepMounted
      open={open}
      onClose={handleClose}
      aria-labelledby="keep-mounted-modal-title"
      aria-describedby="keep-mounted-modal-description"
    >
      <Box sx={style}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <PayPalScriptProvider options={initialOptions}>
            <Paypal_credit handlePaymentSubmit={handlePaymentSubmit} />
          </PayPalScriptProvider>
        </div>
      </Box>
    </Modal>
  );
}

//   return (
//     <Modal
//       keepMounted
//       open={open}
//       onClose={handleClose}
//       aria-labelledby="keep-mounted-modal-title"
//       aria-describedby="keep-mounted-modal-description"
//     >
//       <Box sx={style}>
//         <div
//           style={{
//             maxWidth: "750px",
//             maxHeight: "300px",
//             display: "flex",
//             justifyContent: "center",
//           }}
//         >
//           {open && <PayPal handlePaymentSubmit={handlePaymentSubmit} />}
//         </div>
//       </Box>
//     </Modal>
//   );
// }
