import { get, post } from "../Fetch";

export const Get_Product = async () => {
  const url = "api/Get_Product";
  return get(url);
};

export const Get_Product_By_Id = async (id) => {
  const url = `api/Get_ProductById=${id}`;
  return get(url);
};
