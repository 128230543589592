import React, { useState, useEffect, useRef, Suspense } from "react";
import { Button, Grid } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { Get_Product } from "../Utilities/Products";
import {
  LocalMall,
  Favorite,
  ArrowBack,
  ArrowForward,
} from "@mui/icons-material";
import ModelLoader from "../Components/ModelLoader";

// import Banner from "../assets/svg/cover phone.svg";
import homeHero from "../assets/svg/homePageHero.svg";
import midBanner1 from "../assets/svg//mid-banner-1.svg";
import logo from "../assets/svg/logo_black.svg";
import featureImage1 from "../assets/images/featureImage1.png";
import featureImage2 from "../assets/images/featureImage2.png";
import featureImage3 from "../assets/images/featureImage3.png";
import gladiator from "../assets/images/gladiator.png";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import rightArrow from "../assets/svg/rightArrow.svg";
import leftArrow from "../assets/svg/leftArrow.svg";

import ModelRenderer from "../Components/3DModelHome";
import bumper from "../assets/images/bumper.png";
import body from "../assets/images/body.png";
import rims from "../assets/images/rims.png";
import tyre from "../assets/images/tyre.png";
import MarkJHanscomGrill from "../assets/images/modelParts/Grills/MarkJHanscomGrill.png";
import "../assets/css/image-crousel.css";

const Home = () => {
  const navigation = useNavigate();

  // const [cart, setCart] = useState([]);
  const [visibleItems, setVisibleItems] = useState([]);

  const [translate, setTranslate] = useState(0);
  const [grillColor, setGrillColor] = useState(null);
  const [bumperColor, setBumperColor] = useState(null);
  const [grillIndex, setGrillIndex] = useState(-1);
  const [bumperIndex, setBumperIndex] = useState(-1);
  const [price, setPrice] = React.useState([
    {
      component: "Body",
      price: 49000,
    },
  ]);
  const [totalPrice, setTotalPrice] = React.useState(49000);

  const [JeepList, setJeepList] = React.useState([]);

  const [translate2, setTranslate2] = useState(0);
  const [currentCard, setcurrentCard] = useState(1);

  const handlePriceChange = (value) => {
    console.log(value);
    const newArray = price.filter((item) => {
      return item.component !== value.component;
    });

    newArray.push(value);
    setPrice(newArray);

    let totalValue = 0;
    newArray.map((item) => {
      totalValue = totalValue + item.price;
    });
    setTotalPrice(totalValue);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // const boxRef = useRef(null);
  // const [scrollLeft, setScrollLeft] = useState(0);

  const btnpressprev = () => {
    if (translate !== 0) setTranslate(translate + 320);
    else setTranslate(-640);
  };

  const btnpressnext = () => {
    if (translate !== -640) setTranslate(translate - 320);
    else setTranslate(0);
  };

  const btnpressprev2 = () => {
    if (translate2 !== 0) {
      setTranslate2(translate2 + 340);
      setcurrentCard(currentCard - 1);
    } else {
      setTranslate2(-1020);
      setcurrentCard(1);
    }
  };

  const btnpressnext2 = () => {
    if (translate2 !== -1020) {
      setTranslate2(translate2 - 340);
      setcurrentCard(currentCard + 1);
    } else {
      setTranslate2(0);
      setcurrentCard(1);
    }
  };

  const handleAddToCart = async (product) => {
    let currentCart = [];
    const cart = localStorage.getItem("Cart");
    if (cart) {
      currentCart = JSON.parse(cart);
    }

    const index = currentCart.findIndex((item) => {
      return item.id === product._id;
    });
    if (index !== -1) {
      const filtered = currentCart.filter((item) => {
        return item.id === product._id;
      });
      currentCart.splice(index, 1);
      currentCart.push({
        id: filtered[0].id,
        productName: filtered[0].productName,
        productDescription: filtered[0].productDescription,
        productImage: filtered[0].productImage,
        productPrice: filtered[0].productPrice,
        Quantity: filtered[0].Quantity + 1,
      });
    } else {
      currentCart.push({
        id: product._id,
        productName: product.name,
        productDescription: product.description,
        productImage: product.titleImageURL,
        productPrice: product.price,
        Quantity: 1,
      });
    }

    localStorage.removeItem("Cart");
    localStorage.setItem("Cart", JSON.stringify(currentCart));
    navigation("/cart");
  };

  const handleAddToWishlist = async (product) => {
    let currentWishList = [];
    const wishList = localStorage.getItem("Wishlist");
    if (wishList) {
      currentWishList = JSON.parse(wishList);
    }

    const index = currentWishList.findIndex((item) => {
      return item.id === product._id;
    });
    if (index === -1) {
      currentWishList.push({
        id: product._id,
        productName: product.name,
        productDescription: product.description,
        productImage: product.titleImageURL,
        productPrice: product.price,
        Quantity: 1,
      });
    }

    localStorage.removeItem("Wishlist");
    localStorage.setItem("Wishlist", JSON.stringify(currentWishList));
    navigation("/wishlist");
  };

  return (
    <div>
      <div className="homepage-hero-container">
        <Grid container spacing={2}>
          <Grid
            item
            md={6}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
            }}
          >
            <div
              style={{
                width: "70%",
                color: "white",
              }}
            >
              <p
                // style={{
                //   fontWeight: "500",
                //   fontSize: "26px",
                // }}
                className="Montserrat-Regular home-page-hero-h1"
              >
                WELCOME TO MY 3D JEEP
              </p>
              <p
                // style={{
                //   fontWeight: "800",
                //   fontSize: "24px",
                // }}
                className="Akira-Expanded home-page-hero-h2"
              >
                Elevate your Jeep adventure by customizing & ordering your
                MY3DJEEP today!
              </p>
              <p
                // style={{
                //   fontSize: "22px",
                // }}
                className="Gilroy-Regular home-page-hero-h3"
              >
                Our goal is to fuel your passion for Jeep Aventure. Get started
                by customizing your Jeep today!
              </p>
              <Link
                onClick={(e) => {
                  localStorage.removeItem("modelParts");
                }}
                to="/build-your-own?q=0001"
                style={{ textDecoration: "none", color: "black" }}
              >
                <Button
                  style={{
                    backgroundColor: "white",
                    color: "black",
                    padding: "5px 10px",
                    fontSize: "14px",
                    fontWeight: "600",
                    borderRadius: 0,
                    minWidth: 200,
                  }}
                  className="Gilroy-Bold"
                >
                  Customize Your Jeep
                </Button>
              </Link>
            </div>
          </Grid>
          <Grid item md={6} className="hero-section-image">
            <img src={homeHero} alt="" style={{ width: "100%" }} />
          </Grid>
        </Grid>
      </div>

      <div
        style={{
          width: "50%",
          height: 65,
          backgroundColor: "black",
          marginTop: -65,
          borderTopRightRadius: 50,
        }}
      ></div>

      <div>
        <Grid container spacing={2} className="featured-models">
          <Grid
            item
            md={4}
            sm={12}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              marginBottom: -50,
            }}
          >
            <div
              style={{
                width: "70%",
                color: "white",
              }}
            >
              <p className="featured-models-heading Akira-Expanded">
                FEATURED MODELS
              </p>
              {/*<p className="featured-models-description">
                Lorem ipsum dolor sit amet, consectetur elit. Sed malesuada
                magna at leo scelerisque molestie. Vivamus sed sodales odio, in
                faucibus sapien.
              </p>*/}
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                }}
              >
                <button
                  onClick={btnpressprev}
                  className="featured-scroll-arrow-button"
                >
                  <img src={leftArrow} alt="" style={{ width: "45px" }} />
                </button>
                <button
                  onClick={btnpressnext}
                  className="featured-scroll-arrow-button"
                >
                  <img src={rightArrow} alt="" style={{ width: "45px" }} />
                </button>
              </div>
            </div>
          </Grid>
          <Grid
            item
            md={8}
            sm={12}
            style={{
              display: "flex",
              // justifyContent: "center",
              alignItems: "center",
              overflow: "hidden",
            }}
          >
            <div className="product-carousel">
              <div
                className="product-container"
                style={{
                  transition: "transform 0.5s ease",
                  transform: `translateX(${translate}px)`,
                }}
              >
                <div className="featured-card-container">
                  <div className="featured-card-image-container">
                    <img src={featureImage1} alt="" style={{ width: "100%" }} />
                  </div>
                  <div className="featured-link-arrow-button">
                    <Button>
                      {/* <ArrowOutwardIcon style={{ margin: 0 }} color="#fff" /> */}
                    </Button>
                  </div>
                  <Link to="/product-details?q=0001">
                    <Button className="featured-link-button Akira-Expanded">
                      4D Wrangler
                    </Button>
                  </Link>
                </div>
                <div className="featured-card-container">
                  <div className="featured-card-image-container">
                    <img src={gladiator} alt="" style={{ width: "100%" }} />
                  </div>
                  <div className="featured-link-arrow-button">
                    <Button>
                      {/* <ArrowOutwardIcon style={{ margin: 0 }} color="#fff" /> */}
                    </Button>
                  </div>
                  <Link to="/product-details?q=0002">
                    <Button className="featured-link-button Akira-Expanded">
                      4D Gladiator
                    </Button>
                  </Link>
                </div>
                <div className="featured-card-container">
                  <div className="featured-card-image-container">
                    <img src={featureImage2} alt="" style={{ width: "100%" }} />
                  </div>
                  <div className="featured-link-arrow-button">
                    <Button>
                      {/* <ArrowOutwardIcon style={{ margin: 0 }} color="#fff" /> */}
                    </Button>
                  </div>
                  <Link to="/product-details?q=0003">
                    <Button className="featured-link-button Akira-Expanded">
                      2D Wrangler
                    </Button>
                  </Link>
                </div>
                {/* <div className="featured-card-container">
                  <div className="featured-card-image-container">
                    <img src={featureImage3} alt="" style={{ width: "100%" }} />
                  </div>
                  <div className="featured-link-arrow-button">
                    <Button>
                      <ArrowOutwardIcon style={{ margin: 0 }} color="#fff" />
                    </Button>
                  </div>
                  <Link to="/product-details?q=664108c58825550bb9c3cbc8">
                    <Button className="featured-link-button Akira-Expanded">
                      2D Gladiator
                    </Button>
                  </Link>
                </div>
                <div className="featured-card-container">
                  <div className="featured-card-image-container">
                    <img src={jeep1} alt="" style={{ width: "90%" }} />
                  </div>
                  <div className="featured-link-arrow-button">
                    <Button>
                      <ArrowOutwardIcon style={{ margin: 0 }} color="#fff" />
                    </Button>
                  </div>
                  <Link to="/product-details">
                    <Button className="featured-link-button Akira-Expanded">
                      3 Donec commodo
                    </Button>
                  </Link>
                </div>
                <div className="featured-card-container">
                  <div className="featured-card-image-container">
                    <img src={jeep2} alt="" style={{ width: "90%" }} />
                  </div>
                  <div className="featured-link-arrow-button">
                    <Button>
                      <ArrowOutwardIcon style={{ margin: 0 }} color="#fff" />
                    </Button>
                  </div>
                  <Link to="/product-details">
                    <Button className="featured-link-button Akira-Expanded">
                      4 Donec commodo
                    </Button>
                  </Link>
                </div>
                <div className="featured-card-container">
                  <div className="featured-card-image-container">
                    <img src={jeep2} alt="" style={{ width: "90%" }} />
                  </div>
                  <div className="featured-link-arrow-button">
                    <Button>
                      <ArrowOutwardIcon style={{ margin: 0 }} color="#fff" />
                    </Button>
                  </div>
                  <Link to="/product-details">
                    <Button className="featured-link-button Akira-Expanded">
                      5 Donec commodo
                    </Button>
                  </Link>
                </div> */}
              </div>
            </div>

            {/* <div className="card-slide-container">
              <div className="card-slide">
                {slideData.map((card, index) => (
                  <div
                    key={index}
                    className={`card ${index === currentCard ? "active" : ""}`}
                  >
                    <h2>{card.title}</h2>
                    <p>{card.content}</p>
                  </div>
                ))}
              </div>
              <button onClick={prevCard}>Previous</button>
              <button onClick={nextCard}>Next</button>
            </div> */}
          </Grid>
        </Grid>
      </div>

      <div className="build-your-own-container">
        <p className="build-your-own-title  Akira-Expanded">BUILD YOUR OWN</p>
        <Grid
          container
          spacing={3}
          style={{
            paddingLeft: "15px",
            paddingRight: "10px",
            paddingTop: "50px",
          }}
        >
          <Grid md={7} sm={12} xs={12} className="build-card-vehicle-container">
            {/* <ModelRenderer
              grillIndex={grillIndex}
              grillColor={grillColor}
              bumperColor={bumperColor}
              bumperIndex={bumperIndex}
              visibleItems={visibleItems}
            /> */}
            <Suspense fallback={<ModelLoader src={featureImage1} />}>
              <ModelRenderer
                grillIndex={grillIndex}
                grillColor={grillColor}
                bumperColor={bumperColor}
                bumperIndex={bumperIndex}
                visibleItems={visibleItems}
              />
            </Suspense>
          </Grid>
          <Grid md={5} sm={12} className="build-card-options-container">
            <Grid
              container
              spacing={2}
              style={{
                margin: "0px 10px",
                width: "100%",
              }}
            >
              <Grid xs={4}>
                <Link
                  onClick={(e) => {
                    localStorage.removeItem("modelParts");
                  }}
                  to={"/build-your-own?q=0001"}
                  style={{ textDecoration: "none", color: "black" }}
                >
                  <Button
                    style={{ width: "80%", borderRadius: 0 }}
                    className="main-button"
                  >
                    BUMPER
                  </Button>
                </Link>
              </Grid>
              <Grid xs={4}>
                <Link
                  onClick={(e) => {
                    localStorage.removeItem("modelParts");
                  }}
                  to={"/build-your-own?q=0001"}
                  style={{ textDecoration: "none", color: "black" }}
                >
                  <Button
                    style={{ width: "80%", borderRadius: 0 }}
                    className="main-button"
                  >
                    GRILL
                  </Button>
                </Link>
              </Grid>
              <Grid xs={4}>
                <Link
                  onClick={(e) => {
                    localStorage.removeItem("modelParts");
                  }}
                  to={"/build-your-own?q=0001"}
                  style={{ textDecoration: "none", color: "black" }}
                >
                  <Button
                    style={{ width: "80%", borderRadius: 0 }}
                    className="main-button"
                  >
                    DOOR
                  </Button>
                </Link>
              </Grid>
              <Grid xs={6} style={{ marginTop: 20 }}>
                <Link
                  onClick={(e) => {
                    localStorage.removeItem("modelParts");
                  }}
                  to={"/build-your-own?q=0001"}
                  style={{ textDecoration: "none", color: "black" }}
                >
                  <div className="build-card">
                    <div className="build-card-image">
                      <img src={bumper} />
                    </div>
                    <p className="build-card-text">BUMPER</p>
                  </div>
                </Link>
              </Grid>
              <Grid xs={6} style={{ marginTop: 20 }}>
                <Link
                  onClick={(e) => {
                    localStorage.removeItem("modelParts");
                  }}
                  to={"/build-your-own?q=0001"}
                  style={{ textDecoration: "none", color: "black" }}
                >
                  <div className="build-card">
                    <div className="build-card-image">
                      <img src={body} />
                    </div>
                    <p className="build-card-text">BODY</p>
                  </div>
                </Link>
              </Grid>
              <Grid xs={6} style={{ marginTop: 20 }}>
                <Link
                  onClick={(e) => {
                    localStorage.removeItem("modelParts");
                  }}
                  to={"/build-your-own?q=0001"}
                  style={{ textDecoration: "none", color: "black" }}
                >
                  <div className="build-card">
                    <div className="build-card-image">
                      <img src={rims} height={90} />
                    </div>
                    <p className="build-card-text">RIMS</p>
                  </div>
                </Link>
              </Grid>
              <Grid xs={6} style={{ marginTop: 20 }}>
                <Link
                  onClick={(e) => {
                    localStorage.removeItem("modelParts");
                  }}
                  to={"/build-your-own?q=0001"}
                  style={{ textDecoration: "none", color: "black" }}
                >
                  <div className="build-card">
                    <div className="build-card-image">
                      <img src={MarkJHanscomGrill} width={200} height={120} />
                    </div>
                    <p className="build-card-text">GRILL</p>
                  </div>
                </Link>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>

      <div className="about-reviews-main-container">
        <div
          style={{
            textAlign: "center",
          }}
        >
          <p
            style={{ fontSize: "44px", lineHeight: "52px" }}
            className=" Akira-Expanded"
          >
            OUR REVIEWS
          </p>
          {/* <p style={{ width: "60%", textAlign: "center", margin: "auto" }}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas
            elementum felis at ligula ullamcorper. Vivamus vitae nisl libero.
            Pellentesque imperdiet ipsum ut sollicitudin efficitur. Nulla
            aliquet erat vel scelerisque efficitur. Etiam molestie
          </p> */}
        </div>
        <Grid container spacing={2} style={{ marginTop: 30 }}>
          <Grid
            item
            sm={12}
            xs={12}
            md={3}
            lg={3}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              margin: "auto",
              padding: "0px 30px",
            }}
          >
            <img
              src={logo}
              alt="logo"
              className="logo"
              style={{ marginLeft: 5 }}
            />
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                marginTop: 20,
                // marginLeft: -22,
              }}
            >
              <button
                onClick={btnpressprev2}
                style={{ border: "none", backgroundColor: "transparent" }}
              >
                <img src={leftArrow} alt="" style={{ width: "45px" }} />
              </button>
              <button
                onClick={btnpressnext2}
                style={{ border: "none", backgroundColor: "transparent" }}
              >
                <img src={rightArrow} alt="" style={{ width: "45px" }} />
              </button>
            </div>
          </Grid>
          <Grid
            item
            sm={12}
            xs={12}
            md={9}
            lg={9}
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              margin: "auto",
              padding: "0px 30px",
            }}
          >
            <div className="product-carousel">
              <div
                className="product-container"
                style={{
                  transition: "transform 0.5s ease",
                  transform: `translateX(${translate2}px)`,
                }}
              >
                <div
                  className={
                    currentCard === 1
                      ? "review-main-container-active"
                      : "review-main-container"
                  }
                >
                  <p
                    style={{
                      fontSize: 13,
                    }}
                  >
                    highly recommend to have your jeep done if you are a true
                    enthusiast, the attention to detail and communication and
                    process was awesome and exciting journey as i will do
                    another one when my jeep evolves and the adventure to come
                    in my future. When it arrived it was well received and
                    packaged with care!
                  </p>
                  <h4>Rollos SA</h4>
                </div>
                <div
                  className={
                    currentCard === 2
                      ? "review-main-container-active"
                      : "review-main-container"
                  }
                >
                  <p
                    style={{
                      fontSize: 13,
                    }}
                  >
                    Zach was awesome to work with! My Wrangler JKU looks
                    amazing, all the details was spot on. I highly recommend
                    my3DJeep to replicate your jeeps!
                  </p>
                  <h4>Nancy Reece</h4>
                </div>
                <div
                  className={
                    currentCard === 3
                      ? "review-main-container-active"
                      : "review-main-container"
                  }
                >
                  <p
                    style={{
                      fontSize: 13,
                    }}
                  >
                    my3DJeep did a great job on our CJ7!! I got this as a
                    Christmas gift and couldn't be happier. Not only is the jeep
                    exact, the customer service was amazing!! In the process of
                    restoration, can't wait to order our next my3DJeep. As you
                    can see from the picutes, he has enjoyed this gift
                    tremendously.
                  </p>
                  <h4>Sharon Lewis Fullmer</h4>
                </div>
                <div
                  className={
                    currentCard === 4
                      ? "review-main-container-active"
                      : "review-main-container"
                  }
                >
                  <p
                    style={{
                      fontSize: 13,
                    }}
                  >
                    I contacted Zach about making a model of my jeep. He did a
                    fantastic job recreating it considering I have a tone of
                    graphics on it. Thankyou again Zach for great work you did!
                  </p>
                  <h4>Jonathan M. McLaren</h4>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>

      {/* SHOP NOW */}
      {/* <div
        style={{
          textAlign: "center",
          marginTop: "100px",
          marginBottom: "100px",
        }}
      >
        <p
          style={{ fontSize: "44px", lineHeight: "52px" }}
          className=" Akira-Expanded"
        >
          SHOP NOW
        </p>
        <p style={{ width: "40%", textAlign: "center", margin: "auto" }}>
          Lorem ipsum dolor sit amet, consectetur elit. Sed malesuada magna at
          leo scelerisque molestie. Vivamus sed sodales odio, in faucibus
          sapien.
        </p>

        <Grid
          container
          spacing={2}
          style={{
            width: "90%",
            margin: "auto",
            marginTop: "50px",
          }}
        >
          {JeepList.map((jeep, index) => {
            return (
              <Grid item md={4} key={index}>
                <div className="shop-card-container">
                  <div className="shop-card-image-container">
                    <img
                      src={jeep.titleImageURL}
                      alt=""
                      style={{ width: "80%" }}
                    />
                  </div>
                  <div className="shop-card-details">
                    <Link
                      to={"/product-details?q=" + jeep._id}
                      style={{ textDecoration: "none", color: "black" }}
                    >
                      <p className="shop-card-details-heading">{jeep.name}</p>
                    </Link>
                    <p className="shop-card-details-description">
                      {jeep.description}
                    </p>
                    <p className="shop-card-details-price">
                      {"$ " + jeep.price.toLocaleString()}
                    </p>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Link
                        to="/build-your-own"
                        style={{ textDecoration: "none", color: "black" }}
                      >
                        <Button className="main-button">
                          Build Buy Your Own
                        </Button>
                      </Link>
                      <button
                        style={{ marginLeft: "10px", border: "none" }}
                        onClick={(e) => handleAddToCart(jeep)}
                      >
                        <LocalMall
                          style={{
                            color: "#FF3132",
                            fontSize: "36px",
                          }}
                        />
                      </button>
                      <button
                        style={{ marginLeft: "5px", border: "none" }}
                        onClick={(e) => handleAddToWishlist(jeep)}
                      >
                        <Favorite
                          style={{
                            color: "#FF3132",
                            fontSize: "36px",
                          }}
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </Grid>
            );
          })}
          <Grid item md={4}>
            <div className="shop-card-container">
              <div className="shop-card-image-container">
                <img src={jeep1} alt="" style={{ width: "80%" }} />
              </div>
              <div className="shop-card-details">
                <Link
                  to={"/product-details?q=0001"}
                  style={{ textDecoration: "none", color: "black" }}
                >
                  <p className="shop-card-details-heading">{"4D Wrangler"}</p>
                </Link>
                <p className="shop-card-details-description">
                  {
                    "Lorem ipsum dolor sit amet, consectetur elit. Sed malesuada magna at leo scelerisque molestie."
                  }
                </p>
                <p className="shop-card-details-price">{"$ 100"}</p>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Link
                    to="/build-your-own?q=0001"
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    <Button className="main-button">Build Buy Your Own</Button>
                  </Link>
                  <button
                    style={{ marginLeft: "10px", border: "none" }}
                    onClick={(e) =>
                      handleAddToCart({
                        _id: "0001",
                        name: "4D Wrangler",
                        description:
                          "Lorem ipsum dolor sit amet, consectetur elit. Sed malesuada magna at leo scelerisque molestie.",
                        titleImageURL: "",
                        price: 100,
                      })
                    }
                  >
                    <LocalMall
                      style={{
                        color: "#FF3132",
                        fontSize: "36px",
                      }}
                    />
                  </button>
                  <button
                    style={{ marginLeft: "5px", border: "none" }}
                    onClick={(e) =>
                      handleAddToWishlist({
                        _id: "0001",
                        name: "4D Wrangler",
                        description:
                          "Lorem ipsum dolor sit amet, consectetur elit. Sed malesuada magna at leo scelerisque molestie.",
                        titleImageURL: "",
                        price: 100,
                      })
                    }
                  >
                    <Favorite
                      style={{
                        color: "#FF3132",
                        fontSize: "36px",
                      }}
                    />
                  </button>
                </div>
              </div>
            </div>
          </Grid>
          <Grid item md={4}>
            <div className="shop-card-container">
              <div className="shop-card-image-container">
                <img src={jeep1} alt="" style={{ width: "80%" }} />
              </div>
              <div className="shop-card-details">
                <Link
                  to={"/product-details?q=0002"}
                  style={{ textDecoration: "none", color: "black" }}
                >
                  <p className="shop-card-details-heading">{"4D Gladiator"}</p>
                </Link>
                <p className="shop-card-details-description">
                  {
                    "Lorem ipsum dolor sit amet, consectetur elit. Sed malesuada magna at leo scelerisque molestie."
                  }
                </p>
                <p className="shop-card-details-price">{"$ 120"}</p>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Link
                    to="/build-your-own?q=0002"
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    <Button className="main-button">Build Buy Your Own</Button>
                  </Link>
                  <button
                    style={{ marginLeft: "10px", border: "none" }}
                    onClick={(e) =>
                      handleAddToCart({
                        _id: "0002",
                        name: "4D Gladiator",
                        description:
                          "Lorem ipsum dolor sit amet, consectetur elit. Sed malesuada magna at leo scelerisque molestie.",
                        titleImageURL: "",
                        price: 120,
                      })
                    }
                  >
                    <LocalMall
                      style={{
                        color: "#FF3132",
                        fontSize: "36px",
                      }}
                    />
                  </button>
                  <button
                    style={{ marginLeft: "5px", border: "none" }}
                    onClick={(e) =>
                      handleAddToWishlist({
                        _id: "0002",
                        name: "4D Gladiator",
                        description:
                          "Lorem ipsum dolor sit amet, consectetur elit. Sed malesuada magna at leo scelerisque molestie.",
                        titleImageURL: "",
                        price: 120,
                      })
                    }
                  >
                    <Favorite
                      style={{
                        color: "#FF3132",
                        fontSize: "36px",
                      }}
                    />
                  </button>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </div> */}

      <div className="mid-banner-2">
        <div>
          <p
            style={{
              fontSize: "22px",
            }}
            className="Montserrat-Regular"
          >
            DRIVE BEYOND BOUNDARIES
          </p>
        </div>
        <p
          style={{
            fontSize: "44px",
            lineHeight: "52px",
            textAlign: "center",
          }}
          className=" Akira-Expanded"
        >
          CUSTOMIZE DESIGN
        </p>
        <Link
          onClick={(e) => {
            localStorage.removeItem("modelParts");
          }}
          to="/build-your-own?q=0001"
          style={{ textDecoration: "none", color: "black" }}
        >
          <Button className="main-button">Customize Your Jeep</Button>
        </Link>
      </div>
    </div>
  );
};

export default Home;
